import React, { useState, useEffect } from 'react';
import './index.css'; // Import CSS for styling

const GoogleMap = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [map, setMap] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [statePolygon, setStatePolygon] = useState(null);

  useEffect(() => {
    const loadMap = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
        script.async = true;
        script.onload = initMap;
        document.head.appendChild(script);
      } else {
        initMap();
      }
    };

    const initMap = () => {
      const delhiCoordinates = { lat: 28.7041, lng: 77.1025 }; // Delhi's coordinates
      const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
        center: delhiCoordinates,
        zoom: 7,
      });
      setMap(mapInstance);
    };

    loadMap();

    return () => {
      // Cleanup code if needed
    };
  }, [googleMapsApiKey]);

  useEffect(() => {
    if (map && selectedState) {
      // Define state boundaries and colors
      const stateBoundaries = {
        'Andhra Pradesh': [
          { lat: 14.7504, lng: 78.5700 },
          { lat: 14.9876, lng: 80.4599 },
          { lat: 15.5775, lng: 80.0642 },
          { lat: 15.4863, lng: 78.2866 }
        ],
        'Arunachal Pradesh': [
          { lat: 27.8196, lng: 91.4927 },
          { lat: 28.2356, lng: 96.1852 },
          { lat: 26.7645, lng: 96.1652 }
        ],
        'Assam': [
          { lat: 26.1445, lng: 89.9035 },
          { lat: 27.5689, lng: 96.1847 },
          { lat: 24.7433, lng: 94.2075 }
        ],
        'Bihar': [
          { lat: 25.2084, lng: 83.9918 },
          { lat: 26.3492, lng: 88.1423 },
          { lat: 24.8669, lng: 87.5937 }
        ],
        'Chhattisgarh': [
          { lat: 17.0325, lng: 81.1252 },
          { lat: 24.3963, lng: 83.3350 },
          { lat: 21.6615, lng: 83.3189 }
        ],
        'Goa': [
          { lat: 14.7532, lng: 74.6190 },
          { lat: 15.3584, lng: 74.2993 },
          { lat: 15.7954, lng: 73.8270 }
        ],
        'Gujarat': [
          { lat: 20.1211, lng: 68.1940 },
          { lat: 24.3963, lng: 71.1924 },
          { lat: 22.2587, lng: 72.6447 }
        ],
        'Haryana': [
          { lat: 28.5192, lng: 76.4310 },
          { lat: 30.2140, lng: 77.0895 },
          { lat: 29.3239, lng: 74.5875 }
        ],
        'Himachal Pradesh': [
          { lat: 30.4410, lng: 75.8090 },
          { lat: 33.2549, lng: 78.4820 },
          { lat: 30.0590, lng: 78.4675 }
        ],
        'Jharkhand': [
          { lat: 21.9790, lng: 83.3547 },
          { lat: 25.0507, lng: 87.5191 },
          { lat: 22.9430, lng: 86.1626 }
        ],
        'Karnataka': [
          { lat: 11.5816, lng: 74.3097 },
          { lat: 16.1006, lng: 78.2048 },
          { lat: 15.6454, lng: 77.6079 }
        ],
        'Kerala': [
          { lat: 8.0657, lng: 76.7394 },
          { lat: 12.7922, lng: 77.7455 },
          { lat: 12.2388, lng: 74.6450 }
        ],
        'Madhya Pradesh': [
          { lat: 21.1024, lng: 74.9879 },
          { lat: 26.8640, lng: 82.8691 },
          { lat: 21.9933, lng: 81.7212 }
        ],
        'Maharashtra': [
          { lat: 15.6021, lng: 73.7266 },
          { lat: 22.0032, lng: 80.5480 },
          { lat: 21.0527, lng: 72.8052 }
        ],
        'Manipur': [
          { lat: 23.6439, lng: 92.6725 },
          { lat: 25.3667, lng: 94.3250 },
          { lat: 24.4085, lng: 93.1784 }
        ],
        'Meghalaya': [
          { lat: 25.1680, lng: 89.8710 },
          { lat: 26.1249, lng: 92.7971 },
          { lat: 24.2615, lng: 92.4900 }
        ],
        'Mizoram': [
          { lat: 21.9588, lng: 92.1988 },
          { lat: 24.6797, lng: 93.8711 },
          { lat: 22.9676, lng: 92.8032 }
        ],
        'Nagaland': [
          { lat: 25.2146, lng: 93.1738 },
          { lat: 27.6335, lng: 95.2505 },
          { lat: 25.0595, lng: 94.5633 }
        ],
        'Odisha': [
          { lat: 17.7800, lng: 81.6360 },
          { lat: 22.0341, lng: 87.0970 },
          { lat: 19.5399, lng: 84.7913 }
        ],
        'Punjab': [
          { lat: 29.5877, lng: 73.8772 },
          { lat: 32.4994, lng: 76.0107 },
          { lat: 30.6975, lng: 74.7615 }
        ],
        'Rajasthan': [
          { lat: 23.6345, lng: 69.8301 },
          { lat: 30.0590, lng: 78.4976 },
          { lat: 24.3963, lng: 73.7487 }
        ],
        'Sikkim': [
          { lat: 27.1751, lng: 88.0220 },
          { lat: 28.2784, lng: 88.9000 },
          { lat: 27.3165, lng: 88.6546 }
        ],
        'Tamil Nadu': [
          { lat: 8.0859, lng: 77.5385 },
          { lat: 13.4967, lng: 80.2825 },
          { lat: 11.5050, lng: 77.2794 }
        ],
        'Telangana': [
          { lat: 17.7494, lng: 77.4460 },
          { lat: 19.9512, lng: 81.1249 },
          { lat: 17.8749, lng: 81.0353 }
        ],
        'Tripura': [
          { lat: 22.7085, lng: 91.6232 },
          { lat: 24.5336, lng: 92.2433 },
          { lat: 23.8486, lng: 91.1194 }
        ],
        'Uttar Pradesh': [
          { lat: 23.6911, lng: 77.7464 },
          { lat: 30.4207, lng: 88.3885 },
          { lat: 26.5162, lng: 80.9462 }
        ],
        'Uttarakhand': [
          { lat: 28.8389, lng: 75.0145 },
          { lat: 31.4429, lng: 79.2707 },
          { lat: 29.9695, lng: 78.3054 }
        ],
        'West Bengal': [
          { lat: 21.5598, lng: 85.0048 },
          { lat: 27.1466, lng: 88.7518 },
          { lat: 22.0558, lng: 87.8560 }
        ]
      };
      

      // Remove existing state polygon if any
      if (statePolygon) {
        statePolygon.setMap(null);
      }

      // Construct the polygon for the selected state
      const polygonCoords = stateBoundaries[selectedState].map(coord => ({
        lat: parseFloat(coord.lat),
        lng: parseFloat(coord.lng)
      }));
      const newStatePolygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: 'red',
        strokeWeight: 5,
        fillColor: 'transparent',
        fillOpacity: 0,
      });
      newStatePolygon.setMap(map);
      setStatePolygon(newStatePolygon);

      // Set center and zoom level based on selected state
      const bounds = new window.google.maps.LatLngBounds();
      polygonCoords.forEach(coord => bounds.extend(coord));
      map.fitBounds(bounds);
    }
  }, [map, selectedState, statePolygon]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <div className="maps-container">
      <div className="maps-select-container">
        <select className="maps-select" value={selectedState} onChange={handleStateChange}>
          <option value="">Select a State</option>
          <option value="Andhra Pradesh">Andhra Pradesh</option>
          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
          <option value="Assam">Assam</option>
          <option value="Bihar">Bihar</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Bengal">West Bengal</option>

        </select>
      </div>
      <div id="map" className="maps-map-container"></div>
    </div>
  );
};

const Maps = () => {
  return (
    <div className='maps-main-container'>
      <GoogleMap />
    </div>
  );
};

export default Maps;
