import "./index.css"
import { useParams } from "react-router-dom"
import { Link,Navigate } from "react-router-dom";
import {useState,useEffect} from 'react'
import { ThreeDots } from "react-loader-spinner";
import Cookies from 'js-cookie'
import Navbar from "../../Navbar";
import Dashboard from "../../Dashboard";
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
const UserVideos = () => {
    const {email,channelName} = useParams()
    const [isLoading,setIsLoading] = useState(false)
    console.log(email)
    console.log(channelName)
    const [days,setDays] = useState([])
    const [dateArray,setDateArray] = useState([])
    const [channelsList,setChannelsList] = useState([])
    const [username,setUserName] = useState('');
    const [activeTab, setActiveTab] = useState('Table');

    useEffect(() => {
        // Code that would run on component mount
        const getVideos = async () => {
          setIsLoading(true);
          try{
            // const options  = {
            //   method : "GET",
            //   headers : {
            //     "Content-Type" : "application/json"
            //   },
            //   body : JSON.stringify({email})
            // }
            const response = await fetch(`https://js-member-backend.vercel.app/users`);
            if(response.ok===true){
            const data = await response.json()
            // console.log(data)
            const User = data.filter((ele) => ele.email===email)[0]
            const Channels = User.channels
            console.log(Channels)
            const channels = Channels.filter((ele) => ele.channelName===channelName)[0].videos
            // if(channels)
            setChannelsList(channels)
            // console.log(channels)
            // console.log("Channels : " + channels)
            // const videos = channels.videos
            // setChannelsList(videos)
            // console.log(User.channels)
            // setUserName(User.)
            // let arr = []
            // // if(User.channels.length!==0){
            // for(let values of User.channels)
            // {
            //     let totviews = 0;
            //     // console.log(values)
            //     const newObj = {
            //         ChannelName : values.channelName,
            //         NoOfVideos : values.videos.length,
            //         NoOfViews : 0
            //     }
            //     arr = [...arr,newObj]
            // }
        // }
            // setChannelsList(arr)
            // const {days,videoDate,dateArray} = data.videoItem
            setIsLoading(false)
            }
            }
          catch(Err){
            console.log(`Error Occurred : ${Err}`)
          }
        }
        // Example: getVideos();
        getVideos()
      }, [email]); // Empty dependency array means it runs only once on mount  }

    const onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    const onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }

    let totviews=0,totrewardpoints=0;
    for(let values of channelsList)
    {
        totviews += parseInt(values.days[0])
        totrewardpoints += parseInt(values.days[0])/100   
    }
        // console.log(channelsList)

        const token = Cookies.get("jwt_token")
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
        <>
        <Navbar/>
        <div className="app-main-container">
            <div className="app-sidebar-container">
                <Dashboard/>
            </div>
            <div className="app-content-container">
        
            <div className="ytpa-main-container">

            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Channel : {channelName}</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                        )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={this.onSubmitUrl}>
                        <input value={userinput} onChange={this.onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th>
                        Video Name
                    </th>
                    <th>
                        Video Url
                    </th>
                    <th>
                        No of Views
                    </th>
                    <th>
                        Reward Points
                    </th>
                </tr>
                </thead>
                <tbody>
   {channelsList.length !== 0 && (
    channelsList.map((ele, index) => (
      <tr key={index}> {/* Added key prop for each table row */}
      <td>
        <Link to={`/ytmcvideo/videoinfo/${email}/${channelName}/${ele.videoId}`}>
        {ele.videoName}
        </Link>
      </td>
        <td>
            <a href={ele.videoUrl} rel="noreferrer" target="_blank">
            Link
            </a>
        </td>
        <td>{ele.days[0]}</td>
        <td>{parseInt(ele.days[0])/100}</td>
      </tr>
    ))
  )}
</tbody>
<tfoot>
    <tr>
        <th colSpan={2}>Total</th>
        <th>{totviews}</th>
        <th>{totrewardpoints}</th>
    </tr>
</tfoot>
            
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
            </>)
}


export default UserVideos