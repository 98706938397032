import "./index.css"
import {Link} from 'react-router-dom'
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import ReferralItem from "../ReferralItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

class Referral extends Component {

    state = {activeTab:'Table',usersList:[], Name:'',Mobileno:'',Email:'',ReferralCode:''}

    componentDidMount(){
        this.getContentList()
    }

    getContentList = async () => {
        const response = await fetch(`https://js-member-backend.vercel.app/getreferraldetails`);
        try{
        const data = await response.json()
        // console.log(data)
        this.setState({usersList:data.Referral})
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

    onChangeName = (e) => {
        this.setState({ Name: e.target.value });
    }
    
    onChangeMobile = (e) => {
        this.setState({ Mobileno: e.target.value });
    }
    
    onChangeEmail = (e) => {
        this.setState({ Email: e.target.value });
    }
    
    onChangeReferral = (e) => {
        this.setState({ ReferralCode: e.target.value });
    }
    

    getYouTubeVideoID = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else if (match && match[1].includes('shorts/')) {
            // Extract video ID from shorts URL format
            const videoIdIndex = url.indexOf('shorts/') + 'shorts/'.length;
            const videoId = url.substring(videoIdIndex, videoIdIndex + 11);
            return videoId;
        } else {
            console.error('The supplied URL is not a valid YouTube URL');
            return url;
        }
    }

    onDeleteUser = async (value) => {
        try{
            const options = {
                method : "DELETE",
                headers : {
                    "Content-Type" : "application/json"
                } 
            }
            const response = await fetch(`https://js-member-backend.vercel.app/referral/${value}`,options);
            const data = await response.json()
            console.log(data);
            window.location.reload()
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

    addFormData = async (obj) => {
        const options = {
            method :"POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
        }
        const response = await fetch(`https://js-member-backend.vercel.app/addreferral`,options)
        const data = await response.json();
        console.log(data);
    }

    onSubmitUrl = (event) => {
        event.preventDefault();
            const {usersList,Name,Mobileno,Email,ReferralCode} = this.state 
            const newObj = {
                id : uuidv4(),
                Name,Mobileno,Email,ReferralCode
            } // Set the image source to the preview image element
            this.addFormData(newObj)
            const newList = [...usersList,newObj]
            localStorage.setItem("users",JSON.stringify(newList))
            this.setState({usersList:newList, Name:'', Mobileno:'', Email:'', ReferralCode:''})
    }

    render(){
        const {usersList,Name,Mobileno,Email,ReferralCode} = this.state


        return (
            <>
            <Navbar/>
            <div className="app-main-container">
                <div className="app-sidebar-container">
                    <Dashboard/>
                </div>
                <div className="app-content-container">
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Referral</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            <Popup
                        trigger={<button className="addBtn" type="button"> Add <FaPlus/> </button>}
                        modal
                        nested
                    >
                        {close => (
                        <div className="modal rcyt-custom-popup">
                            {
                            /* <button className="close " onClick={close}>
                            &times;
                            </button> */
                            }
                            {/* <div className="header popup-cont"> Add Link </div> */}
                            <div className="content rcyt-popup-cont">
                            <form onSubmit={this.onSubmitUrl}>
                                <h2>Add Referral</h2>
                                <div>
                                    <label htmlFor="name">Name</label>
                                    <br />
                                    <input pattern="^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$" placeholder="Enter your Name" onChange={this.onChangeName} className="rcyt-user-input" type="text" id="name" value={Name} required />
                                </div>
                                <div>
                                    <label htmlFor="mobile">Mobile No</label>
                                    <br />
                                    <input pattern="^[6-9]\d{9}$" placeholder="Enter your Mobile No (Indian format)" onChange={this.onChangeMobile} className="rcyt-user-input" type="tel" id="mobile" value={Mobileno} required />
                                </div>
                                <div>
                                    <label htmlFor="email">Email</label>
                                    <br />
                                    <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" placeholder="Enter your Email" onChange={this.onChangeEmail} className="rcyt-user-input" type="email" id="email" value={Email} required />
                                </div>
                                <div>
                                    <label htmlFor="referral">Referral Code</label>
                                    <br />
                                    <input placeholder="Enter Referral Code" onChange={this.onChangeReferral} className="rcyt-user-input" type="text" id="referral" value={ReferralCode} required />
                                </div>
                                <div className="actions">
                            <button
                                className="button closeBtn"
                                onClick={() => {
                                console.log('modal closed ');
                                close();
                                }}
                            >
                                Cancel
                            </button>
                            <button className="fetchBtn" type="submit">Add</button>
                            </div>
                            </form>
                            </div>                        
                            </div>
                        )}
                    </Popup>
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                {/* <div className="flex-contrc"> */}
                {/* <div id="leftCont" className="left-containerya">
                <p onClick={this.onClickCont} id="icon" className="nav-icon"><i className="fa-solid fa-bars"></i></p>
                <div id="menuList" className="menu-expanded">
                    <Link to="/">
                    <button className="nav-buttonya">Youtuber</button>
                    </Link>
                    <button className="nav-buttonya">YTCM</button> */}
                    {/* <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Digital Karyakarta</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">D2D</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/stats">
                    <button className="nav-buttonya">Stats</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Camp</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Padayatra</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Padyatri</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Youth Club</button>
                    </Link>
                    <br/>
                    
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">BiharPol</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Events</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Crowd Funding</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Candidates</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Leaders</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">MSME</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Coachings</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Local Influencer</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Voters</button>
                    </Link>
                    <br/> */}
                    {/* <Link className="nav-link" to="/settings">
                    <button className="nav-buttonya">Settings</button>
                    </Link> 
                    <br/>
                </div>

                JS Dashboard Table 
1. Video Tile 
2. Video Link 
3. Source - Telegram || Youtube 
4. Video type - Short || Long
5. Status - Active || Inactive
6. Date 
                </div> */}
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                <table className="ytpa-content-table">
                    <thead>
                <tr>
                    <th>
                        sno
                    </th>
                    <th>
                    Name
                    </th>
                    <th>
                    Mobile No
                    </th>
                    <th>
                    Email
                    </th>
                    <th>
                    Referral Code
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                </thead>
                <tbody>
                        {(usersList.length!==0) && (
                        usersList.map((ele,index) => <ReferralItem sno={index+1} key={ele.id} userDetails={ele} onDeleteUser={this.onDeleteUser}/>
                        ))}
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        )
    }
}

export default Referral