import React, { useState, useEffect } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const D2DIncharge = () => {
  const [activeTab, setActiveTab] = useState('Table');
  const [d2dList, setd2dList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const {campCluster} = params;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://js-member-backend.vercel.app/getd2dinchargereportdata/${campCluster}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      

      const data = await response.json();
      let l = data.result;
      l.sort((a, b) => new Date(b.d2ddate) - new Date(a.d2ddate));
      const newList = (l).map((values) => ({
        teamlead : values.teamlead,
        id : values.id,
d2ddate : (new Date(values.d2ddate)).toLocaleDateString('en-GB'),
district : values.district,
block : values.block,
panchayat1 : (values.villageanalysis).panchayat1,
nameofvillage1 : (values.villageanalysis).nameofvillage1,
totalhouseholdscovered1 : (values.villageanalysis).totalhouseholdscovered1,
totalssmade1 : (values.villageanalysis).totalssmade1,
socialmediainfluencersonboarded1 : (values.villageanalysis).socialmediainfluencersonboarded1,
whatsappjoined1 : (values.villageanalysis).whatsappjoined1,
villagecompleted1 : (values.villageanalysis).villagecompleted1,
panchayat2 : (values.villageanalysis).panchayat2,
nameofvillage2 : (values.villageanalysis).nameofvillage2,
totalhouseholdscovered2 : (values.villageanalysis).totalhouseholdscovered2,
totalssmade2 : (values.villageanalysis).totalssmade2,
socialmediainfluencersonboarded2 : (values.villageanalysis).socialmediainfluencersonboarded2,
whatsappjoined2 : (values.villageanalysis).whatsappjoined2,
villagecompleted2 : (values.villageanalysis).villagecompleted2,
campCluster : values.campCluster,
addedByemail : values.addedByemail,
date : values.date,
time : values.time
      }));
      setd2dList(newList);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const onClickLogout = () => {
    Cookies.remove('jwt_token');
    // Perform any additional logout logic here
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Additional logic based on tab click if needed
  };

  const renderTable = () => {
    return (
      <div className="ytpa-table-container">
        <table className="ytpa-content-table">
          <thead>
          <tr>
      <th rowSpan="2">S.NO.</th>
      <th rowSpan="2">Camp Cluster</th>
      <th rowSpan="2">Date</th>
      <th rowSpan="2">Team Lead Name</th>
      <th rowSpan="2">District</th>
      <th rowSpan="2">Block</th>
      <th style={{textAlign:'center',borderBottom:'1px solid white'}} colSpan="7">Village1</th>
      <th style={{textAlign:'center',borderBottom:'1px solid white'}} colSpan="7">Village2</th>
      <th rowSpan="2">Actions</th>
    </tr>
    <tr>
      <th>Panchayat</th>
      <th>Name of Village</th>
      <th>Total Households Covered</th>
      <th>Total SS Made</th>
      <th>Social Media Influencers Onboarded</th>
      <th>WhatsApp Joined</th>
      <th>Is Village Completed</th>
      <th>Panchayat</th>
      <th>Name of Village</th>
      <th>Total Households Covered</th>
      <th>Total SS Made</th>
      <th>Social Media Influencers Onboarded</th>
      <th>WhatsApp Joined</th>
      <th>Is Village Completed</th>
    </tr>
  </thead>
          <tbody>
            {d2dList.map((ele, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{ele.campCluster}</td>
                <td>{ele.d2ddate}</td>
                <td>{ele.teamlead}</td>
                <td>{ele.district}</td>
                <td>{ele.block}</td>
                <td>{ele.panchayat1}</td>
                <td>{ele.nameofvillage1}</td>
                <td>{ele.totalhouseholdscovered1}</td>
                <td>{ele.totalssmade1}</td>
                <td>{ele.socialmediainfluencersonboarded1}</td>
                <td>{ele.whatsappjoined1}</td>
                <td>{ele.villagecompleted1}</td>
                <td>{ele.panchayat2}</td>
                <td>{ele.nameofvillage2}</td>
                <td>{ele.totalhouseholdscovered2}</td>
                <td>{ele.totalssmade2}</td>
                <td>{ele.socialmediainfluencersonboarded2}</td>
                <td>{ele.whatsappjoined2}</td>
                <td>{ele.villagecompleted2}</td>
                <td style={{textAlign:'center',color:'red'}} >
                    <MdDelete size={20}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <div className="app-main-container">
        <div className="app-sidebar-container">
          <Dashboard />
        </div>
        <div className="app-content-container">
          <div className="ytpa-main-container">
            <div className="top-cards">
              <div className="top-stats-design">
                <h1 className="main-stats-heading">D2D Incharge {`> ${campCluster}`} </h1>
                <div>
                  <p>{new Date().toDateString()}</p>
                </div>
              </div>
              <div className="header">
                <div className="tabs">
                  <div
                    className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Table')}
                  >
                    Table
                  </div>
                  {/* Additional tabs if needed */}
                </div>
                <div className="action-buttons">
                  <button className="action-button">
                    <FaCalendarAlt />
                  </button>
                  <button className="action-button">
                    <FaMapMarkerAlt />
                  </button>
                  <button className="action-button">
                    <FaSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className="ytpa-main-inner-container">
              {isLoading && <ThreeDots color="gray" height={50} width={50} />}
              {!isLoading && renderTable()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default D2DIncharge;
