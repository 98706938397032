import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'

const CampTeamItem = (props) => {
    const {itemDetails,sno} = props
    const {                
     teamName,
      teamNumber,
      teamLeadName,
      teamLeadMobile,
      time,
      campCluster
    } = itemDetails

    return (
        <tr>
            <td>{sno}</td>
            <td>{campCluster}</td>
            <td>
              {teamName}
            </td>
            <td>{teamNumber}</td>
            <td>{teamLeadName}</td>
            <td>{teamLeadMobile}</td>
            <td>{new Date(time.split(',')[0]).toLocaleDateString('en-GB')} & {time.split(',')[1]}</td>
        </tr>
    )
}
export default CampTeamItem