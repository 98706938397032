import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'
import { MdDelete } from "react-icons/md";

const RegCampMemberItem = (props) => {
    const {itemDetails,sno,onClickApprove,onClickReject,onDeleteMember} = props
    const {
      name,
      campCluster,
      dob,
      fathername,
      district,
      block,
      panchayat,
      imageData,
      village,
      aadhaarNo,
      mobileno,
      referral,
      Googlename,
      email,
      regstatus,
      kycstatus,
      date,
      time
    } = itemDetails
    const onApprove = () => {
        onClickApprove(email)
    }
    const onReject = () => {
        onClickReject(email)
    }
    const onDelete = () => {
      onDeleteMember(email)
    }
    return (
        <tr>
            <td>{sno}</td>
            <td>{campCluster}</td>
            <td>
            <Popup
    trigger={<img style={{cursor:'pointer'}} src={imageData} height="100" width="100" alt="memberimage"/>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
          <h1>Uploaded Image</h1>
        <img src={imageData} height="300" width="400" alt="memberimage"/>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
            </td>
            <td>
              {name}
            </td>
            <td>{email}</td>
            <td>{dob}</td>
            <td>{fathername}</td>
            <td>{district}</td>
            {/* <td>{constituency}</td> */}
            <td>{block}</td>
            <td>{panchayat}</td>
            <td>{village}</td>
            <td>{aadhaarNo}</td>
            <td>{mobileno}</td>
            <td>{referral}</td>
            {/* <td>{whatsappNumber}</td> */}
            <td>{regstatus}</td>
            {/* <td>{referral}</td> */}
            {regstatus==="rejected" && (<td style={{color:'red'}}>Rejected</td>)}
            {regstatus==="approved" && (<td style={{color:'green'}}>Approved</td>)}
            {regstatus==="pending" && (<td>
              <Popup
    trigger={<button className="edit-Btn" type="button"> Approve </button>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
            <h1>Are you sure want to Approve?</h1>
            <p>Please reload the page after clicking on approve.</p>
            <button className="edit-Btn" type="button" onClick={() => {
              onApprove()
              close()
            }}>Approve</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
                {/* <Popup
  trigger={<button className="edit-Btn" type="button"> Approve </button>}
  modal
  nested
>
  {close => (
    <div className="modal custom-popup">
      <div className="content popup-cont">
        <h3>Are you sure you want to approve?</h3>
        <button
          className="edit-Btn"
          onClick={onApprove}
          type="button"
        >
          Approve
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup> */}
<Popup
  trigger={<button className="delete-Btn" type="button"> Reject </button>}
  modal
  nested
>
  {close => (
    <div className="modal rcyt-custom-popup">
      <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
        <h1>Are you sure you want to Reject?</h1>
        <button
          className="delete-Btn"
          onClick={() => {
            onReject()
            close()
          }}
          type="button"
        >
          Reject
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup>

                {/* <button onClick={onApprove} type="button" className="fetchBtn">Approve</button> */}
                {/* <button type="button" className="closeBtn">Reject</button> */}
            </td>)}
            <td>
              <Popup
    trigger={<button style={{backgroundColor:'transparent',borderWidth:'0',margin:'auto'}} type="button"> <MdDelete style={{color:'red'}} size={20}/> </button>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
            <h1>Are you sure want to Delete?</h1>
            {/* <p>Please reload the page after clicking on approve.</p> */}
            <button className="closeBtn" type="button" onClick={() => {
              onDelete()
              close()
            }}>Delete</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
  </td>
        </tr>
    )
}
export default RegCampMemberItem