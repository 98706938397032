import "./index.css"
import { useParams } from "react-router-dom"
import { Link,Navigate } from "react-router-dom";
import {useState,useEffect} from 'react'
import { ThreeDots } from "react-loader-spinner";
import Cookies from 'js-cookie'
import Navbar from "../../Navbar";
import Dashboard from "../../Dashboard";
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"

const UserChannels = () => {
    const {email} = useParams()
    const [isLoading,setIsLoading] = useState(false)
    const [days,setDays] = useState([])
    const [dateArray,setDateArray] = useState([])
    const [channelsList,setChannelsList] = useState([])
    const [username,setUserName] = useState('');
    const [activeTab, setActiveTab] = useState('Table');
    let totviews = 0,totvideos = 0;

    useEffect(() => {
        // Code that would run on component mount
        const getVideos = async () => {
          setIsLoading(true);
          try{
            // const options  = {
            //   method : "GET",
            //   headers : {
            //     "Content-Type" : "application/json"
            //   },
            //   body : JSON.stringify({email})
            // }
            const response = await fetch(`https://js-member-backend.vercel.app/users`);
            if(response.ok===true){
            const data = await response.json()
            // console.log(data)
            const User = data.filter((ele) => ele.email===email)[0]
            // console.log(User.channels)
            setUserName(User.name)
            let arr = []
            // if(User.channels.length!==0){
            for(let values of User.channels)
            {
                // console.log(values)
                const newObj = {
                    ChannelName : values.channelName,
                    NoOfVideos : values.videos.length,
                    NoOfViews : 0
                }
                arr = [...arr,newObj]
            }
        // }
            setChannelsList(arr)
            // const {days,videoDate,dateArray} = data.videoItem
            setIsLoading(false)
            }
            }
          catch(Err){
            console.log(`Error Occurred : ${Err}`)
          }
        }
        // Example: getVideos();
        getVideos()
      }, [email,totvideos]); // Empty dependency array means it runs only once on mount  }

    const onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    const onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }

    for(let values of channelsList)
    {
        totvideos += values.NoOfVideos
    }
    

        const token = Cookies.get("jwt_token")
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
        <>
        <Navbar/>
        <div className="app-main-container">
            <div className="app-sidebar-container">
                <Dashboard/>
            </div>
            <div className="app-content-container">
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Username : {username}</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                        )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th>
                        Channel Name
                    </th>
                    <th>
                        No of Videos
                    </th>
                    <th>
                        No of Views
                    </th>
                </tr>
                </thead>
                <tbody>
  {channelsList.length !== 0 && (
    channelsList.map((ele, index) => (
      <tr key={index}> {/* Added key prop for each table row */}
        <td>
            <Link to={`/user/videos/${email}/${ele.ChannelName}`}>
            {ele.ChannelName}
            </Link>
        </td>
        <td>{ele.NoOfVideos}</td>
        <td>{ele.NoOfViews}</td>
      </tr>
    ))
  )}
</tbody>
<tfoot>
    <tr>
        <th>Total</th>
        <th>{totvideos}</th>
        <th>{totviews}</th>
    </tr>
</tfoot>
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
        </>
        )
}


export default UserChannels