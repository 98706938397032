import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'

const CampSubAdminItem = (props) => {
    const {itemDetails,sno} = props
    console.log(itemDetails)
    const {                
      name,
      email,
      mobileNo,
      accessItems,
      time,
      campId
    } = itemDetails
    return (
        <tr>
            <td>{sno}</td>
            {/* <td>{campId}</td> */}
            <td>
              {name}
            </td>
            <td>{email}</td>
            <td>{mobileNo}</td>
            <td>{accessItems.join(", ")}</td>
            <td>{new Date(time.split(',')[0]).toLocaleDateString('en-GB')} & {time.split(',')[1]}</td>
        </tr>
    )
}
export default CampSubAdminItem