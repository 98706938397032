import "./index.css"
import {Component} from 'react'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom"
import {ThreeDots} from 'react-loader-spinner'
import RegistrationItem from "../RegistrationItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"

// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

const options = [
    { "OptionId": "ARARIA" },
    { "OptionId": "ARWAL" },
    { "OptionId": "AURANGABAD" },
    { "OptionId": "BANKA" },
    { "OptionId": "BEGUSARAI" },
    { "OptionId": "BHAGALPUR" },
    { "OptionId": "BHOJPUR" },
    { "OptionId": "BUXAR" },
    { "OptionId": "DARBHANGA" },
    { "OptionId": "GAYA" },
    { "OptionId": "GOPALGANJ" },
    { "OptionId": "JAHANABAD" },
    { "OptionId": "JAMUI" },
    { "OptionId": "KAIMUR (BHABHUA)" },
    { "OptionId": "KATIHAR" },
    { "OptionId": "KISHANGANJ" },
    { "OptionId": "KHAGARIA" },
    { "OptionId": "LAKHISARAI" },
    { "OptionId": "MADHEPURA" },
    { "OptionId": "MADHUBANI" },
    { "OptionId": "MUNGER" },
    { "OptionId": "MUZAFFARPUR" },
    { "OptionId": "NALANDA" },
    { "OptionId": "NAWADA" },
    { "OptionId": "PASCHIM CHAMPARAN" },
    { "OptionId": "PATNA" },
    { "OptionId": "PURNIA" },
    { "OptionId": "PURVI CHAMPARAN" },
    { "OptionId": "ROHTAS" },
    { "OptionId": "SAHARSA" },
    { "OptionId": "SAMASTIPUR" },
    { "OptionId": "SARAN" },
    { "OptionId": "SHEIKHPURA" },
    { "OptionId": "SHEOHAR" },
    { "OptionId": "SITAMARHI" },
    { "OptionId": "SIWAN" },
    { "OptionId": "SUPAUL" },
    { "OptionId": "VAISHALI" }
]

  const constituencies = {
    "SELECT" : ['SELECT'],
    "PASCHIM CHAMPARAN": [
      "Valmiki Nagar",
      "Ramnagar (SC)",
      "Narkatiaganj",
      "Bagaha",
      "Lauriya",
      "Nautan",
      "Chanpatia",
      "Bettiah",
      "Sikta"
    ],
    "PURVI CHAMPARAN": [
      "Raxaul",
      "Sugauli",
      "Narkatia",
      "Harsidhi (SC)",
      "Govindganj",
      "Kesaria",
      "Kalyanpur",
      "Pipra",
      "Madhuban",
      "Motihari",
      "Chiraia",
      "Dhaka"
    ],
    "SHEOHAR": ["Sheohar"],
    "SITAMARHI": [
      "Riga",
      "Bathnaha (SC)",
      "Parihar",
      "Sursand",
      "Bajpatti",
      "Sitamarhi",
      "Runnisaidpur",
      "Belsand"
    ],
    "MADHUBANI": [
      "Harlakhi",
      "Benipatti",
      "Khajauli",
      "Babubarhi",
      "Bisfi",
      "Madhubani",
      "Rajnagar (SC)",
      "Jhanjharpur",
      "Phulparas",
      "Laukaha"
    ],
    "SUPAUL": [
      "Nirmali",
      "Pipra",
      "Supaul",
      "Triveniganj (SC)",
      "Chhatapur"
    ],
    "ARARIA": [
      "Narpatganj",
      "Raniganj (SC)",
      "Forbesganj",
      "Araria",
      "Jokihat",
      "Sikti"
    ],
    "KISHANGANJ": [
      "Bahadurganj",
      "Thakurganj",
      "Kishanganj",
      "Kochadhaman"
    ],
    "PURNIA": [
      "Amour",
      "Baisi",
      "Kasba",
      "Banmankhi (SC)",
      "Rupauli",
      "Dhamdaha",
      "Purnia"
    ],
    "KATIHAR": [
      "Katihar",
      "Kadwa",
      "Balrampur",
      "Pranpur",
      "Manihari (ST)",
      "Barari",
      "Korha (SC)"
    ],
    "MADHEPURA": [
      "Alamnagar",
      "Bihariganj",
      "Singheshwar (SC)",
      "Madhepura"
    ],
    "SAHARSA": [
      "Sonbarsha (SC)",
      "Saharsa",
      "Simri Bakhtiarpur",
      "Mahishi"
    ],
    "DARBHANGA": [
      "Kusheshwar Asthan (SC)",
      "Gaura Bauram",
      "Benipur",
      "Alinagar",
      "Darbhanga Rural",
      "Darbhanga",
      "Hayaghat",
      "Bahadurpur",
      "Keoti",
      "Jale"
    ],
    "MUZAFFARPUR": [
      "Gaighat",
      "Aurai",
      "Minapur",
      "Bochaha (SC)",
      "Sakra (SC)",
      "Kurhani",
      "Muzaffarpur",
      "Kanti",
      "Baruraj",
      "Paroo",
      "Sahebganj"
    ],
    "GOPALGANJ": [
      "Baikunthpur",
      "Barauli",
      "Gopalganj",
      "Kuchaikote",
      "Bhorey (SC)",
      "Hathua"
    ],
    "SIWAN": [
      "Siwan",
      "Ziradei",
      "Darauli (SC)",
      "Raghunathpur",
      "Daraundha",
      "Barharia",
      "Goriakothi",
      "Maharajganj"
    ],
    "SARAN": [
      "Ekma",
      "Manjhi",
      "Baniapur",
      "Taraiya",
      "Marhaura",
      "Chapra",
      "Garkha (SC)",
      "Amnour",
      "Parsa",
      "Sonepur"
    ],
    "VAISHALI": [
      "Hajipur",
      "Lalganj",
      "Vaishali",
      "Mahua",
      "Raja Pakar (SC)",
      "Raghopur",
      "Mahnar",
      "Patepur (SC)"
    ],
    "SAMASTIPUR": [
      "Kalyanpur (SC)",
      "Warisnagar",
      "Samastipur",
      "Ujiarpur",
      "Morwa",
      "Sarairanjan",
      "Mohiuddinnagar",
      "Bibhutipur",
      "Rosera (SC)",
      "Hasanpur"
    ],
    "BEGUSARAI": [
      "Cheria Bariarpur",
      "Bachhwara",
      "Teghra",
      "Matihani",
      "Sahebpur Kamal",
      "Begusarai",
      "Bakhri (SC)"
    ],
    "KHAGARIA": [
      "Alauli (SC)",
      "Khagaria",
      "Beldaur",
      "Parbatta"
    ],
    "BHAGALPUR": [
      "Bihpur",
      "Gopalpur",
      "Pirpainti (SC)",
      "Kahalgaon",
      "Bhagalpur",
      "Sultanganj",
      "Nathnagar"
    ],
    "BANKA": [
      "Amarpur",
      "Dhauraiya (SC)",
      "Banka",
      "Katoria (ST)",
      "Belhar"
    ],
    "MUNGER": [
      "Tarapur",
      "Munger",
      "Jamalpur"
    ],
    "LAKHISARAI": [
      "Suryagarha",
      "Lakhisarai"
    ],
    "SHEIKHPURA": [
      "Sheikhpura",
      "Barbigha"
    ],
    "NALANDA": [
      "Asthawan",
      "Biharsharif",
      "Rajgir (SC)",
      "Islampur",
      "Hilsa",
      "Nalanda",
      "Harnaut"
    ],
    "PATNA": [
        "Mokama",
        "Barh",
        "Bakhtiarpur",
        "Digha",
        "Bankipur",
        "Kumhrar",
        "Patna Sahib",
        "Fatuha",
        "Danapur",
        "Maner",
        "Phulwari (SC)",
        "Masaurhi (SC)",
        "Paliganj",
        "Bikram"
      ],
      "BHOJPUR": [
        "Sandesh",
        "Barhara",
        "Arrah",
        "Agiaon (SC)",
        "Tarari",
        "Jagdishpur",
        "Shahpur"
      ],
      "BUXAR": [
        "Brahampur",
        "Buxar",
        "Dumraon",
        "Rajpur (SC)"
      ],
      "KAIMUR (BHABHUA)": [
        "Ramgarh",
        "Mohania (SC)",
        "Bhabua",
        "Chainpur"
      ],
      "ROHTAS": [
        "Chenari (SC)",
        "Sasaram",
        "Kargahar",
        "Dinara",
        "Nokha",
        "Dehri",
        "Karakat"
      ],
      "ARWAL": [
        "Arwal",
        "Kurtha"
      ],
      "JAHANABAD": [
        "Jehanabad",
        "Ghosi",
        "Makhdumpur (SC)"
      ],
      "AURANGABAD": [
        "Goh",
        "Obra",
        "Nabinagar",
        "Kutumba (SC)",
        "Aurangabad",
        "Rafiganj"
      ],
      "GAYA": [
        "Gurua",
        "Sherghati",
        "Imamganj (SC)",
        "Barachatti (SC)",
        "Bodh Gaya (SC)",
        "Gaya Town",
        "Tikari",
        "Belaganj",
        "Atri",
        "Wazirganj"
      ],
      "NAWADA": [
        "Rajauli (SC)",
        "Hisua",
        "Nawada",
        "Gobindpur",
        "Warsaliganj"
      ],
      "JAMUI": [
        "Sikandra (SC)",
        "Jamui",
        "Jhajha",
        "Chakai"
      ]
    }

const districts = options.map((ele) => ({district : ele.OptionId,NoOfYTCM:0,NoOfChannels:0,NoOfVideos:0,TotalViews:0}))

let dis = [];

class YTCMStats extends Component {

    state = {activeTab:'Table',regusersList:[],isLoading:false}

    componentDidMount() {
      this.getUsers();
  }
  
  getVideosSum = (arg) => {
      let sumo = 0;
      for (let values of arg) {
          if (values.videos) sumo += values.videos.length;
      }
      return sumo;
  };
  getTotalViews = (arg) => {
    let totviews = 0;
    for(let values of arg)
    {
      for(let values2 of values.videos)
      {
        for(let values3 of values2.days)
        {
          totviews += parseInt(values3)
        }
      }
    }
    return totviews;
  }

  updateDistricts = (newList) => {
    // Create a dictionary to store aggregated values
    const districtAggregates = {};

    // Iterate through newList
    newList.forEach((ele) => {
        const { district, NoOfVideos, NoOfChannels, TotalViews } = ele;

        // Initialize district aggregates if not already present
        if (!districtAggregates[district]) {
            districtAggregates[district] = {
                NoOfVideos: 0,
                NoOfChannels: 0,
                NoOfYTCM: 0,
                TotalViews:0
            };
        }

        // Update aggregated values
        districtAggregates[district].NoOfVideos += NoOfVideos;
        districtAggregates[district].NoOfChannels += NoOfChannels.length;
        districtAggregates[district].NoOfYTCM += 1;
        districtAggregates[district].TotalViews += TotalViews
    });

    // Update districts based on aggregated values
    districts.forEach((ele2, index) => {
        const { district } = ele2;
        if (districtAggregates[district]) {
            districts[index] = {
                ...ele2,
                ...districtAggregates[district]
            };
        }
    });

    // Now districts should be updated correctly
};


  // updateDistricts = (newList) => {
  //   newList.forEach((ele) => {
  //     districts.forEach((ele2, index) => {
  //         if (ele2.district === ele.district) {
  //             districts[index] = {
  //                 ...ele2,
  //                 NoOfVideos: ele2.NoOfVideos + ele.NoOfVideos,
  //                 NoOfChannels: ele2.NoOfChannels + ele.NoOfChannels.length,
  //                 NoOfYTCM: ele2.NoOfYTCM + 1
  //             };
  //         }
  //     });
  // });
  // }
  
  getUsers = async () => {
      this.setState({ isLoading: true });
      try {
          const response = await fetch("https://js-member-backend.vercel.app/users");
          if (!response.ok) {
              throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          const newList = data.map((values) => ({
              username: values.name,
              email: values.email,
              state: values.state,
              district: values.district,
              constituency: values.constituency,
              channelUrl: values.channelUrl,
              whatsappNumber: values.whatsappNumber,
              regstatus: values.regstatus,
              id: values._id,
              block: values.block,
              NoOfChannels: values.channels,
              NoOfVideos: this.getVideosSum(values.channels),
              TotalViews : this.getTotalViews(values.channels)
          }));
  
          this.updateDistricts(newList)
          
  
          this.setState({ regusersList: newList, isLoading: false });
      } catch (error) {
          console.error("Error fetching data:", error);
          this.setState({ isLoading: false });
      }
  };
  


    // componentDidMount = () =>
    // {
    //     this.getUsers()
    // }

    // getVideosSum = (arg) => {
    //   let sumo = 0
    //   for(let values of arg){
    //   if(values.videos)
    //   sumo += values.videos.length
    //   }
    //   return sumo
    // }

    // getUsers = async () => {
    //     this.setState({ isLoading: true });
    //     try {
    //         const response = await fetch("https://js-member-backend.vercel.app/users");
    //         if (!response.ok) {
    //             throw new Error("Failed to fetch data");
    //         }
    //         const data = await response.json();
    //         const newList = data.map((values) => ({
    //             username: values.name,
    //             email: values.email,
    //             state: values.state,
    //             district: values.district,
    //             constituency: values.constituency,
    //             channelUrl: values.channelUrl,
    //             whatsappNumber: values.whatsappNumber,
    //             regstatus: values.regstatus,
    //             id: values._id,
    //             block:values.block,
    //             NoOfChannels : values.channels,
    //             NoOfVideos : this.getVideosSum(values.channels)
    //         }));
    //         // console.log(newList)
    //         newList.forEach((ele) => {
    //           districts.forEach((ele2, index) => {
    //             if (ele2.district === ele.district) {
    //               console.log(ele)
    //                 districts[index] = {
    //                     ...ele2,
    //                     NoOfVideos: ele2.NoOfVideos + ele.NoOfVideos,
    //                     NoOfChannels: ele2.NoOfChannels + ele.NoOfChannels.length,
    //                     NoOfYTCM: ele2.NoOfYTCM + 1
    //                 };
    //                 console.log(districts[index])
    //                 console.log("Break")
    //             }
    //         });
    //         })
    //         // console.log(dis)
    //         this.setState({ regusersList: newList, isLoading: false });
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         this.setState({ isLoading: false });
    //     }
    // }
    

    // getUsers = async () => {
    //     this.setState({isLoading:true})
    //     const response = await fetch("https://js-member-backend.vercel.app/users")
    //     if(response.ok){
    //     const data = await response.json()
    //     let newList;
    //     const {regusersList} = this.state
    //     for(let values of data)
    //     {
    //         const {name,email,state,district,constituency,channelUrl,whatsappNumber,regstatus} = values
    //         const newObj = {
    //             username:name,
    //             email,
    //             state,
    //             district,
    //             constituency,
    //             channelUrl,
    //             whatsappNumber,
    //             regstatus,
    //             id : values._id
    //         }
    //         console.log(newObj)
    //         newList = [...regusersList,newObj]
    //     }
    //     return newList
    //     }
    // }

    onClickLogout = () => {
        Cookies.remove("jwt_token")
        this.setState({userinput:''})
    }
    
    onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }


    render(){
        const {regusersList,isLoading} = this.state
        // console.log(process.env.API_KEY)
        const token = Cookies.get("jwt_token")
        let totconstituencies = 0,totytcm = 0, totchannels = 0, totvideos = 0, totviews = 0;
        for(let values of districts)
          {
            totconstituencies += (constituencies[values.district]).length
            totytcm += values.NoOfYTCM
            totchannels += values.NoOfChannels
            totvideos += values.NoOfVideos
            totviews += values.TotalViews
          }
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
          <>
          <Navbar/>
          <div className="app-main-container">
            <div className="app-sidebar-container">
              <Dashboard/>
              </div>
          <div className="app-content-container">
            
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Stats YTCM</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                                )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={this.onSubmitUrl}>
                        <input value={userinput} onChange={this.onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th>
                        S.NO
                    </th>
                    <th>
                        District
                    </th>
                    <th>
                        No of Constituency
                    </th>
                    <th>
                        No of YTCM
                    </th>
                    <th>
                        No of Channels
                    </th>
                    <th>
                        No of Videos
                    </th>
                    <th>
                        Total Views
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    districts.map((ele,index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                              <Link to={`/ytcmstats/${ele.district}`}>
                              {ele.district}
                              </Link>
                            </td>
                            <td>{constituencies[ele.district].length}</td>
                            <td>{ele.NoOfYTCM}</td>
                            <td>{ele.NoOfChannels}</td>
                            <td>{ele.NoOfVideos}</td>
                            <td>{ele.TotalViews}</td>
                        </tr>
                    ))
                    // (regusersList.length!==0) && (
                    //     regusersList.map((ele,index) => <RegistrationItem S.NO={index+1} key={ele.id} itemDetails={ele} onClickApprove={this.onClickApprove} onClickReject={this.onClickReject}/>)
                    // )
                }
                </tbody>
                {/* {(itemsList.length!==0) && (
                        itemsList.map((ele,index) => <YoutubeItem S.NO={index+1} key={ele.id} itemDetails={ele} onClickDelete={this.onClickDelete}/>
                        ))} */}
                <tfoot >
                    <tr>
                        <th colSpan="2">Total</th>
                        <th>{totconstituencies}</th>
                        <th>{totytcm}</th>
                        <th>{totchannels}</th>
                        <th>{totvideos}</th>
                        <th>{totviews}</th>
                    </tr>
                </tfoot>
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
            </>)}        
}

export default YTCMStats