import "./index.css"
import Popup from "reactjs-popup"
import { Link } from "react-router-dom"

const CampDetailItem = (props) => {
    const {itemDetails,sno} = props
    const {
        id,
        date,
        campCluster,
        campName,
        campDistricts,
        campInchargeName,
        campInchargeNumber,
        campInchargeGmail,
        totalMembers,
    } = itemDetails

    return (
        <tr>
            <td>{sno}</td>
            <td>{date}</td>
            <td><Link to={`/regcampmembers/${campCluster}`}>{campCluster}</Link></td>
            <td>{campDistricts.join("\n")}</td>
            <td>{campInchargeName}</td>
            <td>{campInchargeNumber}</td>
            <td>{campInchargeGmail}</td>
            <td> <Link to={`/regcampmembers/${campCluster}`}>{totalMembers}</Link></td>
        </tr>
    )
}

export default CampDetailItem