import "./index.css"
import Popup from 'reactjs-popup'
import { useNavigate } from "react-router-dom"
const UserItem = (props) => {
    const {userDetails,Sno,onDeleteUser} = props
    const {
        id,
        recordCount,
        name,
        channelUrl,
        state,
        district,
        selectedConstituency,
        imageUrl,
        whatsappnumber
    } = userDetails
    const onRemoveUser = () => {
        onDeleteUser(id)
    }

    const navigate = useNavigate()
    const onSearchChannel = async () => {
      let curl;
      if(channelUrl.includes('?'))
      curl = (channelUrl.slice(channelUrl.indexOf('@')+1,channelUrl.indexOf('?'))).toLowerCase()
      else
      curl = (channelUrl.slice(channelUrl.indexOf('@')+1)).toLowerCase()
        const response = await fetch(`https://youtube.googleapis.com/youtube/v3/search?part=id%2Csnippet&maxResults=1&q=${curl}&type=channel&key=${process.env.REACT_APP_API_KEY3}`)
        const data = await response.json()
        const {items} = data
        const {channelId} = items[0].id
        const channelName = items[0].snippet.channelTitle
        // console.log(channelName)
        if(items.length!==0)
        navigate(`/channeldetails/${channelName}/${channelId}`)
        else
        alert("Channel Does not Exist")
    }
    return (
            <tr>
            <td>{Sno}</td>
                <td>{name}</td>
                <td>
                  <img className="user-img" src="https://imgs.search.brave.com/mVZUg9yLtbGziLSE-n97XJJN60bZQoIZN199kEK-RIE/rs:fit:500:0:0/g:ce/aHR0cHM6Ly9jZG4t/aWNvbnMtcG5nLmZy/ZWVwaWsuY29tLzI1/Ni8xMzg0LzEzODQw/NjAucG5n" alt="img" />
                </td>
                <td>
                  <button onClick={onSearchChannel}>
                  {channelUrl}
                  </button>
                </td>
                <td>{state}</td>
                <td>{district}</td>
                <td>{selectedConstituency}</td>
                <td>{whatsappnumber}</td>
                <td>
                <Popup
    trigger={<button className="edit-Btn" type="button"> Edit </button>}
    modal
    nested
  >
    {close => (
      <div className="modal custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div className="content popup-cont">
            <h1>Edit Details</h1>
            <input type="text" value={name}/>
            <input type="link" value={channelUrl}/>
            <input type="link" value={channelUrl}/>
            <button className="edit-Btn" type="button">Edit</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
  <br/>
  <Popup
    trigger={<button className="delete-Btn" type="button"> Delete </button>}
    modal
    nested
  >
    {close => (
      <div className="modal custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div className="content popup-cont">
            <h1>Confirm Delete</h1>
            <button className="delete-Btn" type="button" onClick={onRemoveUser}>Delete</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
                </td>
            </tr>
    )
}
export default UserItem