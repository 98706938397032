import React from 'react';
import "./index.css"

const Trainings = () => {


  return (
    <div className='wrapper'>
      <div className='top-cards'>
        <h1 className='main-head-text'>Trainings</h1>
      </div>
      <div className='bottom-container'>
        <h1 className='headd'>hiiiiiiiiiii</h1>
      </div>
    </div>
  );
}

export default Trainings;
