// import "./index.css"
// import {Component} from 'react'
// import {Link} from 'react-router-dom'
// import Cookies from 'js-cookie'
// import { Navigate } from "react-router-dom"
// import {ThreeDots} from 'react-loader-spinner'
// import RegistrationItem from "../RegistrationItem"
// // 1. Add Link 
// // 2. Create a table view with views , likes , comment count 
// // 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

import "./index.css"
import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import RegistrationItem from '../RegistrationItem';
import Navbar from "../../Navbar";
import Dashboard from "../../Dashboard";
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
// const districts = options.map((ele) => ({constituency : ele.OptionId,NoOfYTCM:0,NoOfChannels:0,NoOfVideos:0,TotalViews:0}))

// Function to get the total views from the list of videos
// Functional component YTCMDistrictStats
const YTCMChannelStats = () => {
    const [regusersList, setRegusersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [vidList, setVideos] = useState([]);
    const [email, setEmail] = useState('');
    const [activeTab, setActiveTab] = useState('Table');

    const {district, constituency,username,channelName} = useParams()
    console.log(district)
    console.log(constituency)
    console.log(username)
    console.log(channelName)
    // const constituencyArray = constituencies[district].map((ele) => ({constituency:ele,NoOfYTCM:0,NoOfChannels:0,NoOfVideos:0,TotalViews:0}))
    // console.log(constituencyArray)
    const [di,setDistricts] = useState([])

    useEffect(() => {
        getUsers();
    }, []);

    const getTotalViews = (arg) => {
        let totviews = 0;
        for (let values of arg) {
            for (let values2 of values.videos) {
                for (let values3 of values2.days) {
                    totviews += parseInt(values3);
                }
            }
        }
        return totviews;
    };
    

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://js-member-backend.vercel.app/users');
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const newList = data.map((values) => ({
                username: values.name,
                email: values.email,
                state: values.state,
                district: values.district,
                constituency: values.constituency,
                channelUrl: values.channelUrl,
                whatsappNumber: values.whatsappNumber,
                regstatus: values.regstatus,
                id: values._id,
                block: values.block,
                NoOfChannels: values.channels,
                NoOfVideos: values.channels.reduce((acc, curr) => acc + curr.videos.length, 0),
                TotalViews: getTotalViews(values.channels),
            }));


            const newYTCMList = newList.filter((ele) => (ele.constituency===constituency && ele.username===username))[0]
            setEmail(newYTCMList.email)
            const VideoArray = (newYTCMList.NoOfChannels).filter((ele) => ele.channelName===channelName);
            let finArray = []
            for(let values of VideoArray)
                {
                    finArray = [...finArray,...values.videos]
                }
            setVideos(finArray)
            // setDistricts(newYTCMList)

            // updateDistricts(newList);

            setRegusersList(newList);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const updateDistricts = (newList) => {
        const districtAggregates = {};

        newList.forEach((ele) => {
            const { constituency, NoOfVideos, NoOfChannels, TotalViews } = ele;

            if (!districtAggregates[constituency]) {
                districtAggregates[constituency] = {
                    NoOfVideos: 0,
                    NoOfChannels: 0,
                    NoOfYTCM: 0,
                    TotalViews: 0,
                };
            }

            districtAggregates[constituency].NoOfVideos += NoOfVideos;
            districtAggregates[constituency].NoOfChannels += NoOfChannels.length;
            districtAggregates[constituency].NoOfYTCM += 1;
            districtAggregates[constituency].TotalViews += TotalViews;
        });

        setDistricts((prevDistricts) =>
            prevDistricts.map((ele2) => ({
                ...ele2,
                ...districtAggregates[ele2.constituency],
            }))
        );
    }; 

    const onClickLogout = () => {
        Cookies.remove('jwt_token');
        // Assuming you have a state for user input, you'll need to set it here
    };

    const onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    const onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont");
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }
    
    let totchannels=0,totvideos=0,totviews=0;
    for(let values of di)
    {
        if(values.NoOfChannels)
        totchannels += values.NoOfChannels.length
        if(values.NoOfVideos)
        totvideos += values.NoOfVideos
        if(values.TotalViews)
        totviews += values.TotalViews
    }

    console.log(vidList)


    const token = Cookies.get('jwt_token');
    if (token === undefined) return <Navigate to="/login" replace={true} />;
    return (
    <>
    <Navbar/>
    <div className="app-main-container">
        <div className="app-sidebar-container">
            <Dashboard/>
        </div>
        <div className="app-content-container">
        <div className="ytpa-main-container">
        <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>District : {district} Constituency : {constituency}</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                        )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={onSubmitUrl}>
                        <input value={userinput} onChange={onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th >
                        S.NO
                    </th>
                    <th >
                        Video
                    </th>
                    {/* <th>
                        Day1 View
                    </th>
                    <th>
                        Day2 View
                    </th>
                    <th>
                        Day3 View
                    </th>
                    <th>
                        Day4 View
                    </th>
                    <th>
                        Day5 View
                    </th>
                    <th>
                        Day6 View
                    </th>
                    <th>
                        Day7 View
                    </th> */}
                    <th>
                        VideoUrl
                    </th>
                    <th>
                        Total
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    vidList.map((ele, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Link to={`/ytmcvideo/videoinfo/${email}/${channelName}/${ele.videoId}`}>
                                    {ele.videoName}
                                </Link>
                                </td>
                            {/* <td>{ele.days[0]}</td>
                            <td>{ele.days[1]}</td>
                            <td>{ele.days[2]}</td>
                            <td>{ele.days[3]}</td>
                            <td>{ele.days[4]}</td>
                            <td>{ele.days[5]}</td>
                            <td>{ele.days[6]}</td> */}
                            <td>
                                <a href={ele.videoUrl} target="_blank" rel="noreferrer">Link</a>
                            </td>
                            <td>
                                {ele.days.reduce((total, value) => total + parseInt(value), 0)}
                            </td>
                        </tr>
                    ))
                    // (regusersList.length!==0) && (
                    //     regusersList.map((ele,index) => <RegistrationItem S.NO={index+1} key={ele.id} itemDetails={ele} onClickApprove={onClickApprove} onClickReject={onClickReject}/>)
                    // )
                }
                </tbody>
                {/* {(itemsList.length!==0) && (
                        itemsList.map((ele,index) => <YoutubeItem S.NO={index+1} key={ele.id} itemDetails={ele} onClickDelete={onClickDelete}/>
                        ))} */}
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
        </>
    );
};

export default YTCMChannelStats;