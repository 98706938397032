import "./index.css"
import { useState, useEffect } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { ThreeDots } from 'react-loader-spinner'
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from "react-icons/fa"
import CampTeamItem from "../CampTeamItem"

const CampTeams = () => {
  const [activeTab, setActiveTab] = useState('Table')
  const [regusersList, setRegusersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {campCluster} = useParams()

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const response = await fetch("https://js-member-backend.vercel.app/getcampteams")
      if (!response.ok) {
        throw new Error("Failed to fetch data")
      }
      const data = await response.json()
      const {Teams} = data
      const filteredList = Teams.filter((ele) => ele.campCluster===campCluster)
      setRegusersList(filteredList)
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error)
      setIsLoading(false)
    }
  }

  const onClickLogout = () => {
    Cookies.remove("jwt_token")
  }

  const onClickDropBtn = () => {
    let el = document.getElementsByClassName("drop-item")
    for (let values of el) values.classList.toggle("dis")
  }

  const onClickCont = () => {
    let menu = document.getElementById("menuList")
    let leftCont = document.getElementById("leftCont")
    menu.classList.toggle("menu-collapsed")
    menu.classList.toggle("menu-expanded")
    leftCont.classList.toggle("di")
  }

  const token = Cookies.get("jwt_token")
  if (token === undefined) return <Navigate to="/login" />
  else
    return (
      <>
        <Navbar />
        <div className="app-main-container">
          <div className="app-sidebar-container">
            <Dashboard />
          </div>
          <div className="app-content-container">
            <div className="ytpa-main-container">
              <div className="top-cards">
                <div className='top-stats-design'>
                  <h1 className='main-stats-heading'>Teams</h1>
                  <div>
                    <p>{new Date().toDateString()}</p>
                  </div>
                </div>
                <div className="header">
                  <div className="tabs">
                    <div
                      className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                      onClick={() => setActiveTab('Table')}
                    >
                      Table
                    </div>
                    <div className={`tab ${activeTab === 'Charts' ? 'active' : ''}`}>
                      Charts
                    </div>
                    <div className={`tab ${activeTab === 'Graph' ? 'active' : ''}`}>
                      Graph
                    </div>
                    <div className={`tab ${activeTab === 'Maps' ? 'active' : ''}`}>
                      Maps
                    </div>
                  </div>
                  <div className="action-buttons">
                    <button className="action-button">
                      <FaCalendarAlt />
                    </button>
                    <button className="action-button">
                      <FaMapMarkerAlt />
                    </button>
                    <button className="action-button">
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
              <div className="ytpa-main-inner-container">
                {isLoading && (
                  <div className="ytcm-main-container">
                    <ThreeDots color="gray" height={50} width={50} />
                  </div>
                )}
                {!isLoading && (
                  <div className="ytpa-table-container">
                    <table className="ytpa-content-table">
                      <thead>
                        <tr>
                          <th>Sno</th>
                          <th>Camp Cluster</th>
                          <th>Team Name</th>
                          <th>Team Number</th>
                          <th>Team Lead Name</th>
                          <th>Team Lead Mobile</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {regusersList.length !== 0 && (
                          regusersList.map((ele, index) => (
                            <CampTeamItem sno={index + 1} key={ele.id} itemDetails={ele} />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default CampTeams
