import "./index.css"
import {Component} from 'react'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom"
import {ThreeDots} from 'react-loader-spinner'
import RegistrationItem from "../RegistrationItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"



class Registrations extends Component {

    state = {activeTab:'Table',regusersList:[],isLoading:false}


    componentDidMount()
    {
        this.getUsers()
    }

    onClickReject = async (value) => {
        const options = {
            method : "PUT",
            headers : {
            "Content-Type" : "application/json"
            },
            body : JSON.stringify({newemail:value,newregstatus:"rejected"})
        }
        // https://js-member-backend.vercel.app
        const response = await fetch(`https://js-member-backend.vercel.app/users`,options)
        const data = await response.json()
        console.log(data);
        window.location.reload()
    }

    onClickApprove = async (value) => {
        console.log("I am from approve")
        const options = {
            method : "PUT",
            headers : {
            "Content-Type" : "application/json"
            },
            body : JSON.stringify({newemail:value,newregstatus:"approved"})
        }
        // https://js-member-backend.vercel.app
        const response = await fetch(`https://js-member-backend.vercel.app/users`,options)
        const data = await response.json()
        console.log(data);
        window.location.reload()
    }

    getUsers = async () => {
        this.setState({ isLoading: true });
        try {
            const response = await fetch("https://js-member-backend.vercel.app/users");
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            const newList = data.map((values) => ({
                username: values.name,
                email: values.email,
                state: values.state,
                district: values.district,
                constituency: values.constituency,
                channelUrl: values.channelUrl,
                whatsappNumber: values.whatsappNumber,
                regstatus: values.regstatus,
                id: values._id,
                block:values.block,
                referral : values.referral,
                jsid : values.jsid,
                imageData:values.imageData
            }));
            this.setState({ regusersList: newList, isLoading: false });
        } catch (error) {
            console.error("Error fetching data:", error);
            this.setState({ isLoading: false });
        }
    }
    

    // getUsers = async () => {
    //     this.setState({isLoading:true})
    //     const response = await fetch("https://js-member-backend.vercel.app/users")
    //     if(response.ok){
    //     const data = await response.json()
    //     let newList;
    //     const {regusersList} = this.state
    //     for(let values of data)
    //     {
    //         const {name,email,state,district,constituency,channelUrl,whatsappNumber,regstatus} = values
    //         const newObj = {
    //             username:name,
    //             email,
    //             state,
    //             district,
    //             constituency,
    //             channelUrl,
    //             whatsappNumber,
    //             regstatus,
    //             id : values._id
    //         }
    //         console.log(newObj)
    //         newList = [...regusersList,newObj]
    //     }
    //     return newList
    //     }
    // }

    onClickLogout = () => {
        Cookies.remove("jwt_token")
        this.setState({userinput:''})
    }
    
    onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }

    render(){
        const {regusersList,isLoading} = this.state
        // console.log(process.env.API_KEY)
        const token = Cookies.get("jwt_token")
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
        <>
        <Navbar/>
        <div className="app-main-container">
            <div className="app-sidebar-container">
                <Dashboard/>
            </div>
            <div className="app-content-container">
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Registrations</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytcm-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                                )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={this.onSubmitUrl}>
                        <input value={userinput} onChange={this.onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th>
                        S.NO.
                    </th>
                    <th>
                        JSID
                    </th>
                    <th>
                        Username
                    </th>
                    <th>
                      User Image
                    </th>
                    <th>
                        Email
                    </th>
                    <th>
                        State
                    </th>
                    <th>
                        District
                    </th>
                    <th>
                        Constituency
                    </th>
                    <th>
                        Block
                    </th>
                    <th>
                        ChannelUrl
                    </th>
                    <th>
                        whatsappnumber
                    </th>
                    <th>
                        Registration Status
                    </th>
                    <th>
                        Referrals
                    </th>
                    <th>
                        Accept/Reject
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (regusersList.length!==0) && (
                        regusersList.map((ele,index) => <RegistrationItem sno={index+1} key={ele.id} itemDetails={ele} onClickApprove={this.onClickApprove} onClickReject={this.onClickReject}/>)
                    )
                }
                </tbody>
                {/* {(itemsList.length!==0) && (
                        itemsList.map((ele,index) => <YoutubeItem sno={index+1} key={ele.id} itemDetails={ele} onClickDelete={this.onClickDelete}/>
                        ))} */}
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
            </>
        )}        
}

export default Registrations