import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'

const KYCItem = (props) => {
    const {itemDetails,sno,onClickApprove,onClickReject} = props
    const {     
        name,
        email,
        bank,
        accountNo,
        ifscCode,
        city,
        aadharNo,
        aadharPhoto,
        kycstatus
        } = itemDetails
    const onApprove = () => {
        onClickApprove(email)
        // window.location.reload();
    }
    const onReject = () => {
        onClickReject(email)
    }
    return (
        <tr>
            <td>{sno}</td>
            <td>
                {name}
            </td>
            {/* </Link> */}
            <td>{email}</td>
            <td>{bank}</td>
            <td>{accountNo}</td>
            <td>{ifscCode}</td>
            <td>{city}</td>
            <td>{aadharNo}</td>
            <td>{aadharPhoto}</td>
            {kycstatus==="rejected" && (<td style={{color:'red'}}>Rejected</td>)}
            {kycstatus==="approved" && (<td style={{color:'green'}}>Approved</td>)}
            {kycstatus==="pending" && (<td>
              <Popup
    trigger={<button className="edit-Btn" type="button"> Approve </button>}
    modal
    nested
  >
    {close => (
      <div className="modal custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div className="content popup-cont">
            <h1>Are you sure want to Approve?</h1>
            <p>Please reload the page after clicking on approve.</p>
            <button className="edit-Btn" type="button" onClick={() => {
              onApprove()
              close()
            }}>Approve</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
                {/* <Popup
  trigger={<button className="edit-Btn" type="button"> Approve </button>}
  modal
  nested
>
  {close => (
    <div className="modal custom-popup">
      <div className="content popup-cont">
        <h3>Are you sure you want to approve?</h3>
        <button
          className="edit-Btn"
          onClick={onApprove}
          type="button"
        >
          Approve
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup> */}
<Popup
  trigger={<button className="delete-Btn" type="button"> Reject </button>}
  modal
  nested
>
  {close => (
    <div className="modal custom-popup">
      <div className="content popup-cont">
        <h3>Are you sure you want to approve?</h3>
        <button
          className="delete-Btn"
          onClick={() => {
            onReject()
            close()
          }}
          type="button"
        >
          Reject
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup>

                {/* <button onClick={onApprove} type="button" className="fetchBtn">Approve</button> */}
                {/* <button type="button" className="closeBtn">Reject</button> */}
            </td>)}
        </tr>
    )
}
export default KYCItem