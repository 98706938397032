import React, { Component } from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import StatsItem from '../StatsItem';
import Popup from 'reactjs-popup';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from "react-icons/fa";
import Graph from '../StatsComponents/Graph';
import Charts from '../StatsComponents/Charts';
import Maps from "../StatsComponents/Maps";
import './index.css';
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"

import Chart  from 'chart.js';

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Table',
      statsList: [],
      date: '',
      CampNo: '',
      CampName: '',
      THV: '',
      TPO: '',
      TWC: '',
      TSS: '',
      TYCS: '',
      TNRB: '',
      TNS: '',
      TCD: ''
    };
  }

  componentDidMount() {
    const stat = localStorage.getItem('statsList');
    if (stat) this.setState({ statsList: JSON.parse(stat) });
  }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmitStatsForm = (event) => {
    event.preventDefault();
    const {
      statsList,
      date,
      CampNo,
      CampName,
      THV,
      TPO,
      TWC,
      TSS,
      TYCS,
      TNRB,
      TNS,
      TCD
    } = this.state;
    const newObj = {
      id: uuidv4(),
      date,
      CampNo,
      CampName,
      THV,
      TPO,
      TWC,
      TSS,
      TYCS,
      TNRB,
      TNS,
      TCD
    };
    const newStatList = [...statsList, newObj];
    localStorage.setItem('statsList', JSON.stringify(newStatList));
    this.setState({ statsList: newStatList });
  };

  render() {
    const { statsList } = this.state;
    const { activeTab } = this.state;
    return (
      <>
      <Navbar/>
      <div className='app-main-container'>
        <div className='app-sidebar-container'>
          <Dashboard/>
        </div>
        <div className='app-content-container'>
      <div className="bottom-stats-container">
        <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Stats</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <Popup trigger={<button className="addBtn"> Add <FaPlus />
              </button>} modal nested>
                {(close) => (
                  <div className="modal rcyt-custom-popup">
                    <div className="content rcyt-popup-cont">
                      <form className="stats-form" onSubmit={this.onSubmitStatsForm}>
                        <h1>Enter Stats</h1>
                        <div className="stats-inp-cont">
                          <label htmlFor="date">Date</label>
                          <br />
                          <input
                            name="date"
                            onChange={this.handleChange}
                            className="stats-inp-ele"
                            id="date"
                            type="date"
                            alt="date"
                          />
                        </div>
                        <div className="stats-inp-cont">
                                <label  htmlFor="campNo">Camp Number</label>
                                <br/>
                                <input id="campNo" name="CampNo" onChange={this.handleChange} className="stats-inp-ele" type="number" placeholder="Enter the Camp Number " alt="campNo"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="campName">Camp Name</label>
                                <br/>
                                <input className="stats-inp-ele" name="CampName" onChange={this.handleChange} id="campName" type="text" placeholder="Enter the Camp Name" alt="campName"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="THV">THV</label>
                                <br/>
                                <input name="THV" onChange={this.handleChange} className="stats-inp-ele" id="THV" type="text" placeholder="Enter the THV" alt="THV"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TPO">TPO</label>
                                <br/>
                                <input name="TPO" onChange={this.handleChange} className="stats-inp-ele" id="TPO" type="text" placeholder="Enter the TPO" alt="TPO"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TWC">TWC</label>
                                <br/>
                                <input name="TWC" onChange={this.handleChange} className="stats-inp-ele" id="TWC" type="text" placeholder="Enter the TWC" alt="TWC"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TWC">TSS</label>
                                <br/>
                                <input name="TSS" onChange={this.handleChange} className="stats-inp-ele" id="TSS" type="text" placeholder="Enter the TSS" alt="TSS"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TYCS">TYCS</label>
                                <br/>
                                <input name="TYCS" onChange={this.handleChange} className="stats-inp-ele" id="TYCS" type="text" placeholder="Enter the TYCS" alt="TYCS"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TNRB">TNRB</label>
                                <br/>
                                <input name="TNRB" onChange={this.handleChange} className="stats-inp-ele" id="TNRB" type="text" placeholder="Enter the TNRB" alt="TNRB"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TNS">TNS</label>
                                <br/>
                                <input name="TNS" onChange={this.handleChange} className="stats-inp-ele" id="TNS" type="text" placeholder="Enter the TNS" alt="TNS"/>
                                </div>
                                <div className="stats-inp-cont">
                                <label htmlFor="TCD">TCD</label>
                                <br/>
                                <input name="TCD" onChange={this.handleChange} className="stats-inp-ele" id="TCD" type="text" placeholder="Enter the TCD" alt="TCD"/>
                                </div>
                        <div className="actions">
                          <button className="button closeBtn" onClick={close}>
                            Cancel
                          </button>
                          <button className="fetchBtn" type="submit">
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </Popup>
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-stats-container">
          {activeTab === 'Table' && (
            <div style={{marginTop:'0px'}} className="ytpa-main-inner-container">
              <div className="ytpa-table-container">
                <table className='ytpa-content-table'>
                
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Camp Number</th>
                      <th>Camp Name</th>
                      <th>THV</th>
                      <th>TPO</th>
                      <th>TWC</th>
                      <th>TSS</th>
                      <th>TYCS</th>
                      <th>TNRB</th>
                      <th>TNS</th>
                      <th>TCD</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  {/* Render StatsItem components based on statsList */}
                  <tbody>
                    {statsList.map((ele) => (
                      <StatsItem key={ele.id} statDetails={ele} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === 'Graph' && (
            <div className="ytpa-main-inner-container">
              <Graph />
            </div>
          )}
          {activeTab === 'Charts' && (
  <div className="ytpa-main-inner-container">
    <Charts />
  </div>
)}

{activeTab === 'Maps' && (
  <div className="ytpa-main-inner-container">
    <Maps />
  </div>
)}
        </div>
      </div>
      </div>
      </div> 
      </>
    );
  }
}

export default Stats;