import "./index.css"
import {Component} from 'react'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom"
import {ThreeDots} from 'react-loader-spinner'
import KYCItem from "../KYCItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter



class KYC extends Component {

    state = {regusersList:[],isLoading:false}


    componentDidMount()
    {
        this.getUsers()
    }

    onClickReject = async (value) => {
        const options = {
            method : "PUT",
            headers : {
            "Content-Type" : "application/json"
            },
            body : JSON.stringify({newemail:value,newkycstatus:"rejected"})
        }
        // https://js-member-backend.vercel.app
        const response = await fetch(`https://js-member-backend.vercel.app/updatekycstatus`,options)
        const data = await response.json()
        console.log(data);
        window.location.reload()
    }

    onClickApprove = async (value) => {
        console.log("I am from approve")
        const options = {
            method : "PUT",
            headers : {
            "Content-Type" : "application/json"
            },
            body : JSON.stringify({newemail:value,newkycstatus:"approved"})
        }
        // https://js-member-backend.vercel.app
        const response = await fetch(`https://js-member-backend.vercel.app/updatekycstatus`,options)
        const data = await response.json()
        console.log(data);
        window.location.reload()
    }

    getUsers = async () => {
        this.setState({ isLoading: true });
        try {
            const response = await fetch("https://js-member-backend.vercel.app/getkyclist");
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            // console.log(data.details);
            const newList = (data.details).map((values) => ({
                name : values.name,
                email : values.email,
                bank : values.bankName,
                accountNo : values.accountNumber,
                ifscCode : values.ifscCode,
                city : values.city,
                aadharNo : values.aadharNumber,
                aadharPhoto : values.aadharPhoto,
                kycstatus:values.kycstatus
            }))
            this.setState({ regusersList: newList, isLoading: false });
        } catch (error) {
            console.error("Error fetching data:", error);
            this.setState({ isLoading: false });
        }
    }
    

    // getUsers = async () => {
    //     this.setState({isLoading:true})
    //     const response = await fetch("https://js-member-backend.vercel.app/users")
    //     if(response.ok){
    //     const data = await response.json()
    //     let newList;
    //     const {regusersList} = this.state
    //     for(let values of data)
    //     {
    //         const {name,email,state,district,constituency,channelUrl,whatsappNumber,regstatus} = values
    //         const newObj = {
    //             username:name,
    //             email,
    //             state,
    //             district,
    //             constituency,
    //             channelUrl,
    //             whatsappNumber,
    //             regstatus,
    //             id : values._id
    //         }
    //         console.log(newObj)
    //         newList = [...regusersList,newObj]
    //     }
    //     return newList
    //     }
    // }

    onClickLogout = () => {
        Cookies.remove("jwt_token")
        this.setState({userinput:''})
    }
    
    onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }

    render(){
        const {regusersList,isLoading} = this.state
        // console.log(process.env.API_KEY)
        const token = Cookies.get("jwt_token")
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
        <>
        <Navbar/>
        <div className="app-main-container">
            <div className="app-sidebar-container">
                <Dashboard/>
            </div>
            <div className="app-content-container">
            <div className="ytpa-main-container">
                <div className="ytpa-top-container">
                    <div className="ytpa-inner-top-container">
                    <h1>KYC</h1>
                    {/* <div> */}
                    {/* <button className="addBtn" type="button">Add</button> */}
                    {/* <button className="last24Btn" onClick={this.onClickLast24Btn}>Last 24Hrs Button</button> */}
                    {/* </div> */}
                    <div >
                    <p>{(new Date()).toDateString()}</p>
                    <p>Beta Testing</p>
                    </div>
                    </div>
                </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                                )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={this.onSubmitUrl}>
                        <input value={userinput} onChange={this.onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                <table className="ytpa-content-table">
                <thead>
                <tr>
                    <th>
                        sno
                    </th>
                    <th>
                        Name
                    </th>
                    <th>
                        Email
                    </th>
                    <th>
                        Bank 
                    </th>
                    <th>
                        Account No
                    </th>
                    <th>
                        IFSC Code
                    </th>
                    <th>
                        City
                    </th>
                    <th>
                        Aadhar No
                    </th>
                    <th>
                        Aadhaar Photo
                    </th>
                    <th>
                        Accept/Reject
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (regusersList.length!==0) && (
                        regusersList.map((ele,index) => <KYCItem sno={index+1} key={index} itemDetails={ele} onClickApprove={this.onClickApprove} onClickReject={this.onClickReject}/>)
                    )
                }
                </tbody>
                {/* {(itemsList.length!==0) && (
                        itemsList.map((ele,index) => <YoutubeItem sno={index+1} key={ele.id} itemDetails={ele} onClickDelete={this.onClickDelete}/>
                        ))} */}
                <tfoot >
                    {/* <tr>
                        <th  colSpan="3">Total</th>
                        <th >{views}</th>
                        <th >{likes}</th>
                        <th >{comments}</th>
                        <th >{rewards}</th>
                    </tr> */}
                </tfoot>
                </table>
            </div>)}
            </div>
            </div>
            </div>
            </div>
            </>
        )}        
}

export default KYC