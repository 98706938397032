import "./index.css"
import Popup from 'reactjs-popup'
const YoutubeItem = (props) => {
    const {itemDetails,sno,onClickDelete} = props
    // console.log(itemDetails)
    const {id,
        linkUrl,
        title,
        yturl,
        channelViewCount,
        viewCount,
        likeCount,
        channelTitle,
        rewardPoints,
        publishedAt,
        VideoDays,
        videoCount,
        subscriberCount,
        commentCount} = itemDetails
        // console.log(rewardPoints);
        const comments = commentCount?commentCount:0;
        const likes = likeCount?likeCount:0;
        const views = viewCount?viewCount:0;
        const onDelete = () => {
            onClickDelete(id);
        }
        // console.log(linkUrl)
        const linkU = `https://www.youtube.com/watch?v=${linkUrl}`;
        let l="false";
        if(linkUrl===undefined)
        l = "true"
    return (
  <tr data-invalid-date={l} >
    <td className="ytpa-table-cell">{sno}</td>
    <td className="ytpa-table-cell">{title}</td>
    <td className="ytpa-table-cell">{channelTitle}</td>
    <td className="ytpa-table-cell">{views}</td>
    <td className="ytpa-table-cell">{likes}</td>
    <td className="ytpa-table-cell">{comments}</td>
    <td className="ytpa-table-cell">{rewardPoints}</td>
    <td className="ytpa-table-cell">{new Date(publishedAt).toLocaleString()}</td>
    <td className="ytpa-table-cell">{VideoDays}</td>
    <td className="ytpa-table-cell">{videoCount}</td>
    <td className="ytpa-table-cell">{channelViewCount}</td>
    <td className="ytpa-table-cell">{subscriberCount}</td>
    <td className="ytpa-table-cell"><a rel="noreferrer" href={linkU} target="_blank">Link</a></td>
    {linkUrl !== undefined ? (
      <td className="ytpa-table-cell"><a rel="noreferrer" href={linkU} target="_blank">{linkU}</a></td>
    ) : (
      <td className="ytpa-table-cell"><a rel="noreferrer" href={linkU} target="_blank">{yturl}</a></td>
    )}
    <td className="ytpa-table-cell">
      <Popup
        trigger={<button className="ytpa-deleteBtn" type="button">Delete</button>}
        modal
        nested
      >
        {close => (
          <div className="ytpa-modal ytpa-custom-popup">
            <div className="ytpa-content ytpa-popup-cont">
              <h1>Confirm Delete</h1>
              <button className="ytpa-deleteBtn" type="button" onClick={onDelete}>Delete</button>
            </div>
            <div className="ytpa-actions">
              <button
                className="ytpa-button ytpa-closeBtn"
                onClick={() => {
                  console.log('modal closed ');
                  close();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Popup>
    </td>
  </tr>

    )
}

export default YoutubeItem