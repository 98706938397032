import Popup from 'reactjs-popup'
import { useNavigate } from "react-router-dom"
const ReferralItem = (props) => {
    const {userDetails,sno,onDeleteUser} = props
    const {
        id,
        Name,Mobileno,Email,ReferralCode
    } = userDetails
    // const dateObj = new Date(date)
    // const day = dateObj.getDate().toString().padStart(2, '0');
    // const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-indexed, so we add 1
    // const year = dateObj.getFullYear().toString();

    // const formattedDate = `${day}/${month}/${year}`;
    const onRemoveUser = () => {
        onDeleteUser(id)
    }

    return (
            <tr>
            <td>{sno}</td>
                <td>{Name}</td>
                <td>{Mobileno}</td>
                <td>{Email}</td>
                <td>{ReferralCode}</td>
                <td>
            <Popup
                trigger={<button className="delete-Btn" type="button"> Delete </button>}
                modal
                nested
            >
                {close => (
                <div className="modal rcyt-custom-popup">
                    {/* <button className="close " onClick={close}>
                    &times;
                    </button> */}
                    <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
                        <h1>Confirm Delete</h1>
                        <button className="delete-Btn" type="button" onClick={() => {
                            onRemoveUser()
                            close()
                        }}>Delete</button>
                    </div>
                    <div className="actions">
                    <button
                        className="button closeBtn"
                        onClick={() => {
                        console.log('modal closed ');
                        close();
                        }}
                    >
                        Cancel
                    </button>
                    </div>
                </div>
                )}
            </Popup>
                </td>
            </tr>
    )
}
export default ReferralItem