// About.js

import React from 'react';
import './index.css';
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';

const About = () => {
  return (
    <>
    <Navbar/>
    <div className="app-main-container">
      <div className='app-sidebar-container'>
        <Dashboard/>
        </div>
        <div className='app-content-container'>
    <div className="about-container">
      <h1 className="heading">JS Dashboard</h1>
      <p className="paragraph">
        JS Dashboard is dedicated to transforming the Jan Suraaj movement in Bihar through technology.
        Our mission is to empower the movement by providing real-time insights and analytics for informed decision-making.
        We strive to optimize resource utilization and drive impactful change for a transparent and accountable governance system.
      </p>
    </div>
    </div> 
    </div>
    </>
  );
}

export default About;
