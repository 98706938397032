import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import "./index.css";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FcManager } from "react-icons/fc";
import { MdHelpCenter } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { HiMiniUserGroup } from "react-icons/hi2";
import { FaHome } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";

const Dashboard = ({ studentDetails, updateStudentDetails }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropDown = (option) => {
    setSelectedOption(selectedOption === option ? null : option);
  }

  const isOptionOpen = (option) => selectedOption === option;

  return (
    <div className='left-section-container'>
      {/* <div className='student-card'>
        <h1 className='jsnavdashboard-heading'>JS Dashboard</h1>
      </div> */}

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('home')}>
          <div className='two-options'>
            <FaHome className='option-logo' />
            <p className='left-options-text'>Home</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('home') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('home') && (
          <div className="dropdown-content">
            <Link to="/about" className='sidebar-options'>About</Link>
            <Link to="/vision" className='sidebar-options'>Vision</Link>
            <Link to="/mission" className='sidebar-options'>Mission</Link>
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('youtube')}>
          <div className='two-options'>
            <FaYoutube className='option-logo' />
            <p className='left-options-text'>Youtube</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('youtube') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('youtube') && (
          <div className="dropdown-content">
            <Link to="/youtuber" className='sidebar-options'>Youtuber</Link>
            <Link to="/register" className="sidebar-options">Register YTCM</Link>
            <Link to="/registrations" className="sidebar-options">Registrations</Link>
            <Link to="/ytcmstats" className='sidebar-options'>YTCM Stats</Link>
            <Link to="/reports" className='sidebar-options'>Reports</Link>
            <Link to="/reward" className='sidebar-options'>Rewards</Link>
            <Link to="/content" className='sidebar-options'>Content</Link>
            <Link to="/trending" className='sidebar-options'>Trending</Link>
            <Link to="/message" className="sidebar-options">Message</Link>
            <Link to="/" className="sidebar-options">Profile</Link>
            <Link to="/referral" className="sidebar-options">Referral</Link>
            <Link to="/tickets" className='sidebar-options'>Tickets</Link>
            <Link to="/kyc" className="sidebar-options">KYC</Link>
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('camps')}>
          <div className='two-options'>
            <HiMiniUserGroup className='option-logo' />
            <p className='left-options-text'>Camp Admin</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('camps') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('camps') && (
          <div className="dropdown-content">
            <Link to="/stats" className='sidebar-options'>Stats</Link>
            {/* <Link to="/members" className='sidebar-options'>Members</Link> */}
            <Link to="/campregistrations" className='sidebar-options'>Camp Registrations</Link>
            <Link to="/regcampmembersdetails" className='sidebar-options'>Registered Members</Link>
            <Link to="/campdetailsubadmin" className='sidebar-options'>Sub Admins</Link>
            <Link to="/campdetailteams" className='sidebar-options'>Teams</Link>
            <Link to="/campdetailattendance" className='sidebar-options'>Attendance</Link>
            <Link to="/campdetaild2dincharge" className='sidebar-options'>D2D Incharge</Link>
            <Link to="/campdetailactivity" className='sidebar-options'>Activity</Link>
            <Link to="/campdetailcollaterals" className='sidebar-options'>Collaterals</Link>
            <Link to="/campdetailexpenses" className='sidebar-options'>Expenses</Link>
          
            {/* <Link to="/d2d" className='sidebar-options'>D2D</Link>
            <Link to="/collaterals" className='sidebar-options'>Collaterals</Link>
            <Link to="/transport" className='sidebar-options'>Transport</Link>
            <Link to="/kitchen" className='sidebar-options'>Kitchen</Link>
            <Link to="/request" className='sidebar-options'>Request</Link>
            <Link to="/management" className='sidebar-options'>Management</Link>
            <Link to="/profile" className='sidebar-options'>Profile</Link> */}
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('campreport')}>
          <div className='two-options'>
            <TbReportAnalytics className='option-logo' />
            <p className='left-options-text'>Camp Report</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('campreport') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('campreport') && (
          <div className="dropdown-content">
            <Link to="/campdetailattendanceselfie" className='sidebar-options'>Attendance Selfie</Link>
            <Link to="/campdetailhousehold" className='sidebar-options'>Household (Selfie)</Link>
            {/* <Link to="/members" className='sidebar-options'>Members</Link> */}
            <Link to="/campdetaild2dreport" className='sidebar-options'>D2D Report</Link>
            <Link to="/campdetailss" className='sidebar-options'>Sansthapak Sadasya</Link>
            <Link to="/campdetaildigitalinfluencer" className='sidebar-options'>Digital Influencer</Link>
            <Link to="/campdetailcoaching" className='sidebar-options'>Coaching</Link>
            <Link to="/campdetailssvitran" className='sidebar-options'>SS Vitran</Link>
            <Link to="/campdetailwhatsapp" className='sidebar-options'>WhatsApp</Link>
            <Link to="/campdetailkyc" className='sidebar-options'>KYC</Link>
          
            {/* <Link to="/d2d" className='sidebar-options'>D2D</Link>
            <Link to="/collaterals" className='sidebar-options'>Collaterals</Link>
            <Link to="/transport" className='sidebar-options'>Transport</Link>
            <Link to="/kitchen" className='sidebar-options'>Kitchen</Link>
            <Link to="/request" className='sidebar-options'>Request</Link>
            <Link to="/management" className='sidebar-options'>Management</Link>
            <Link to="/profile" className='sidebar-options'>Profile</Link> */}
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('help')}>
          <div className='two-options'>
            <MdHelpCenter className='option-logo' />
            <p className='left-options-text'>Help</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('help') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('help') && (
          <div className="dropdown-content">
            <Link to="/faqs" className='sidebar-options'>FAQ's</Link>
            <Link to="/trainings" className='sidebar-options'>Trainings</Link>
            <Link to="/feedback" className='sidebar-options'>Feedback</Link>
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('support')}>
          <div className='two-options'>
            <FaThumbsUp className='option-logo' />
            <p className='left-options-text'>Support</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('support') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('support') && (
          <div className="dropdown-content">
            <Link to="/contact" className='sidebar-options'>Contact</Link>
            <Link to="/request" className='sidebar-options'>Ticket</Link>
            <Link to="/report" className='sidebar-options'>Chat</Link>
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('settings')}>
          <div className='two-options'>
            <IoSettingsSharp className='option-logo' />
            <p className='left-options-text'>Settings</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('settings') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('settings') && (
          <div className="dropdown-content">
            <Link to="/edit" className='sidebar-options'>Profile</Link>
            <Link to="/settings" className='sidebar-options'>Account</Link>
          </div>
        )}
      </div>

      <div className='left-options'>
        <div className='left-options-container' onClick={() => toggleDropDown('admin')}>
          <div className='two-options'>
            <FcManager className='option-logo' />
            <p className='left-options-text'>Admin</p>
          </div>
          <button className='down-button'>
            {isOptionOpen('admin') ? <RiArrowDropUpLine className='down-symbol' /> : <RiArrowDropDownLine className='down-symbol' />}
          </button>
        </div>
        {isOptionOpen('admin') && (
          <div className="dropdown-content">
            <Link to="/edit" className='sidebar-options'>Account</Link>
            <Link to="/edit" className='sidebar-options'>Sub Admins</Link>
            <Link to="/edit" className='sidebar-options'>Logs</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// import "./index.css";
// import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
// import { FcManager } from "react-icons/fc";
// import { MdHelpCenter } from "react-icons/md";
// import { FaThumbsUp } from "react-icons/fa";
// import { IoSettingsSharp } from "react-icons/io5";
// import { FaYoutube } from "react-icons/fa";
// import { HiMiniUserGroup } from "react-icons/hi2";
// import { FaHome } from "react-icons/fa";



// const Dashboard = ({ studentDetails, updateStudentDetails }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);

//   const toggleDropDown = (option) => {
//     if (selectedOption === option) {
//       setSelectedOption(null);
//     } else {
//       setSelectedOption(option);
//     }
//     setIsOpen(!isOpen);
//   }

//   return (
//     <div className='left-section-container'>
//       <div className='student-card'>
//       <h1 className='jsnavdashboard-heading'>JS Dashboard</h1>
//         </div>

    
//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <FaHome className='option-logo'/>
//               <p className='left-options-text'>Home</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('home')}>
//               {isOpen && selectedOption === 'home' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'home' && (
//               <div className="dropdown-content">
//                 <Link to="/about" className='sidebar-options'>About</Link>
//                 <Link to="/vision" className='sidebar-options'>Vision</Link>
//                 <Link to="/mission" className='sidebar-options'>Mission</Link>
//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <FaYoutube className='option-logo'/>
//               <p className='left-options-text'>Youtube</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('users')}>
//               {isOpen && selectedOption === 'users' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'users' && (
//               <div className="dropdown-content">
//               <Link to="/" className='sidebar-options'>Youtuber</Link>
//               <Link to="/register" className="sidebar-options">Register YTCM</Link>
//               <Link to="/registrations" className="sidebar-options">Registrations</Link>
//               <Link to="/ytcmstats" className='sidebar-options'>YTCM Stats</Link>

//               <Link to="/reports" className='sidebar-options'>Reports</Link>
//               <Link to="/rewards" className='sidebar-options'>Rewards</Link>
//               <Link to="/content" className='sidebar-options'>Content</Link>
//               <Link to="/trending" className='sidebar-options'>Trending</Link>
//               <Link to="/message" className="sidebar-options">Message</Link>
//               <Link to="/" className="sidebar-options">Profile</Link>
//               <Link to="/referral" className="sidebar-options">Referral</Link>
//               <Link to="/tickets" className='sidebar-options'>Tickets</Link>
//               <Link to="/kyc" className="sidebar-options">KYC</Link>

//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <HiMiniUserGroup className='option-logo'/>
//               <p className='left-options-text'>Camps</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('staff')}>
//               {isOpen && selectedOption === 'staff' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'staff' && (
//               <div className="dropdown-content">
//                <Link to="/stats" className='sidebar-options'>Stats</Link>
//               <Link to="/members" className='sidebar-options'>Members</Link>
//               <Link to="/attendance" className='sidebar-options'>Attendance</Link>
//               <Link to="/d2d" className='sidebar-options'>D2D</Link>
//               <Link to="/collaterals" className='sidebar-options'>Collaterals</Link>
//               <Link to="/transport" className='sidebar-options'>Transport</Link>
//               <Link to="/kitchen" className='sidebar-options'>Kitchen</Link>
//               <Link to="/request" className='sidebar-options'>Request</Link>
//               <Link to="/management" className='sidebar-options'>Management</Link>
//               <Link to="/profile" className='sidebar-options'>Profile</Link>
//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <MdHelpCenter className='option-logo'/>
//               <p className='left-options-text'>Help</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('help')}>
//               {isOpen && selectedOption === 'help' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'help' && (
//               <div className="dropdown-content">
//                 <Link to="/faqs" className='sidebar-options'>FAQ's</Link>
//                 <Link to="/trainings" className='sidebar-options'>Trainings</Link>
//                 <Link to="/feedback" className='sidebar-options'>Feedback</Link>
//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <FaThumbsUp className='option-logo'/>
//               <p className='left-options-text'>Support</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('support')}>
//               {isOpen && selectedOption === 'support' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'support' && (
//               <div className="dropdown-content">
//                   <Link to="/contact" className='sidebar-options'>Contact</Link>
//                   <Link to="/request" className='sidebar-options'>Ticket</Link>
//                   <Link to="/report" className='sidebar-options'>Chat</Link>
//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <IoSettingsSharp className='option-logo'/>
//               <p className='left-options-text'>Settings</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('settings')}>
//               {isOpen && selectedOption === 'settings' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'settings' && (
//               <div className="dropdown-content">
//                 <Link to = "/edit" className='sidebar-options'>Profile</Link>
//                 <Link to = "/settings" className='sidebar-options'>Account</Link>
//               </div>
//             )}
//         </div>

//         <div className='left-options'>
//           <div className='left-options-container'>
//             <div className='two-options'>
//               <FcManager className='option-logo'/>
//               <p className='left-options-text'>Admin</p>
//             </div>
//             <button className='down-button' onClick={() => toggleDropDown('admin')}>
//               {isOpen && selectedOption === 'admin' ? <RiArrowDropUpLine className='down-symbol'/> : <RiArrowDropDownLine className='down-symbol'/>}
//             </button>
//             </div>
//             {isOpen && selectedOption === 'admin' && (
//               <div className="dropdown-content">
//                   <Link to = "/edit" className='sidebar-options'>Account</Link>
//                   <Link to = "/edit" className='sidebar-options'>Sub Admins</Link>
//                   <Link to = "/edit" className='sidebar-options'>Logs</Link>
//               </div>
//             )}
//         </div>


//     </div>
//   );
// };

// export default Dashboard;
