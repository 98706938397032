import React from 'react'
import { FaSearch } from "react-icons/fa";
import "./index.css"

const onLogOut = () => {

}

const Navbar = () => {
  return (
<div className='top-nav'>
<div className='logo-container'>
    <img src = "https://res.cloudinary.com/dylh46szw/image/upload/v1711793425/favicon2_pef2lb.jpg" alt = "jan-logos" className='jan-logo'/>
    <h3>JS Dashboard</h3>
    </div>
    <div style={{display:'flex',alignItems:'center'}}>
      <p>jsdash</p>
      <img src="https://res.cloudinary.com/dylh46szw/image/upload/v1717177294/image-removebg-preview_xwrm60.png" style={{height:'50px',width:'50px',paddingBottom:'2px'}}/>
    </div>

  </div>
  )
}

export default Navbar