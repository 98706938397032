import React from 'react';
import './index.css'; // Importing CSS file for styling
import Dashboard from '../../Dashboard';
import Navbar from '../../Navbar';

function Vision() {
  return (
    <>
    <Navbar/>
    <div className="app-main-container">
      <div className='app-sidebar-container'>
        <Dashboard/>
        </div>
        <div className='app-content-container'>
    <div className="vision-container">
      <h1 className="vision-heading">Vision</h1>
      <p className="vision-content">Harnessing Technology for a Transformed Jan Suraaj Movement</p>
    </div>
    </div>
    </div>
    </>
  );
}

export default Vision;
