import "./index.css"
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import CampRegistrationItem from "../CampRegistrationItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import CampDetailItem from "../CampDetailItem"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

const constituencies = {
    "SELECT" : ['SELECT'],
    "PASCHIM CHAMPARAN": [
      "Valmiki Nagar",
      "Ramnagar (SC)",
      "Narkatiaganj",
      "Bagaha",
      "Lauriya",
      "Nautan",
      "Chanpatia",
      "Bettiah",
      "Sikta"
    ],
    "PURVI CHAMPARAN": [
      "Raxaul",
      "Sugauli",
      "Narkatia",
      "Harsidhi (SC)",
      "Govindganj",
      "Kesaria",
      "Kalyanpur",
      "Pipra",
      "Madhuban",
      "Motihari",
      "Chiraia",
      "Dhaka"
    ],
    "SHEOHAR": ["Sheohar"],
    "SITAMARHI": [
      "Riga",
      "Bathnaha (SC)",
      "Parihar",
      "Sursand",
      "Bajpatti",
      "Sitamarhi",
      "Runnisaidpur",
      "Belsand"
    ],
    "MADHUBANI": [
      "Harlakhi",
      "Benipatti",
      "Khajauli",
      "Babubarhi",
      "Bisfi",
      "Madhubani",
      "Rajnagar (SC)",
      "Jhanjharpur",
      "Phulparas",
      "Laukaha"
    ],
    "SUPAUL": [
      "Nirmali",
      "Pipra",
      "Supaul",
      "Triveniganj (SC)",
      "Chhatapur"
    ],
    "ARARIA": [
      "Narpatganj",
      "Raniganj (SC)",
      "Forbesganj",
      "Araria",
      "Jokihat",
      "Sikti"
    ],
    "KISHANGANJ": [
      "Bahadurganj",
      "Thakurganj",
      "Kishanganj",
      "Kochadhaman"
    ],
    "PURNIA": [
      "Amour",
      "Baisi",
      "Kasba",
      "Banmankhi (SC)",
      "Rupauli",
      "Dhamdaha",
      "Purnia"
    ],
    "KATIHAR": [
      "Katihar",
      "Kadwa",
      "Balrampur",
      "Pranpur",
      "Manihari (ST)",
      "Barari",
      "Korha (SC)"
    ],
    "MADHEPURA": [
      "Alamnagar",
      "Bihariganj",
      "Singheshwar (SC)",
      "Madhepura"
    ],
    "SAHARSA": [
      "Sonbarsha (SC)",
      "Saharsa",
      "Simri Bakhtiarpur",
      "Mahishi"
    ],
    "DARBHANGA": [
      "Kusheshwar Asthan (SC)",
      "Gaura Bauram",
      "Benipur",
      "Alinagar",
      "Darbhanga Rural",
      "Darbhanga",
      "Hayaghat",
      "Bahadurpur",
      "Keoti",
      "Jale"
    ],
    "MUZAFFARPUR": [
      "Gaighat",
      "Aurai",
      "Minapur",
      "Bochaha (SC)",
      "Sakra (SC)",
      "Kurhani",
      "Muzaffarpur",
      "Kanti",
      "Baruraj",
      "Paroo",
      "Sahebganj"
    ],
    "GOPALGANJ": [
      "Baikunthpur",
      "Barauli",
      "Gopalganj",
      "Kuchaikote",
      "Bhorey (SC)",
      "Hathua"
    ],
    "SIWAN": [
      "Siwan",
      "Ziradei",
      "Darauli (SC)",
      "Raghunathpur",
      "Daraundha",
      "Barharia",
      "Goriakothi",
      "Maharajganj"
    ],
    "SARAN": [
      "Ekma",
      "Manjhi",
      "Baniapur",
      "Taraiya",
      "Marhaura",
      "Chapra",
      "Garkha (SC)",
      "Amnour",
      "Parsa",
      "Sonepur"
    ],
    "VAISHALI": [
      "Hajipur",
      "Lalganj",
      "Vaishali",
      "Mahua",
      "Raja Pakar (SC)",
      "Raghopur",
      "Mahnar",
      "Patepur (SC)"
    ],
    "SAMASTIPUR": [
      "Kalyanpur (SC)",
      "Warisnagar",
      "Samastipur",
      "Ujiarpur",
      "Morwa",
      "Sarairanjan",
      "Mohiuddinnagar",
      "Bibhutipur",
      "Rosera (SC)",
      "Hasanpur"
    ],
    "BEGUSARAI": [
      "Cheria Bariarpur",
      "Bachhwara",
      "Teghra",
      "Matihani",
      "Sahebpur Kamal",
      "Begusarai",
      "Bakhri (SC)"
    ],
    "KHAGARIA": [
      "Alauli (SC)",
      "Khagaria",
      "Beldaur",
      "Parbatta"
    ],
    "BHAGALPUR": [
      "Bihpur",
      "Gopalpur",
      "Pirpainti (SC)",
      "Kahalgaon",
      "Bhagalpur",
      "Sultanganj",
      "Nathnagar"
    ],
    "BANKA": [
      "Amarpur",
      "Dhauraiya (SC)",
      "Banka",
      "Katoria (ST)",
      "Belhar"
    ],
    "MUNGER": [
      "Tarapur",
      "Munger",
      "Jamalpur"
    ],
    "LAKHISARAI": [
      "Suryagarha",
      "Lakhisarai"
    ],
    "SHEIKHPURA": [
      "Sheikhpura",
      "Barbigha"
    ],
    "NALANDA": [
      "Asthawan",
      "Biharsharif",
      "Rajgir (SC)",
      "Islampur",
      "Hilsa",
      "Nalanda",
      "Harnaut"
    ],
    "PATNA": [
        "Mokama",
        "Barh",
        "Bakhtiarpur",
        "Digha",
        "Bankipur",
        "Kumhrar",
        "Patna Sahib",
        "Fatuha",
        "Danapur",
        "Maner",
        "Phulwari (SC)",
        "Masaurhi (SC)",
        "Paliganj",
        "Bikram"
      ],
      "BHOJPUR": [
        "Sandesh",
        "Barhara",
        "Arrah",
        "Agiaon (SC)",
        "Tarari",
        "Jagdishpur",
        "Shahpur"
      ],
      "BUXAR": [
        "Brahampur",
        "Buxar",
        "Dumraon",
        "Rajpur (SC)"
      ],
      "KAIMUR (BHABHUA)": [
        "Ramgarh",
        "Mohania (SC)",
        "Bhabua",
        "Chainpur"
      ],
      "ROHTAS": [
        "Chenari (SC)",
        "Sasaram",
        "Kargahar",
        "Dinara",
        "Nokha",
        "Dehri",
        "Karakat"
      ],
      "ARWAL": [
        "Arwal",
        "Kurtha"
      ],
      "JAHANABAD": [
        "Jehanabad",
        "Ghosi",
        "Makhdumpur (SC)"
      ],
      "AURANGABAD": [
        "Goh",
        "Obra",
        "Nabinagar",
        "Kutumba (SC)",
        "Aurangabad",
        "Rafiganj"
      ],
      "GAYA": [
        "Gurua",
        "Sherghati",
        "Imamganj (SC)",
        "Barachatti (SC)",
        "Bodh Gaya (SC)",
        "Gaya Town",
        "Tikari",
        "Belaganj",
        "Atri",
        "Wazirganj"
      ],
      "NAWADA": [
        "Rajauli (SC)",
        "Hisua",
        "Nawada",
        "Gobindpur",
        "Warsaliganj"
      ],
      "JAMUI": [
        "Sikandra (SC)",
        "Jamui",
        "Jhajha",
        "Chakai"
      ]
    }  

const options = [
        {
          OptionId : "PASCHIM CHAMPARAN"
        },
        {
            OptionId : "PURVI CHAMPARAN"
        },
        {
            OptionId : "SHEOHAR"
        },
        {
            OptionId : "SITAMARHI"
        },
        {
            OptionId : "MADHUBANI"
        },
        {
            OptionId : "SUPAUL"
        },
        {
            OptionId : "ARARIA"
        },
        {
            OptionId : "KISHANGANJ"
        },
        {
            OptionId : "PURNIA"
        },
        {
            OptionId : "KATIHAR"
        },
        {
            OptionId : "MADHEPURA"
        },
        {
            OptionId : "SAHARSA"
        },
        {
            OptionId : "DARBHANGA"
        },
        {
            OptionId : "MUZAFFARPUR"
        },
        {
            OptionId : "GOPALGANJ"
        },
        {
            OptionId : "SIWAN"
        },
        {
            OptionId : "SARAN"
        },
        {
            OptionId : "VAISHALI"
        },
        {
            OptionId : "SAMASTIPUR"
        },
        {
            OptionId : "BEGUSARAI"
        },
        {
            OptionId : "KHAGARIA"
        },
        {
            OptionId : "BHAGALPUR"
        },
        {
            OptionId : "BANKA"
        },
        {
            OptionId : "MUNGER"
        },
        {
            OptionId : "LAKHISARAI"
        },
        {
            OptionId : "SHEIKHPURA"
        },
        {
            OptionId : "NALANDA"
        },
        {
            OptionId : "PATNA"
        },
        {
            OptionId : "BHOJPUR"
        },
        {
            OptionId : "BUXAR"
        },
        {
            OptionId : "KAIMUR (BHABHUA)"
        },
        {
            OptionId : "ROHTAS"
        },
        {
            OptionId : "ARWAL"
        },
        {
            OptionId : "JAHANABAD"
        },
        {
            OptionId : "AURANGABAD"
        },
        {
            OptionId : "GAYA"
        },
        {
            OptionId : "NAWADA"
        },
        {
            OptionId : "JAMUI"
        }
      ]

const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ]

const campNames = [
    "Camp 1",
    "Camp 2",
    "Camp 3",
    "Camp 4",
    "Camp 5",
    "Camp 6",
    "Camp 7",
    "Camp 8",
    "Camp 9",
    "Camp 10",
    "Camp 11",
    "Camp 12"
];

class CampDetails extends Component {

    state = {
        activeTab:'Table',
        usersList:[],
        campCluster: '',
        campName: '',
        campDistricts: [],
        campInchargeName: '',
        campInchargeNumber: '',
        campInchargeGmail: '',
        campLogin: '',
        campPassword: '',
        isLoading:false
    }

    componentDidMount(){
        this.getUsers()
    }

    getUsers = async () => {
      this.setState({ isLoading: true });
      try {
          const [response, response2] = await Promise.all([
              fetch("https://js-member-backend.vercel.app/getcampusers"),
              fetch("https://js-member-backend.vercel.app/campusers")
          ]);
  
          const data = await response.json();
          const data2 = await response2.json();

          console.log(data2)
          const { CampusersList } = data;
  
          const newList = CampusersList.map(values => ({
              id: values.id,
              campCluster: values.campCluster,
              campName: values.campName,
              campDistricts: values.campDistricts,
              campInchargeName: values.campInchargeName,
              campInchargeNumber: values.campInchargeNumber,
              campInchargeGmail: values.campInchargeGmail,
              campLogin: values.campLogin,
              campPassword: values.campPassword,
              date: values.date,
              time: values.time,
              totalMembers: data2.filter((ele) => ele.campCluster === values.campCluster).length // Ensure campId field matches with values.campId
          }));

          console.log(newList)
          this.setState({ usersList: newList, isLoading: false });
      } catch (error) {
          console.error("Error fetching data:", error);
          this.setState({ isLoading: false });
      }
  }
  

    onChangeCampId = (event) => {
        this.setState({ campCluster: event.target.value });
    }

    onChangeCampName = (event) => {
        this.setState({ campName: event.target.value });
    }

    onChangeCampDistricts = (event) => {
        const { value, checked } = event.target;
        let { campDistricts } = this.state;
        
        if (checked) {
            campDistricts = [...campDistricts, value];
        } else {
            campDistricts = campDistricts.filter(district => district !== value);
        }
        
        this.setState({ campDistricts });
    }

    onChangeCampInchargeName = (event) => {
        this.setState({ campInchargeName: event.target.value });
    }

    onChangeCampInchargeNumber = (event) => {
        this.setState({ campInchargeNumber: event.target.value });
    }

    onChangeCampInchargeGmail = (event) => {
        this.setState({ campInchargeGmail: event.target.value });
    }

    onChangeCampLogin = (event) => {
        this.setState({ campLogin: event.target.value });
    }

    onChangeCampPassword = (event) => {
        this.setState({ campPassword: event.target.value });
    }

    deleteData = async (value) => {
      const options = {
        method : "DELETE",
        headers : {
          "Content-Type":"application/json"
        },
        body : JSON.stringify({id:value})
      }
      const response = await fetch(`https://js-member-backend.vercel.app/deletecampadmin`,options);
      const data = response.json()
      console.log(data);
    }


    onDeleteUser = (value) => {
       const {usersList} = this.state
        const filteredDetails = usersList.filter((ele) => ele.id!==value)
        this.deleteData(value)
        this.setState({usersList:filteredDetails})
    }

    postData = async (obj) => {
        try{
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
        }
        const response = await fetch(`https://js-member-backend.vercel.app/addcampuserjsd`,options);
        const data = await response.json()
        console.log(data)
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`)
        }
    }

    onSubmitCamp = (event) => {
        event.preventDefault();
        const date = (new Date()).toLocaleDateString('en-GB');
        const time = (new Date()).toLocaleTimeString();
            const {campCluster,
                campName,
                campDistricts,
                campInchargeName,
                campInchargeNumber,
                campInchargeGmail,
                campLogin,
                campPassword,
            usersList} = this.state
            const newObj = {
                id : uuidv4(),
                campCluster,
                campName,
                campDistricts,
                campInchargeName,
                campInchargeNumber,
                campInchargeGmail,
                campLogin,
                campPassword,
                date,
                time,
                teams:[],
            }
            this.postData(newObj)
            const newList = [newObj,...usersList]
            localStorage.setItem("campusers",JSON.stringify(newList))
            this.setState({usersList:newList})
            console.log(this.state);
    }

    handleTabClick = (tabName) => {
        this.setState({ activeTab: tabName });
      };

    render(){
        const {campCluster,
            campName,
            campDistricts,
            campInchargeName,
            campInchargeNumber,
            campInchargeGmail,
            campLogin,
            campPassword,
        usersList} = this.state
        return (
            <>
            <Navbar/>
            <div className='app-main-container'>
                <div className='app-sidebar-container'>
                <Dashboard />
                </div>
                <div className='app-content-container'>
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Registered Members</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            {/* <Popup
                trigger={<button className="addBtn" type="button"> Add <FaPlus/> </button>}
                modal
                nested
            >
                {close => (
                    <div className="modal rcyt-custom-popup">
                        <div className="content rcyt-popup-cont">
                            <form className="rcyt-form" onSubmit={this.onSubmitCamp}>
                                <h2>Register Camp</h2>
                                <div>
                                    <label htmlFor="campId">Camp Number</label>
                                    <br/>
                                    <input placeholder="Enter the Camp ID" onChange={this.onChangeCampId} className="rcyt-user-input" type="text" id="campId" required value={campId}/>
                                </div>
                                <div>
                                    <label htmlFor="campName">Camp Name</label>
                                    <br />
                                      <input placeholder="Enter the Camp Name" onChange={this.onChangeCampName} className="rcyt-user-input" type="text" id="campName" required value={campName}/>
                                </div>
                                <div>
                                    <label htmlFor="campDistricts">Camp Districts</label>
                                    <br/>
                                    {options.map(option => (
                                        <div className="rcyt-user-input" key={option.OptionId}>
                                            <input type="checkbox" id={option.OptionId} value={option.OptionId} onChange={this.onChangeCampDistricts}/>
                                            <label htmlFor={option.OptionId}>{option.OptionId}</label>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <label htmlFor="campInchargeName">Camp Incharge Name</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Name" onChange={this.onChangeCampInchargeName} className="rcyt-user-input" type="text" id="campInchargeName" required value={campInchargeName}/>
                                </div>
                                <div>
                                    <label htmlFor="campInchargeNumber">Camp Incharge Number</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Number" onChange={this.onChangeCampInchargeNumber} className="rcyt-user-input" type="tel" id="campInchargeNumber" required value={campInchargeNumber} pattern="^\+91[6-9]\d{9}$"/>
                                </div>
                                <div>
                                    <label htmlFor="campInchargeGmail">Camp Incharge Email</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Email" onChange={this.onChangeCampInchargeGmail} className="rcyt-user-input" type="email" id="campInchargeGmail" required value={campInchargeGmail}/>
                                </div>
                                <div>
                                    <label htmlFor="campLogin">Camp Login</label>
                                    <br/>
                                    <input placeholder="Enter the Camp Login" onChange={this.onChangeCampLogin} className="rcyt-user-input" type="text" id="campLogin" required value={campLogin}/>
                                </div>
                                <div>
                                    <label htmlFor="campPassword">Camp Password</label>
                                    <br/>
                                    <input placeholder="Enter the Camp Password" onChange={this.onChangeCampPassword} className="rcyt-user-input" type="password" id="campPassword" required value={campPassword}/>
                                </div>
                                <div className="actions">
                                    <button
                                        className="button closeBtn"
                                        onClick={() => {
                                            console.log('modal closed');
                                            close();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button className="fetchBtn" type="submit">Add Camp</button>
                                </div>
                            </form>
                        </div>                        
                    </div>
                )}
            </Popup> */}

              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                <table className="ytpa-content-table">
                    <thead>
                <tr>
                    <th>
                        Sno
                    </th>
                    <th>
                      Date
                    </th>
                    <th>
                        Camp Cluster
                    </th>
                    <th>
                        Camp Districts
                    </th>
                    <th>
                        Camp Incharge Name
                    </th>
                    <th>
                        Camp Incharge Number
                    </th>
                    <th>
                        Camp Incharge Gmail
                    </th>
                    <th>
                      Total Members
                      </th>
                </tr>
                </thead>
                <tbody>
                {(usersList.length!==0) && (
                        usersList.map((ele,index) => <CampDetailItem sno={index+1} key={ele.id} itemDetails={ele} onDeleteUser={this.onDeleteUser}/>
                ))}
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        )
    }
}

export default CampDetails