import "./index.css"
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import CampRegistrationItem from "../../CampAdmin/CampRegistrationItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import { Link } from "react-router-dom"

class CampDetailKYC extends Component {

    state = {
        activeTab:'Table',
        usersList:[],
        campCluster: '',
        campName: '',
        campDistricts: [],
        campInchargeName: '',
        campInchargeNumber: '',
        campInchargeGmail: '',
        campLogin: '',
        campPassword: '',
        isLoading:false
    }

    componentDidMount(){
        this.getUsers()
    }

    getUsers = async () => {
      this.setState({ isLoading: true });
      try {
          const [response, response2] = await Promise.all([
              fetch("https://js-member-backend.vercel.app/getcampusers"),
              fetch("https://js-member-backend.vercel.app/getcampkyclist")
          ]);
  
          const data = await response.json();
          const data2 = await response2.json();
          console.log(data2)

          console.log(data2)
          const { CampusersList } = data;
  
          const newList = CampusersList.map(values => ({
              id: values.id,
              campCluster: values.campCluster,
              campName: values.campName,
              campDistricts: values.campDistricts,
              campInchargeName: values.campInchargeName,
              campInchargeNumber: values.campInchargeNumber,
              campInchargeGmail: values.campInchargeGmail,
              campLogin: values.campLogin,
              campPassword: values.campPassword,
              date: values.date,
              time: values.time,
              totalSelfies : (data2.details).filter((ele) => ele.campCluster === values.campCluster).length
          }));

          console.log(newList)
          this.setState({ usersList: newList, isLoading: false });
      } catch (error) {
          console.error("Error fetching data:", error);
          this.setState({ isLoading: false });
      }
  }
  

    onChangeCampId = (event) => {
        this.setState({ campCluster: event.target.value });
    }

    onChangeCampName = (event) => {
        this.setState({ campName: event.target.value });
    }

    onChangeCampDistricts = (event) => {
        const { value, checked } = event.target;
        let { campDistricts } = this.state;
        
        if (checked) {
            campDistricts = [...campDistricts, value];
        } else {
            campDistricts = campDistricts.filter(district => district !== value);
        }
        
        this.setState({ campDistricts });
    }

    onChangeCampInchargeName = (event) => {
        this.setState({ campInchargeName: event.target.value });
    }

    onChangeCampInchargeNumber = (event) => {
        this.setState({ campInchargeNumber: event.target.value });
    }

    onChangeCampInchargeGmail = (event) => {
        this.setState({ campInchargeGmail: event.target.value });
    }

    onChangeCampLogin = (event) => {
        this.setState({ campLogin: event.target.value });
    }

    onChangeCampPassword = (event) => {
        this.setState({ campPassword: event.target.value });
    }

    deleteData = async (value) => {
      const options = {
        method : "DELETE",
        headers : {
          "Content-Type":"application/json"
        },
        body : JSON.stringify({id:value})
      }
      const response = await fetch(`https://js-member-backend.vercel.app/deletecampadmin`,options);
      const data = response.json()
      console.log(data);
    }


    onDeleteUser = (value) => {
       const {usersList} = this.state
        const filteredDetails = usersList.filter((ele) => ele.id!==value)
        this.deleteData(value)
        this.setState({usersList:filteredDetails})
    }

    postData = async (obj) => {
        try{
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
        }
        const response = await fetch(`https://js-member-backend.vercel.app/addcampuserjsd`,options);
        const data = await response.json()
        console.log(data)
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`)
        }
    }

    onSubmitCamp = (event) => {
        event.preventDefault();
        const date = (new Date()).toLocaleDateString('en-GB');
        const time = (new Date()).toLocaleTimeString();
            const {campCluster,
                campName,
                campDistricts,
                campInchargeName,
                campInchargeNumber,
                campInchargeGmail,
                campLogin,
                campPassword,
            usersList} = this.state
            const newObj = {
                id : uuidv4(),
                campCluster,
                campName,
                campDistricts,
                campInchargeName,
                campInchargeNumber,
                campInchargeGmail,
                campLogin,
                campPassword,
                date,
                time,
                teams:[],
            }
            this.postData(newObj)
            const newList = [newObj,...usersList]
            localStorage.setItem("campusers",JSON.stringify(newList))
            this.setState({usersList:newList})
            console.log(this.state);
    }

    handleTabClick = (tabName) => {
        this.setState({ activeTab: tabName });
      };

    render(){
        const {campCluster,
            campName,
            campDistricts,
            campInchargeName,
            campInchargeNumber,
            campInchargeGmail,
            campLogin,
            campPassword,
        usersList} = this.state
        return (
            <>
            <Navbar/>
            <div className='app-main-container'>
                <div className='app-sidebar-container'>
                <Dashboard />
                </div>
                <div className='app-content-container'>
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>KYC</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            {/* <Popup
                trigger={<button className="addBtn" type="button"> Add <FaPlus/> </button>}
                modal
                nested
            >
                {close => (
                    <div className="modal rcyt-custom-popup">
                        <div className="content rcyt-popup-cont">
                            <form className="rcyt-form" onSubmit={this.onSubmitCamp}>
                                <h2>Register Camp</h2>
                                <div>
                                    <label htmlFor="campId">Camp Number</label>
                                    <br/>
                                    <input placeholder="Enter the Camp ID" onChange={this.onChangeCampId} className="rcyt-user-input" type="text" id="campId" required value={campId}/>
                                </div>
                                <div>
                                    <label htmlFor="campName">Camp Name</label>
                                    <br />
                                      <input placeholder="Enter the Camp Name" onChange={this.onChangeCampName} className="rcyt-user-input" type="text" id="campName" required value={campName}/>
                                </div>
                                <div>
                                    <label htmlFor="campDistricts">Camp Districts</label>
                                    <br/>
                                    {options.map(option => (
                                        <div className="rcyt-user-input" key={option.OptionId}>
                                            <input type="checkbox" id={option.OptionId} value={option.OptionId} onChange={this.onChangeCampDistricts}/>
                                            <label htmlFor={option.OptionId}>{option.OptionId}</label>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <label htmlFor="campInchargeName">Camp Incharge Name</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Name" onChange={this.onChangeCampInchargeName} className="rcyt-user-input" type="text" id="campInchargeName" required value={campInchargeName}/>
                                </div>
                                <div>
                                    <label htmlFor="campInchargeNumber">Camp Incharge Number</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Number" onChange={this.onChangeCampInchargeNumber} className="rcyt-user-input" type="tel" id="campInchargeNumber" required value={campInchargeNumber} pattern="^\+91[6-9]\d{9}$"/>
                                </div>
                                <div>
                                    <label htmlFor="campInchargeGmail">Camp Incharge Email</label>
                                    <br/>
                                    <input placeholder="Enter the Incharge Email" onChange={this.onChangeCampInchargeGmail} className="rcyt-user-input" type="email" id="campInchargeGmail" required value={campInchargeGmail}/>
                                </div>
                                <div>
                                    <label htmlFor="campLogin">Camp Login</label>
                                    <br/>
                                    <input placeholder="Enter the Camp Login" onChange={this.onChangeCampLogin} className="rcyt-user-input" type="text" id="campLogin" required value={campLogin}/>
                                </div>
                                <div>
                                    <label htmlFor="campPassword">Camp Password</label>
                                    <br/>
                                    <input placeholder="Enter the Camp Password" onChange={this.onChangeCampPassword} className="rcyt-user-input" type="password" id="campPassword" required value={campPassword}/>
                                </div>
                                <div className="actions">
                                    <button
                                        className="button closeBtn"
                                        onClick={() => {
                                            console.log('modal closed');
                                            close();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button className="fetchBtn" type="submit">Add Camp</button>
                                </div>
                            </form>
                        </div>                        
                    </div>
                )}
            </Popup> */}

              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                <table className="ytpa-content-table">
                    <thead>
                <tr>
                    <th>
                        Sno
                    </th>
                    <th>
                      Date
                    </th>
                    <th>
                        Camp Cluster
                    </th>
                    <th>
                      Total Selfies
                      </th>
                    <th>
                        Camp Districts
                    </th>
                    <th>
                        Camp Incharge Name
                    </th>
                    <th>
                        Camp Incharge Number
                    </th>
                    <th>
                        Camp Incharge Gmail
                    </th>
                </tr>
                </thead>
                <tbody>
                {(usersList.length!==0) && (
                        usersList.map((ele,index) => (
                            <tr key={index}>
            <td>{ele.sno}</td>
            <td>{ele.date}</td>
            <td><Link to={`/campkyc/${ele.campCluster}`}>{ele.campCluster}</Link></td>
            <td> <Link to={`/campkyc/${ele.campCluster}`}>{ele.totalSelfies}</Link></td>
            <td>{ele.campDistricts.join("\n")}</td>
            <td>{ele.campInchargeName}</td>
            <td>{ele.campInchargeNumber}</td>
            <td>{ele.campInchargeGmail}</td>
        </tr>
                        )
                ))}
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        )
    }
}

export default CampDetailKYC