import "./index.css"
import {Link} from 'react-router-dom'
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import ContentItem from '../ContentItem'
import DistrictItem from '../DistrictItem'
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
import RewardItem from "../RewardItem"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

class Reward extends Component {

    state = {activeTab:'Table',usersList:[], videoTitle:'', videoLink:'',source:'Youtube',videoType:'Long',status:'Active',date:''}

    componentDidMount(){
        this.getContentList()
    }

    getContentList = async () => {
        const response = await fetch(`https://js-member-backend.vercel.app/rewarditems`);
        try{
        const data = await response.json();
        const {rewardItems} = data;
        this.setState({usersList:rewardItems})
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }


    render(){
        const {usersList} = this.state

        return (
            <>
            <Navbar/>
            <div className="app-main-container">
                <div className="app-sidebar-container">
                    <Dashboard/>
                </div>
                <div className="app-content-container">
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Trending</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                <table className="ytpa-content-container">
                    <thead>
                <tr>
                    <th>
                        Sno
                    </th>
                    <th>
                        Reward ID
                    </th>
                    <th>
                        Username
                    </th>
                    <th>
                        Mobile No
                    </th>
                    <th>
                        Total Views
                    </th>
                    <th>
                         Total Rewards
                    </th>
                    <th>
                        Date & Time
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Update Status
                    </th>
                </tr>
                </thead>
                <tbody>
                {(usersList.length!==0) && (
                        usersList.map((ele,index) => <RewardItem Sno={index+1} key={index} itemDetails={ele}/>
                ))}
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        )
    }
}

export default Reward