import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, LinearScale, CategoryScale, BarElement } from 'chart.js';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

Chart.register(LinearScale, CategoryScale, BarElement);

function Graph() {
    const verticalLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']; // Define labels array for vertical bar graph

    const verticalOptions = {
        plugins:{
            legend:{
                position:'top'
            },
            title:{
                display:true,
                text:"Expense Tracker"
            }
        }
    };

    const verticalData = {
        labels: verticalLabels,
        datasets: [
            {
                label: "2020 Expenses",
                data: [10000, 9000, 20000, 3000, 8000, 13000, 8000, 9000, 13000, 20000, 10000, 8000],
                backgroundColor: "pink"
            },
            {
                label: "2021 Expenses",
                data: [10000, 19000, 8000, 13000, 90000, 30000, 8000, 9000, 11000, 9000, 7000, 8000],
                backgroundColor: "grey"
            }
        ]
    };

    const horizontalLabels = Array.from({ length: 38 }, (_, i) => `Option ${i + 1}`); // Generate labels for horizontal bar graph

    // Sample data for horizontal bar graph
    const horizontalSampleData = Array.from({ length: 38 }, () => Math.floor(Math.random() * 100)); // Generate random data

    const horizontalOptions = {
        indexAxis: 'y',
        plugins:{
            legend:{
                position:'top'
            },
            title:{
                display:true,
                text:"Horizontal Bar Graph"
            }
        }
    };

    const horizontalData = {
        labels: horizontalLabels,
        datasets: [
            {
                label: "Data Set 1",
                data: horizontalSampleData.slice(0, 5), // Using first 5 elements of sample data for 5 columns
                backgroundColor: "pink"
            }
        ]
    };

    const data = [
        { month: 'Jan', city1: 50, city2: 45 },
        { month: 'Feb', city1: 45, city2: 40 },
        { month: 'Mar', city1: 60, city2: 55 },
        { month: 'Apr', city1: 70, city2: 65 },
        { month: 'May', city1: 80, city2: 75 },
        { month: 'Jun', city1: 90, city2: 85 },
        { month: 'Jul', city1: 100, city2: 95 },
        { month: 'Aug', city1: 95, city2: 90 },
        { month: 'Sep', city1: 85, city2: 80 },
        { month: 'Oct', city1: 75, city2: 70 },
        { month: 'Nov', city1: 65, city2: 60 },
        { month: 'Dec', city1: 55, city2: 50 },
    ];

    return (
        <div>
            <div style={{ marginTop: '20px', width: '800px', height: '600px' }}>
                <Bar options={verticalOptions} data={verticalData} />
            </div>
            <div style={{ marginTop: '20px', width: '800px', height: '600px' }}>
                <Bar options={horizontalOptions} data={horizontalData} />
            </div>
            <div style={{ marginTop: '20px', width: '800px', height: '600px' }}>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="city1" stroke="#8884d8" activeDot={{ r: 8 }} name="City 1" />
                        <Line type="monotone" dataKey="city2" stroke="#82ca9d" name="City 2" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default Graph;
