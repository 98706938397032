import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'

const Settings = () => {
    const navigate = useNavigate()
    const onRemove = () => {
        Cookies.remove("jwt_token")
        navigate("/login")
    }
    return (
        <div>
            <button onClick={onRemove} type="button">Log Out</button>
        </div>
    )
}

export default Settings