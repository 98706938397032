import React, { useState, useEffect } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import RegCampMemberItem from '../RegCampMemberItem';

function RegCampMembers() {
  const [activeTab, setActiveTab] = useState('Table');
  const [regusersList, setRegUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const {campCluster} = params;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://js-member-backend.vercel.app/campusers');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      

      const data = await response.json();
      const data2 = data.filter((ele) => ele.campCluster===campCluster)
      const newList = data2.map((values) => ({
        campCluster: values.campCluster,
        name: values.name,
        email: values.email,
        dob : values.dob,
        fathername : values.fathername,
        // state: values.state,
        district: values.district,
        block: values.block,
        panchayat : values.panchayat,
        village : values.village,
        aadhaarNo : values.aadhaarNo,
        mobileno : values.mobileno,
        referral: values.referral,
        date : values.date,
        time : values.time,
        imageData : values.imageData,
        // constituency: values.constituency,
        // channelUrl: values.channelUrl,
        // whatsappNumber: values.whatsappNumber,
        regstatus: values.regstatus,
        id: values._id,
      }));
      console.log(newList)
      setRegUsersList(newList);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const onDeleteMember = async (value) => {
    const newList = regusersList.filter((ele)=> ele.email!==value)
    setRegUsersList(newList)
    try{
      const options = {
        method : "DELETE",
        headers : {
          "Content-Type":"application/json"
        }
      }
      const response = await fetch(`https://js-member-backend.vercel.app/deletecampusers/${value}`,options);
      const data = await response.json();
      console.log(data);
    }
    catch(Err){
      console.log(`Error Occurred : ${Err}`)
  }}

  const onClickReject = async (value) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newemail: value, newregstatus: 'rejected' }),
    };
    const response = await fetch('https://js-member-backend.vercel.app/campusers', options);
    const data = await response.json();
    console.log(data);
    window.location.reload();
  };

  const onClickApprove = async (value) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newemail: value, newregstatus: 'approved' }),
    };
    const response = await fetch('https://js-member-backend.vercel.app/campusers', options);
    const data = await response.json();
    console.log(data);
    window.location.reload();
  };

  const onClickLogout = () => {
    Cookies.remove('jwt_token');
    // Perform any additional logout logic here
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Additional logic based on tab click if needed
  };

  const renderTable = () => {
    return (
      <div className="ytpa-table-container">
        <table className="ytpa-content-table">
          <thead>
            <tr>
              <th>Sno</th>
              {/* <th>Camp Number</th> */}
              <th>Camp Cluster</th>
              <th>Member Image</th>
              <th>Member Name</th>
              <th>Member Email</th>
              <th>D.O.B</th>
              <th>Fathers Name</th>
              <th>District</th>
              {/* <th>Constituency</th> */}
              <th>Block</th>
              <th>Panchayat</th>
              <th>Village</th>
              <th>Aadhaar Number</th>
              <th>Mobile Number</th>
              <th>Referred By</th>
              <th>Registration Status</th>
              <th>Accept/Reject</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {regusersList.map((ele, index) => (
              <RegCampMemberItem
                key={ele.id}
                sno={index + 1}
                itemDetails={ele}
                onClickApprove={onClickApprove}
                onClickReject={onClickReject}
                onDeleteMember={onDeleteMember}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <div className="app-main-container">
        <div className="app-sidebar-container">
          <Dashboard />
        </div>
        <div className="app-content-container">
          <div className="ytpa-main-container">
            <div className="top-cards">
              <div className="top-stats-design">
                <h1 className="main-stats-heading">Registered Members {`> ${campCluster}`} </h1>
                <div>
                  <p>{new Date().toDateString()}</p>
                </div>
              </div>
              <div className="header">
                <div className="tabs">
                  <div
                    className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Table')}
                  >
                    Table
                  </div>
                  {/* Additional tabs if needed */}
                </div>
                <div className="action-buttons">
                  <button className="action-button">
                    <FaCalendarAlt />
                  </button>
                  <button className="action-button">
                    <FaMapMarkerAlt />
                  </button>
                  <button className="action-button">
                    <FaSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className="ytpa-main-inner-container">
              {isLoading && <ThreeDots color="gray" height={50} width={50} />}
              {!isLoading && renderTable()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegCampMembers;
