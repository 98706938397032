import "./index.css"
import {Link} from 'react-router-dom'

const Camp = () => {
    return (
        <div className="ytcm-main-container">
            <div className="top-containerya">
            <Link to="/" className="link-item">
                    <div className="flexi3">
                    <img src="https://res.cloudinary.com/dylh46szw/image/upload/v1711793425/favicon2_pef2lb.jpg" className="websiteImage" alt="logo"/>
                    <h2 className="main-heading1">JS Dashboard</h2>
                    </div>
                    </Link>
                    <div className="inner-top-container">
                    <h1>Statistics</h1>
                    <div className="date-cont">
                    <p>{(new Date()).toDateString()}</p>
                    </div>
                    </div>
            </div>
            <div className="flex-cont1">
            <div className="left-container">
                    <div className="left-container-items">
                    <Link to="/">
                    <button className="nav-button">Youtuber</button>
                    </Link>
                    <br/>
                    <button className="nav-button">YTMC</button>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Digital Karyakarta</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">D2D</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/stats">
                    <button className="nav-button">Stats</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/camp">
                    <button className="nav-button">Camp</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/stats">
                    <button className="nav-button">Stats</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/camp">
                    <button className="nav-button">Camp</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Padayatra</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Padyatri</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Youth Club</button>
                    </Link>
                    <br/>
                    
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">BiharPol</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Events</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Crowd Funding</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Candidates</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Leaders</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">MSME</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Coachings</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Local Influencer</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-button">Voters</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/settings">
                    <button className="nav-button">Settings</button>
                    </Link>
                    <br/>
                    </div>
                </div>
                <div className="main-inner-container">
                    <div className="table-container">
                    <table> 
                    {/* {(itemsList.length!==0) && (
                        itemsList.map((ele) => <YoutubeItem key={ele.id} itemDetails={ele} onClickDelete={this.onClickDelete}/>
                        ))} */}
                    <thead>
                <tr>
                <th>
                        S.NO
                    </th>
                    <th>
                        Camp Name
                    </th>
                    <th>
                        Camp Number
                    </th>
                    <th>
                    District
                    </th>
                    <th>
                        Constituency
                    </th>
                    <th>
                        Total Teams
                    </th>
                    <th>
                        Total Members
                    </th>
                    <th>
                        Total Vahinee
                    </th>
                    <th>
                        Edit/Delete
                    </th>
                </tr>
                </thead>
                {/* <tfoot>
                    <tr>
                        <th colSpan="3">Total</th>
                        <th>{views}</th>
                        <th>{likes}</th>
                        <th>{comments}</th>
                        <th>{rewards}</th>
                    </tr>
                </tfoot> */}
                </table>
            </div>
            </div>
        </div>
        </div>
        );
}
export default Camp