import "./index.css";
import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import Navbar from "../../Navbar";
import Dashboard from "../../Dashboard";
import Popup from "reactjs-popup";
import { FaCalendarAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
// Import necessary icons here if needed

const CampKYC = () => {
  const [regusersList, setRegUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Table');
  const token = Cookies.get("jwt_token");
  const {campCluster} = useParams()

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://js-member-backend.vercel.app/getcampkyclist");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const newList = data.details.map(values => ({
        name: values.name,
        email: values.email,
        bank: values.bankName,
        accountNo: values.accountNumber,
        campCluster:values.campCluster,
        ifscCode: values.ifscCode,
        city: values.city,
        aadharNo: values.aadharNumber,
        aadharPhoto: values.aadharPhoto,
        kycstatus: values.kycstatus
      }));
      setRegUsersList(newList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReject = async (value) => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ newemail: value, newkycstatus: "rejected" })
    };
    const response = await fetch(`https://js-member-backend.vercel.app/updatecampkycstatus`, options);
    const data = await response.json();
    console.log(data);
    getUsers();
  };

  const onClickApprove = async (value) => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ newemail: value, newkycstatus: "approved" })
    };
    const response = await fetch(`https://js-member-backend.vercel.app/updatecampkycstatus`, options);
    const data = await response.json();
    console.log(data);
    getUsers();
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Navbar />
      <div className="app-main-container">
        <div className="app-sidebar-container">
          <Dashboard />
        </div>
        <div className="app-content-container">
          <div className="ytpa-main-container">
            <div className="top-cards">
              <div className="top-stats-design">
                <h1 className="main-stats-heading">KYC {`> ${campCluster}`} </h1>
                <div>
                  <p>{new Date().toDateString()}</p>
                </div>
              </div>
              <div className="header">
                <div className="tabs">
                  <div
                    className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Table')}
                  >
                    Table
                  </div>
                  {/* Additional tabs if needed */}
                </div>
                <div className="action-buttons">
                  <button className="action-button">
                  <FaCalendarAlt />
                  </button>
                  <button className="action-button">
                  <FaMapMarkerAlt />
                  </button>
                  <button className="action-button">
                  <FaSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className="ytpa-main-inner-container">
              {isLoading ? (
                <div className="ytpa-main-container">
                  <ThreeDots color="gray" height={50} width={50} />
                </div>
              ) : (
                <div className="ytpa-table-container">
                  <table className="ytpa-content-table">
                    <thead>
                      <tr>
                        <th>sno</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Bank</th>
                        <th>Account No</th>
                        <th>IFSC Code</th>
                        <th>Aadhar No</th>
                        <th>Aadhaar Photo</th>
                        <th>Accept/Reject</th>
                      </tr>
                    </thead>
                    <tbody>
                      {regusersList.length > 0 && regusersList.map((ele, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{ele.name}</td>
                          <td>{ele.email}</td>
                          <td>{ele.bank}</td>
                          <td>{ele.accountNo}</td>
                          <td>{ele.ifscCode}</td>
                          {/* <td>{ele.city}</td> */}
                          <td>{ele.aadharNo}</td>
                          <td>
            <Popup
    trigger={<img style={{cursor:'pointer'}} src={ele.aadharPhoto} height="100" width="100" alt="memberimage"/>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
          <h1>Uploaded Image</h1>
        <img src={ele.aadharPhoto} height="300" width="300" alt="memberimage"/>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
            </td>
                          {ele.kycstatus === "rejected" && <td style={{ color: 'red' }}>Rejected</td>}
                          {ele.kycstatus === "approved" && <td style={{ color: 'green' }}>Approved</td>}
                          {ele.kycstatus === "pending" && (
                            <td>
                              <Popup
                                trigger={<button className="edit-Btn" type="button"> Approve </button>}
                                modal
                                nested
                              >
                                {close => (
                                  <div className="modal rcyt-custom-popup">
                                    <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
                                      <h1>Are you sure want to Approve?</h1>
                                      <p>Please reload the page after clicking on approve.</p>
                                      <button className="edit-Btn" type="button" onClick={() => {
                                        onClickApprove(ele.email);
                                        close();
                                      }}>Approve</button>
                                    </div>
                                    <div className="actions">
                                      <button
                                        className="button closeBtn"
                                        onClick={() => {
                                          console.log('modal closed ');
                                          close();
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                              <Popup
                                trigger={<button className="delete-Btn" type="button"> Reject </button>}
                                modal
                                nested
                              >
                                {close => (
                                  <div className="modal rcyt-custom-popup">
                                    <div  style={{textAlign:'center'}}className="content rcyt-popup-cont">
                                      <h3>Are you sure you want to reject?</h3>
                                      <button
                                        className="delete-Btn"
                                        onClick={() => {
                                          onClickReject(ele.email);
                                          close();
                                        }}
                                        type="button"
                                      >
                                        Reject
                                      </button>
                                    </div>
                                    <div className="actions">
                                      <button
                                        className="button closeBtn"
                                        onClick={() => {
                                          console.log('modal closed');
                                          close();
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampKYC;
