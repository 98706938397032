import React, { useState } from 'react';
import { useNavigate}  from 'react-router-dom';
import Cookies from 'js-cookie'
import Popup from 'reactjs-popup'

import './index.css';

const DeleteUserForm = () => {
  const [username, setUsername] = useState('');
  const [password, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  // console.log(process.env.REACT_APP_API_KEY)
  // console.log(data);
  // const navigate = useNavigate()
  const navigate = useNavigate()

  const onChangeUsername = event => {
    setUsername(event.target.value);
  };

  const onChangePassword = event => {
    setEmail(event.target.value);
  };

  const onSubmitSuccess = () => {
    console.log("Login Success");
    Cookies.set("jwt_token","helloworld",{expires:2});
    // console.log(redir);
    navigate("/")
    // return redir("/");
  };

  const onSubmitUser = event => {
    event.preventDefault();
    if (username === 'jsdash' && password === 'jsd#123') {
      onSubmitSuccess();
    } else {
      setUsername('');
      setEmail('');
      setErrorMsg('Invalid Credentials');
    }
  };

  return (
    <div className="login-form-container">
      <div>
        <img src="https://res.cloudinary.com/dylh46szw/image/upload/v1711793425/favicon2_pef2lb.jpg" className='login-logo' alt="img"/>
      </div>
      <form className="login-form" onSubmit={onSubmitUser}>
        <h1 className="main-heading">Delete User</h1>
        <div className="form-ele">
          <label htmlFor="username">Username</label>
          <br />
          <input
            className="input-ele"
            placeholder="USERNAME"
            id="username"
            type="text"
            onChange={onChangeUsername}
            value={username}
          />
        </div>
        <div className="form-ele">
          <label htmlFor="password">Email</label>
          <br />
          <input
            className="input-ele"
            placeholder="EMAIL"
            id="password"
            type="text"
            onChange={onChangePassword}
            value={password}
          />
        </div>
        <Popup
                trigger={<button className="delete-Btn" type="button"> Delete </button>}
                modal
                nested
            >
                {close => (
                <div className="modal rcyt-custom-popup">
                    {/* <button className="close " onClick={close}>
                    &times;
                    </button> */}
                    <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
                        <h1>Are you sure want to delete your account?</h1>
                        <button className="delete-Btn" type="button" onClick={() => {
                            Cookies.remove("jwt_token")
                            navigate("/login",{replace:true})
                            close()
                        }}>Delete</button>
                    </div>
                    <div className="actions">
                    <button
                        className="button closeBtn"
                        onClick={() => {
                        console.log('modal closed ');
                        close();
                        }}
                    >
                        Cancel
                    </button>
                    </div>
                </div>
                )}
            </Popup>
        {/* <Popup>

        </Popup>
        <button className="submitBtn" style={{backgroundColor:'red',color:'white'}} type="submit">Delete</button> */}
        {errorMsg.length !== 0 && <p>{errorMsg}</p>}
      </form>
    </div>
  );
};

export default DeleteUserForm;
