import "./index.css"
import Popup from 'reactjs-popup'
import { useNavigate } from "react-router-dom"
const RewardItem = (props) => {
    const {itemDetails,sno} = props
    const {rewardId,email,name,whatsappNumber,totviews,totrewards,claimDate,claimTime,status} = itemDetails
    let colour = "black";
    if(status==="Processing")
    {
        colour="#fd7e14";
    }
    if(status==="Submitted")
        colour = "#007bff";
    if(status==="Approved")
        colour = "#28a745";
    if(status==="Rejected")
        colour = "#dc3545";
    // const {
    //     id,
    //     videoTitle,videoLink,source,videoType,status,date
    // } = userDetails
    // const onRemoveUser = () => {
    //     onDeleteUser(id)
    // }

    const onUpdateStatus = async (obj) => {
        try{
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify(obj)
            }
            const response = await fetch(`https://js-member-backend.vercel.app/updaterewardstatus`,options)
            const data = await response.json()
            console.log(data);
            window.location.reload();

        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`)
        }
    }

    return (
        <tr>
            <td>{sno}</td>
            <td>{rewardId}</td>
            <td>{name}</td>
            <td>{whatsappNumber}</td>
            <td>{totviews}</td>
            <td>{totrewards}</td>
            <td>{claimDate} & {claimTime}</td>
            <td style={{color:{colour}}}>{status}</td>
            <td>
            <Popup
                trigger={<button className="edit-Btn" type="button"> Update </button>}
                modal
                nested
            >
                {close => (
                <div className="modal rcyt-custom-popup">
                    {/* <button className="close " onClick={close}>
                    &times;
                    </button> */}
                    <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
                        <h1>Update Status</h1>
                        <button className="submitted-button" type="button" onClick={() => {
                            onUpdateStatus({email,rewardId,newstatus:'Submitted'})
                            close()
                        }}>Mark it as Submitted</button>
                        <button className="processing-button" type="button" onClick={() => {
                            onUpdateStatus({email,rewardId,newstatus:'Processing'})
                            close()
                        }}>Mark it as Processing </button>
                        <button className="approved-button" type="button" onClick={() => {
                            onUpdateStatus({email,rewardId,newstatus:'Approved'})
                            close()
                        }}>Mark it as Approved </button>
                        <button className="rejected-button" type="button" onClick={() => {
                            onUpdateStatus({email,rewardId,newstatus:'Rejected'})
                            close()
                        }}>Mark it as Rejected</button>
                    </div>
                    <div className="actions">
                    <button
                        className="button closeBtn"
                        onClick={() => {
                        console.log('modal closed ');
                        close();
                        }}
                    >
                        Cancel
                    </button>
                    </div>
                </div>
                )}
            </Popup>
                </td>
        </tr>
    )

    // return (
    //         <tr>
    //         <td>{sno}</td>
    //             <td>{videoTitle}</td>
    //             <td>
    //                 <a href={videoLink} rel="noreferrer" target="_blank">{videoLink}</a>
    //             </td>
    //             <td>{source}</td>
    //             <td>{videoType}</td>
    //             <td>{status}</td>
    //             <td>{date}</td>
            //     <td>
            // <Popup
            //     trigger={<button className="delete-Btn" type="button"> Delete </button>}
            //     modal
            //     nested
            // >
            //     {close => (
            //     <div className="modal rcyt-custom-popup">
            //         {/* <button className="close " onClick={close}>
            //         &times;
            //         </button> */}
            //         <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
            //             <h1>Confirm Delete</h1>
            //             <button className="delete-Btn" type="button" onClick={() => {
            //                 onRemoveUser()
            //                 close()
            //             }}>Delete</button>
            //         </div>
            //         <div className="actions">
            //         <button
            //             className="button closeBtn"
            //             onClick={() => {
            //             console.log('modal closed ');
            //             close();
            //             }}
            //         >
            //             Cancel
            //         </button>
            //         </div>
            //     </div>
            //     )}
            // </Popup>
            //     </td>
    //         </tr>
    // )
}
export default RewardItem