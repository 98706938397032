import "./index.css"
import {Link} from 'react-router-dom'
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import ContentItem from '../ContentItem'
import DistrictItem from "../DistrictItem"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter

class Trending extends Component {

    state = {activeTab:'Table',usersList:[], videoTitle:'', videoLink:'',source:'Youtube',videoType:'Long',status:'Active',date:''}

    componentDidMount(){
        this.getContentList()
    }

    getContentList = async () => {
        const response = await fetch(`https://js-member-backend.vercel.app/gettrendingdetails`);
        try{
        const data = await response.json()
        // console.log(data)
        this.setState({usersList:data.Trending})
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

    onChangeVideoTitle = (event) => {
        this.setState({ videoTitle: event.target.value });
    }
    
    onChangeVideoLink = (event) => {
        this.setState({ videoLink: event.target.value });
    }
    
    onChangeSource = (event) => {
        this.setState({ source: event.target.value });
    }
    
    onChangeVideoType = (event) => {
        this.setState({ videoType: event.target.value });
    }
    
    onChangeStatus = (event) => {
        this.setState({ status: event.target.value });
    }
    
    onChangeDate = (event) => {
        this.setState({ date: event.target.value });
    }

    getYouTubeVideoID = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else if (match && match[1].includes('shorts/')) {
            // Extract video ID from shorts URL format
            const videoIdIndex = url.indexOf('shorts/') + 'shorts/'.length;
            const videoId = url.substring(videoIdIndex, videoIdIndex + 11);
            return videoId;
        } else {
            console.error('The supplied URL is not a valid YouTube URL');
            return url;
        }
    }

    onDeleteUser = async (value) => {
        try{
            const options = {
                method : "DELETE",
                headers : {
                    "Content-Type" : "application/json"
                } 
            }
            const response = await fetch(`https://js-member-backend.vercel.app/trending/${value}`,options);
            const data = await response.json()
            console.log(data);
            window.location.reload()
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

    addFormData = async (obj) => {
        const options = {
            method :"POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
        }
        const response = await fetch(`https://js-member-backend.vercel.app/addtrendingdata`,options)
        const data = await response.json();
        console.log(data);
    }

    onSubmitUrl = (event) => {
        event.preventDefault();
            const {usersList,videoTitle,videoLink,source,videoType,status,date} = this.state 
            const dateObj = new Date(date)
            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-indexed, so we add 1
            const year = dateObj.getFullYear().toString();
            const videoId = this.getYouTubeVideoID(videoLink);
            const formattedDate = `${day}/${month}/${year}`;
            const time = (new Date()).toLocaleTimeString()
            const newObj = {
                id : uuidv4(),
                videoTitle,videoLink,source,videoType,status,date:formattedDate,videoId,time
            } // Set the image source to the preview image element
            this.addFormData(newObj)
            const newList = [...usersList,newObj]
            localStorage.setItem("users",JSON.stringify(newList))
            this.setState({usersList:newList,videoTitle:'', videoLink:'',source:'Youtube',videoType:'Long',status:'Active',date:''})
        // const {name,channelUrl,state,district,constituency,photo,whatsappnumber} = this.state
            // console.log(this.state);
    }

    onClickCont = () => {
        let menu = document.getElementById("menuList");
        let Btn = document.getElementById("icon");
        let leftCont = document.getElementById("leftCont")
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
    }

    render(){
        const {usersList,videoTitle,videoLink,source,videoType,status,date} = this.state


        return (
            <>
            <Navbar/>
            <div className="app-main-container">
                <div className="app-sidebar-container">
                    <Dashboard/>
                </div>
                <div className="app-content-container">
            <div className="ytpa-main-container">
            <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Trending</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            <Popup
                        trigger={<button className="addBtn" type="button"> Add <FaPlus/> </button>}
                        modal
                        nested
                    >
                        {close => (
                        <div className="modal rcyt-custom-popup">
                            {
                            /* <button className="close " onClick={close}>
                            &times;
                            </button> */
                            }
                            {/* <div className="header popup-cont"> Add Link </div> */}
                            <div className="content rcyt-popup-cont">
                            <form onSubmit={this.onSubmitUrl}>
                                <h2>Add Trending</h2>
                                <div>
                            <label htmlFor="videotitle">Video Title</label>
                            <br />
                            <input placeholder="Enter the Video Title" onChange={this.onChangeVideoTitle} className="rcyt-user-input" type="text" id="videotitle" value={videoTitle} required />
                        </div>
                        <div>
                            <label htmlFor="videolink">Video Link</label>
                            <br />
                            <input pattern="^(https?://)?(www\.)?(youtube\.com|youtu\.be)/(watch\?v=|shorts/|.+)?[A-Za-z0-9_-]+|(https?://)?(drive\.google\.com)/file/d/.+/view" placeholder="Enter the Video Link" onChange={this.onChangeVideoLink} className="rcyt-user-input" type="url" id="videolink" value={videoLink} required />
                        </div>
                        <div>
                            <label htmlFor="source">Source</label>
                            <br />
                            <select className="rcyt-select-input" id="source" onChange={this.onChangeSource} value={source}>
                                <option value="Youtube">Youtube</option>
                                <option value="Telegram">Instagram</option>
                                <option value="Google Drive">Google Drive</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="videotype">Video Type</label>
                            <br />
                            <select className="rcyt-select-input" id="videotype" onChange={this.onChangeVideoType} value={videoType}>
                                <option value="Long">Long</option>
                                <option value="Short">Short</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="status">Status</label>
                            <br />
                            <select className="rcyt-select-input" id="status" onChange={this.onChangeStatus} value={status}>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="date">Date</label>
                            <br />
                            <input type="date" id="date" onChange={this.onChangeDate} value={date} className="rcyt-user-input" required />
                        </div>

                        <div className="actions">
                            <button
                                className="button closeBtn"
                                onClick={() => {
                                console.log('modal closed ');
                                close();
                                }}
                            >
                                Cancel
                            </button>
                            <button className="fetchBtn" type="submit">Add</button>
                            </div>
                            </form>
                            </div>                        
                            </div>
                        )}
                    </Popup>
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                {/* <div className="flex-contrc"> */}
                {/* <div id="leftCont" className="left-containerya">
                <p onClick={this.onClickCont} id="icon" className="nav-icon"><i className="fa-solid fa-bars"></i></p>
                <div id="menuList" className="menu-expanded">
                    <Link to="/">
                    <button className="nav-buttonya">Youtuber</button>
                    </Link>
                    <button className="nav-buttonya">YTCM</button> */}
                    {/* <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Digital Karyakarta</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">D2D</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/stats">
                    <button className="nav-buttonya">Stats</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Camp</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Padayatra</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Padyatri</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Youth Club</button>
                    </Link>
                    <br/>
                    
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">BiharPol</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Events</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Crowd Funding</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Candidates</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Leaders</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">MSME</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Coachings</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Local Influencer</button>
                    </Link>
                    <br/>
                    <Link className="nav-link" to="/comingsoon">
                    <button className="nav-buttonya">Voters</button>
                    </Link>
                    <br/> */}
                    {/* <Link className="nav-link" to="/settings">
                    <button className="nav-buttonya">Settings</button>
                    </Link> 
                    <br/>
                </div>

                JS Dashboard Table 
1. Video Tile 
2. Video Link 
3. Source - Telegram || Youtube 
4. Video type - Short || Long
5. Status - Active || Inactive
6. Date 
                </div> */}
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                <table className="ytpa-content-container">
                    <thead>
                <tr>
                    <th>
                        Sno
                    </th>
                    <th>
                        Video Title
                    </th>
                    <th>
                        Video Link
                    </th>
                    <th>
                        Source
                    </th>
                    <th>
                        Video Type
                    </th>
                    <th>
                         Status 
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                </thead>
                <tbody>
                        {(usersList.length!==0) && (
                        usersList.map((ele,index) => <ContentItem Sno={index+1} key={ele.id} userDetails={ele} onDeleteUser={this.onDeleteUser}/>
                        ))}
                </tbody>
                </table>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        )
    }
}

export default Trending