import React from 'react';
import './index.css'; // Importing CSS file for styling
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';

function Mission() {
  return (
    <>
    <Navbar/>
    <div className="app-main-container">
      <div className='app-sidebar-container'>
        <Dashboard/>
        </div>
        <div className='app-content-container'>
    <div className="mission-container">
      <h1 className="mission-heading">Mission</h1>
      <p className="mission-content">Empower the Jan Suraaj movement with our JS Dashboard, leveraging technology to efficiently track activities and optimize resource utilization for impactful change.</p>
    </div>
    </div> 
    </div>
    </>
  );
}

export default Mission;
