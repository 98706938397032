import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'

const RegistrationItem = (props) => {
    const {itemDetails,sno,onClickApprove,onClickReject} = props
    const {                username,
        email,
        state,
        district,
        constituency,
        channelUrl,
        block,
        whatsappNumber,
        regstatus,
        referral,
        jsid,
        imageData,
        id} = itemDetails
    const onApprove = () => {
        onClickApprove(email)
    }
    const onReject = () => {
        onClickReject(email)
    }
    return (
        <tr>
            <td>{sno}</td>
            <td>{jsid}</td>
            <td>
            <Link to={`/user/channels/${email}`} className="nav-link">
              {username}
              </Link>
            </td>
            <td>
            <Popup
    trigger={<img style={{cursor:'pointer'}} src={imageData} height="100" width="100" alt="memberimage"/>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
          <h1>Uploaded Image</h1>
        <img src={imageData} height="300" width="300" alt="memberimage"/>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
            </td>
            <td>{email}</td>
            <td>{state}</td>
            <td>{district}</td>
            <td>{constituency}</td>
            <td>{block}</td>
            <td>{channelUrl}</td>
            <td>{whatsappNumber}</td>
            <td>{regstatus}</td>
            <td>{referral}</td>
            {regstatus==="rejected" && (<td style={{color:'red'}}>Rejected</td>)}
            {regstatus==="approved" && (<td style={{color:'green'}}>Approved</td>)}
            {regstatus==="pending" && (<td>
              <Popup
    trigger={<button className="edit-Btn" type="button"> Approve </button>}
    modal
    nested
  >
    {close => (
      <div className="modal custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div className="content popup-cont">
            <h1>Are you sure want to Approve?</h1>
            <p>Please reload the page after clicking on approve.</p>
            <button className="edit-Btn" type="button" onClick={() => {
              onApprove()
              close()
            }}>Approve</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
                {/* <Popup
  trigger={<button className="edit-Btn" type="button"> Approve </button>}
  modal
  nested
>
  {close => (
    <div className="modal custom-popup">
      <div className="content popup-cont">
        <h3>Are you sure you want to approve?</h3>
        <button
          className="edit-Btn"
          onClick={onApprove}
          type="button"
        >
          Approve
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup> */}
<Popup
  trigger={<button className="delete-Btn" type="button"> Reject </button>}
  modal
  nested
>
  {close => (
    <div className="modal custom-popup">
      <div className="content popup-cont">
        <h3>Are you sure you want to approve?</h3>
        <button
          className="delete-Btn"
          onClick={() => {
            onReject()
            close()
          }}
          type="button"
        >
          Reject
        </button>
      </div>
      <div className="actions">
        <button
          className="button closeBtn"
          onClick={() => {
            console.log('modal closed');
            close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )}
</Popup>

                {/* <button onClick={onApprove} type="button" className="fetchBtn">Approve</button> */}
                {/* <button type="button" className="closeBtn">Reject</button> */}
            </td>)}
        </tr>
    )
}
export default RegistrationItem