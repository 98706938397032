import {  useParams } from "react-router-dom"
import { useState, useEffect } from 'react';
import { ThreeDots } from "react-loader-spinner"
import Cookies from 'js-cookie'
import {Link} from 'react-router-dom'
import Navbar from "../../Navbar";
import Dashboard from "../../Dashboard";

import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"

const YTCMVideoDetail = () => {
    const {email,videoid,channelName} = useParams()
    console.log(videoid)
    console.log(channelName)
    const [isLoading,setIsLoading] = useState(false)
    const [days, setDays] = useState([]);
    const [dateArray,setDateArray] = useState([]);
    const [videoName, setVideoName] = useState('');
    const [activeTab, setActiveTab] = useState('Table');
    useEffect(() => {
        // Code that would run on component mount
        const getVideos = async () => {
          setIsLoading(true);
          try{
            const options  = {
              method : "POST",
              headers : {
                "Content-Type" : "application/json"
              },
              body : JSON.stringify({email,channelName,videoid})
            }
            const response = await fetch(`https://js-member-backend.vercel.app/ytmcvideo/channel/videostats`,options);
            if(response.ok===true){
            const data = await response.json()
            const {days,videoDate,dateArray,videoName} = data.videoItem
            setVideoName(videoName)
            setDays(days)
            setDateArray(dateArray)
            setIsLoading(false)
            }
            }
          catch(Err){
            console.log(`Error Occurred : ${Err}`)
          }
        }
        // Example: getVideos();
        getVideos()
      }, []); // Empty dependency array means it runs only once on mount  }



      let totviews = 0,totrewardpoints = 0;
      for(let values of days)
      {
        if(values)
        {
          totviews += parseInt(values)
          totrewardpoints += values/100
        }
      }
    
      const onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    const onClickCont = () => {
        let menu = document.getElementById("menuList");
        let leftCont = document.getElementById("leftCont");
            menu.classList.toggle("menu-collapsed");
            menu.classList.toggle("menu-expanded");
            leftCont.classList.toggle("di");
        }

    return (
        <>
        <Navbar/>
        <div className="app-main-container">
            <div className="app-sidebar-container">
                <Dashboard/>
            </div>
            <div className="app-content-container">
                <div className="ytpa-main-container">
                <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Channel Name : {channelName}</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
                <div className="ytpa-main-inner-container">
                    {isLoading===true && (
                        (
                            <div className="ytpa-main-container">
                            <ThreeDots color="gray" height={50} width={50}/>
                            </div>
                        )
                    )}
                    {isLoading===false && (
                    <div className="ytpa-table-container">
                {/* <div>
                    <form onSubmit={onSubmitUrl}>
                        <input value={userinput} onChange={onChangeSearchInput} placeholder="Enter the video link" type="link" className="user-input" />
                        <button className="fetchBtn" type="submit">fetch Details</button>
                    </form>
                </div> */}
                    {/* {isLoading===true && (
                    <div className="ytcm-main-container">
                        <ThreeDots color="gray" height={50} width={50}/>
                    </div>
                )} */}
          {/* {isLoading===false && ( */}
          <div className="ytpa-content-container">
            <h2>7 Day Views & Reward</h2>
            <h3>Video Name : {videoName}</h3>
            <table style={{margin:'auto'}}>
                <thead>
                    <tr>
                    <th>Days</th>
                    <th>Date</th>
                    <th>Views</th>
                    <th>Reward</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Day1</td>
                        <td>{dateArray[0]}</td>
                        <td>{days[0]}</td>
                        <td>{days[0]/100}</td>
                    </tr>
                    <tr>
                        <td>Day2</td>
                        <td>{dateArray[1]}</td>
                        <td>{days[1]}</td>
                        <td>{days[1]/100}</td>
                    </tr>
                    <tr>
                        <td>Day3</td>
                        <td>{dateArray[2]}</td>
                        <td>{days[2]}</td>
                        <td>{days[2]/100}</td>
                    </tr>
                    <tr>
                        <td>Day4</td>
                        <td>{dateArray[3]}</td>
                        <td>{days[3]}</td>
                        <td>{days[3]/100}</td>
                    </tr>
                    <tr>
                        <td>Day5</td>
                        <td>{dateArray[4]}</td>
                        <td>{days[4]}</td>
                        <td>{days[4]/100}</td>
                    </tr>
                    <tr>
                        <td>Day6</td>
                        <td>{dateArray[5]}</td>
                        <td>{days[5]}</td>
                        <td>{days[5]/100}</td>
                    </tr>
                    <tr>
                        <td>Day7</td>
                        <td>{dateArray[6]}</td>
                        <td>{days[6]}</td>
                        <td>{days[6]/100}</td>
                    </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={2}>Total</th>
                    <th>{totviews}</th>
                    <th>{totrewardpoints}</th>
                  </tr>
                </tfoot>
            </table>
            </div>
                {/* {(itemsList.length!==0) && (
                        itemsList.map((ele,index) => <YoutubeItem S.NO={index+1} key={ele.id} itemDetails={ele} onClickDelete={onClickDelete}/>
                        ))} */}
            </div>)}
            </div>
            </div>
            </div>
            </div>
        </>
      )
}
export default YTCMVideoDetail