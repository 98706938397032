import VideoIdItem from '../VideoIdItem';
import {useState,useEffect} from 'react'
import {Link,useParams } from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'
import {CSVLink} from 'react-csv'
import "./index.css"
import Dashboard from '../../Dashboard';
import Navbar from '../../Navbar';


const ChannelDetails = () => {
  const [apiData, setApiData] = useState([]);
  const [recordCount,setRecordCount] = useState(0);
  const [state,setState] = useState({
    itemsList:[],
    videoCount : 0,
    subsCount : 0
  })



  const { channelName,channelId } = useParams();


  const getDetailedVideoStats = async (videoIds) => {
    // console.log(videoIds)
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_API_KEY2}&id=${videoIds}&part=snippet,contentDetails,statistics`)
    // console.log(videoIds)
    // console.log(response.json())
    if(response.ok){
    const data = await response.json()
    const {items} = data
    // console.log(items)
    const {title,description} = items[0].snippet.localized;
    const {viewCount,likeCount,commentCount} = items[0].statistics;
    const {channelTitle} = items[0].snippet;
    const {url} = items[0].snippet.thumbnails.high;
    const {publishedAt} = items[0].snippet
    const currDate = new Date()
    const pubDate = new Date(publishedAt)
    const VideoDays = Math.floor((currDate.getTime()-pubDate.getTime())/(1000*60*60*24))
    const finalData = {
        id : uuidv4(),
        videolink:`https://www.googleapis.com/youtube/v3/videos?key=AIzaSyAreUZciVUxKvvRgOsHowgDPuoiRHHAzMo&id=${videoIds}&part=snippet,contentDetails,statistics`,
        linkUrl:videoIds,
        url,
        title,
        description,
        viewCount,
        likeCount,
        channelTitle,
        commentCount,
        recordCount,
        publishedAt,
        VideoDays,
        rewardPoints:Math.ceil(viewCount/100)
    }
    return finalData
}
}

  const getChannelDetailStatistics = async () => {
    const response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?key=${process.env.REACT_APP_API_KEY2}&id=${channelId}&part=statistics`);
    let subscriberCount,videoCount;
    if(response2.ok){
        const data2 = await response2.json()
        const {items} = data2
        subscriberCount = items[0].statistics.subscriberCount
        videoCount = items[0].statistics.videoCount
        setState(prevState => ({
            ...prevState,
            videoCount,subsCount:subscriberCount
        }))
  }
}  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log(channelId)
        const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyCiEKZJyCu0ALeW5r3zyr23zNlLZV8dpV4&channelId=${channelId}&part=id&order=date&maxResults=15&type=video`);
        const data = await response.json();
        const { items } = data;
        setApiData(items)
        getChannelDetailStatistics(channelId)
        // getDetailedVideoStats(items[0].id.videoId)
        let arr = [];
        for(let values of items)
        {
            arr = [...arr,{id:uuidv4(),videoId:values.id.videoId,viewCount:0,likeCount:0,commentCount:0,title:'',uploadDate:''}]
        }
        const getItem = localStorage.getItem(channelId);
        if(getItem)
        {
            setState(prevState => ({
                ...prevState,
                itemsList:JSON.parse(getItem)
            }))
        }
        setState(prevState => ({
            ...prevState,
            itemsList:arr
        }))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [channelId]); // This will run when `channelId` changes

    // console.log(state.itemsList)

const onClickDelete = (value) => {
        const {itemsList} = state
        const filteredDetails = itemsList.filter((ele) => ele.id!==value)
        localStorage.setItem(channelId,JSON.stringify(filteredDetails));
        setState(prevState => ({
            ...prevState,
            itemsList:filteredDetails
        }))
    }
    const onClickCont = () => {
            let menu = document.getElementById("menuList");
            let Btn = document.getElementById("icon");
            let leftCont = document.getElementById("leftCont")
                menu.classList.toggle("menu-collapsed");
                menu.classList.toggle("menu-expanded");
                leftCont.classList.toggle("di");
        }

    const onClickFetch = async (value) => {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_API_KEY2}&id=${value.videoId}&part=snippet,contentDetails,statistics`)
        if(response.ok)
        {
            const data = await response.json();
            const {items} = data
            const {viewCount,commentCount,likeCount} = items[0].statistics
            const {title,publishedAt} = items[0].snippet
            const pbDate = new Date(publishedAt);
            const VideoDays = Math.floor(((new Date()).getTime()-pbDate.getTime())/(1000*60*60*24))
            const item = state.itemsList.map((ele) => {
                if(ele.id===value.id)
                return {...ele,viewCount,commentCount,likeCount,title,uploadDate:pbDate.toLocaleString(),VideoDays}
                return ele
            })
            // console.log(item)
            localStorage.setItem(channelId,JSON.stringify(item));
            setState(prevState => ({
                ...prevState,
                itemsList:item
            }))
        }
    }
      


    return (
        <>
        <Navbar/>
        <div className='app-main-container'>
        <div className='app-sidebar-container'>
            <Dashboard/>
        </div>
        <div className='app-content-container'>
        <div className="ytpa-main-container">
            <div className="ytpa-top-container">
                    <div className="ytpa-inner-top-container">
                    <h1>Channel Details</h1>
                    <h2>Channel Name : {channelName}</h2>
                    <div>
                    <p>{(new Date()).toDateString()}</p>
                    <p>Beta Testing</p>
                    </div>
                    </div>
            </div>
                <div className="ytpa-main-inner-container">
                    <div className="ytpa-table-container">
                    <table className='ytpa-content-table'>
                    <thead>
                <tr>
                    <th>
                        S.NO
                    </th>
                    <th>
                        Video Id
                    </th>
                    <th>
                        Title
                    </th>
                    <th>
                        Views
                    </th>
                    <th>
                        Likes
                    </th>
                    <th>
                        Comments
                    </th>
                    <th>
                       Channel Video Count
                    </th>
                    <th>
                        Subscribers Count
                    </th>
                    <th>
                        Upload Date
                    </th>
                    <th>
                        VideoDays
                    </th>
                    <th>
                        Fetch
                    </th>
                </tr>
                </thead>
                <tbody>
                {(state.itemsList.length!==0) && (
                        state.itemsList.map((ele,index) => <VideoIdItem videoCount={state.videoCount} subsCount={state.subsCount} sno={index+1} key={ele.videoId} videoDetails={ele} onClickFetch={onClickFetch} />
                        ))}
                </tbody>
                </table>
                <div className="csv-link-cont">
                <CSVLink data={state.itemsList} >
                    <button type="button" className='downloadBtn'>
                    Download
                    </button> 
                </CSVLink>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        </>
        );
};
    
export default ChannelDetails