import React from 'react';
import { FaPlus} from 'react-icons/fa';
import "./index.css"

const Feedback = () => {
  return (
    <div className='wrapper'>
      <div className='top-cards'>
        <h1 className='main-head-text'>Feedback</h1>
        <div className="header">
          <div className="action-buttons">
          <button class="action-button">New<FaPlus className='plus-symbol'/></button>
          </div>
        </div>
      </div>
      <div className='bottom-container'>
        <h1 className='headd'>hiiiiiiiiiii</h1>
      </div>
    </div>
  );
}

export default Feedback;
