import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import YoutubeAssign from './Components/Youtube/YoutubeAssign';
import RegisterComp from './Components/Youtube/RegisterComp';
import ComingSoon from './Components/ComingSoon';
import LoginForm from './Components/LoginForm';
import ChannelDetails from './Components/Youtube/ChannelDetails';
import Settings from './Components/Settings';
import Stats from './Components/Youtube/Stats';
import Camp from './Components/Camp';
import Registrations from './Components/Youtube/Registrations';
import UserChannels from './Components/Youtube/UserChannels';
import UserVideos from './Components/Youtube/UserVideos';
import YTCMStats from './Components/Youtube/YTCMStats';
import YTCMDistrictStats from './Components/Youtube/YTCMDistrictStats';
import YTCMConstStats from './Components/Youtube/YTCMConstStats';
import YTCMChannelStats from './Components/Youtube/YTCMChannelStats';
import YTCMVideoDetail from './Components/Youtube/YTCMVideoDetail';
import Dashboard from './Components/Dashboard';
import Navbar from './Components/Navbar';
import Vision from './Components/HomeComponents/Vision';
import Mission from './Components/HomeComponents/Mission';
import About from './Components/HomeComponents/About';
import Faqs from './Components/HelpComponents/Faqs';
import Trainings from './Components/HelpComponents/Trainings';
import Feedback from './Components/HelpComponents/Feedback';
import Content from './Components/Youtube/Content';
import ContentUsers from './Components/Youtube/ContentUsers';
import Referral from './Components/Youtube/Referral';
import Trending from './Components/Youtube/Trending';
import KYC from './Components/Youtube/KYC';
import Message from './Components/Message';
import {dataContext} from './Components/Context/data';
import Reward from './Components/Youtube/Reward';
import CampRegistrations from './Components/CampAdmin/CampRegistrations';
import RegCampMembers from './Components/CampAdmin/RegCampMembers';
import CampTeams from './Components/CampAdmin/CampTeams';
import CampSubAdmins from './Components/CampAdmin/CampSubAdmins';
import CampAttendance from './Components/CampAdmin/CampAttendance';
import CampDetails from './Components/CampAdmin/CampDetails';
import CampDetailSubAdmin from './Components/CampAdmin/CampDetailSubAdmin';
import CampDetailTeam from './Components/CampAdmin/CampDetailTeam';
import CampDetailAttendance from './Components/CampAdmin/CampDetailAttendance';
import CampDetailD2DReport from './Components/CampReport/CampDetailD2DReport';
import D2DReport from './Components/CampReport/D2DReport'
import CampDetailSSVitran from './Components/CampReport/CampDetailSSVitran';
import SSVitran from './Components/CampReport/SSVitran';
import HouseholdSelfie from './Components/CampReport/HouseholdSelfie';
import CampDetailHousehold from './Components/CampReport/CampDetailHousehold';
import CampDetailSS from './Components/CampReport/CampDetailSS';
import SansthapakSadasya from './Components/CampReport/SansthapakSadasya';
import CampDetailDigitalInfluencer from './Components/CampReport/CampDetailDigitalInfluencer';
import DigitalInfluencer from './Components/CampReport/DigitalInfluencer';
import CampDetailD2DIncharge from './Components/CampAdmin/CampDetailD2DIncharge'
import D2DIncharge from './Components/CampAdmin/D2DIncharge';
import CampDetailActivity from './Components/CampAdmin/CampDetailActivity';
import Activity from './Components/CampAdmin/Activity';
import CampDetailCollateral from './Components/CampAdmin/CampDetailCollateral';
import Collateral from './Components/CampAdmin/Collateral';
import Expenses from './Components/CampAdmin/Expenses';
import CampDetailExpenses from './Components/CampAdmin/CampDetailExpenses';
import CampDetailCoaching from './Components/CampReport/CampDetailCoaching';
import Coaching from './Components/CampReport/Coaching';
import CampDetailWhatsApp from './Components/CampReport/CampWhatsApp';
import WhatsApp from './Components/CampReport/WhatsApp';
import CampDetailAttendanceSelfie from './Components/CampReport/CampDetailAttendanceSelfie';
import AttendanceSelfie from './Components/CampReport/AttendanceSelfie';
import CampDetailKYC from './Components/CampReport/CampDetailKYC';
import CampKYC from './Components/CampReport/CampKYC';
import PrivacyAndPolicy from './Components/PrivacyAndPolicy';
import DeleteUserForm from './Components/DeleteUserForm';

const App = () => {
  const [data, setData] = useState([
    { name: 'charan', number: '9989786348', check: false, status: "NILL" },
    { name: 'chard', number: '8309370811', check: false, status: "NILL" },
    { name: 'ruhi', number: '8008717091', check: false, status: "SUCCESS" },
    { name: 'mahesh', number: '80087172091', check: false, status: "FAILURE" },
    { name: 'janu', number: '8978673461', check: false, status: "NILL" },
    { name: 'meena', number: '8712106829', check: false, status: "NILL" },
    { name: 'raju', number: '8978173461', check: false, status: "NILL" },
    { name: 'sriman', number: '9478673461', check: false, status: "NILL" },
    { name: 'subha', number: '7674083953', check: false, status: "NILL" },
    {name : 'chandu', number:'7093608217',check:false,status:'NILL'}
  ]);

  const [message, setMessage] = useState("");
  const [attachFile, setAttachFile] = useState(null);
  const [details, setDetails] = useState([]);
  const [displayDataModel, setDisplayDataModel] = useState(false);

  useEffect(() => {
    const getData = async () => {
      console.log(data);
    }
    getData();
  }, [data]);

  return (
    <Router>
      {/* <Navbar />
      <div className='app-main-container'>
        <div className='app-sidebar-container'>
          <Dashboard />
        </div>
        <div className='app-content-container'> */}
          <dataContext.Provider value={{ setData, data, message, setMessage, details, setDetails, displayDataModel, setDisplayDataModel, attachFile, setAttachFile }}>
            <Routes>
              <Route path="/" element={<About/>}/>
              <Route path="/delete" element={<DeleteUserForm/>}/>
              <Route path="/pp" element={<PrivacyAndPolicy/>}/>
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/trainings" element={<Trainings />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/about" element={<About />} />
              <Route path="/vision" element={<Vision />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/youtuber" element={<YoutubeAssign />} />
              <Route path="/channeldetails/:channelName/:channelId" element={<ChannelDetails />} />
              <Route path="/register" element={<RegisterComp />} />
              <Route path="/comingsoon" element={<ComingSoon />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/camp" element={<Camp />} />
              <Route path="/regcampmembersdetails" element={<CampDetails/>}/>
              <Route path="/regcampmembers/:campCluster" element={<RegCampMembers/>}/>
              <Route path="/campdetailsubadmin" element={<CampDetailSubAdmin/>}/>
              <Route path="/campsubadmins/:campCluster/" element={<CampSubAdmins/>}/>
              <Route path="/campdetailteams" element={<CampDetailTeam/>}/>
              <Route path="/campteams/:campCluster" element={<CampTeams/>}/>
              <Route path="/campdetailattendance" element={<CampDetailAttendance/>}/>
              <Route path="/campattendance/:campCluster" element={<CampAttendance/>}/>
              <Route path="/campdetaild2dreport" element={<CampDetailD2DReport/>}/>
              <Route path="/d2dreport/:campCluster" element={<D2DReport/>}/>
              <Route path="/campdetailssvitran" element={<CampDetailSSVitran/>}/>
              <Route path="/ssvitran/:campCluster" element={<SSVitran/>}/>
              <Route path="/campdetailhousehold" element={<CampDetailHousehold/>}/>
              <Route path="/selfie/:campCluster" element={<HouseholdSelfie/>}/>
              <Route path="/campdetailss" element={<CampDetailSS/>}/>
              <Route path="/ss/:campCluster" element={<SansthapakSadasya/>}/>
              <Route path="/campdetaildigitalinfluencer" element={<CampDetailDigitalInfluencer/>}/>
              <Route path="/digitalinfluencer/:campCluster" element={<DigitalInfluencer/>}/>
              <Route path="/campdetaild2dincharge" element={<CampDetailD2DIncharge/>}/>
              <Route path="/d2dincharge/:campCluster" element={<D2DIncharge/>}/>
              <Route path="/campdetailactivity" element={<CampDetailActivity/>}/>
              <Route path="/activity/:campCluster" element={<Activity/>}/>
              <Route path="/campdetailcollaterals" element={<CampDetailCollateral/>}/>
              <Route path="/collateral/:campCluster" element={<Collateral/>}/>
              <Route path="/campdetailexpenses" element={<CampDetailExpenses/>}/>
              <Route path="/expenses/:campCluster" element={<Expenses/>}/>
              <Route path="/campdetailcoaching" element={<CampDetailCoaching/>}/>
              <Route path="/coaching/:campCluster" element={<Coaching/>}/>
              <Route path="/campdetailwhatsapp" element={<CampDetailWhatsApp/>}/>
              <Route path="/whatsapp/:campCluster" element={<WhatsApp/>}/>
              <Route path="/campdetailattendanceselfie" element={<CampDetailAttendanceSelfie/>}/>
              <Route path="/attendanceselfie/:campCluster" element={<AttendanceSelfie/>}/>
              <Route path="/campdetailkyc" element={<CampDetailKYC/>}/>
              <Route path="/campkyc/:campCluster" element={<CampKYC/>}/>

              <Route path="/registrations" element={<Registrations />} />
              <Route path="/content" element={<Content />} />
              <Route path="/trending" element={<Trending />} />
              <Route exact path="/reward" element={<Reward/>}/>
              <Route path="/referral" element={<Referral />} />
              <Route path="/kyc" element={<KYC />} />
              <Route path="/message" element={<Message />} />
              <Route path="/user/channels/:email" element={<UserChannels />} />
              <Route path="/user/videos/:email/:channelName" element={<UserVideos />} />
              <Route path="/ytcmstats" element={<YTCMStats />} />
              <Route path="/ytcmstats/:district" element={<YTCMDistrictStats />} />
              <Route path="/ytcmstats/:district/:constituency" element={<YTCMConstStats />} />
              <Route path="/ytcmstats/:district/:constituency/:username/:channelName" element={<YTCMChannelStats />} />
              <Route path="/ytmcvideo/videoinfo/:email/:channelName/:videoid" element={<YTCMVideoDetail />} />
              <Route exact path="/campregistrations" element={<CampRegistrations/>}/>
            </Routes>
          </dataContext.Provider>
    </Router>
  );
};

export default App;
