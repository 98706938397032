import "./index.css"
import {Component} from 'react'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import YoutubeItem from '../YoutubeItem'
import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom"
import Navbar from "../../Navbar"
import Dashboard from "../../Dashboard"
import { FaCalendarAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaSearch } from "react-icons/fa"
import { FaPlus } from "react-icons/fa"
// 1. Add Link 
// 2. Create a table view with views , likes , comment count 
// 3. Add a date range filter to fetch the values got in these date , a quickly last 24 hours And last 48 hours button in the filter



class YoutubeAssign extends Component {

    state = {activeTab:'Table',refreshedList:[],userinput:'',yturl:'',itemsList:[],recordCount:0,last24HrsList:[],totalViews:0,totalLikes:0,totalComments:0}

    onChangeSearchInput = (event) => {
        this.setState({userinput:event.target.value})
    }

    componentDidMount(){
        const products = localStorage.getItem("items")
        const recordNo = localStorage.getItem("recordNo")
        // console.log(process.env.REACT_APP_API_KEY1)
        if(products)
        this.setState({itemsList:JSON.parse(products)})
        if(recordNo)
        this.setState({recordCount:JSON.parse(recordNo)})
        this.getItemDetails();
    }

    onClickLogout = () => {
        Cookies.remove("jwt_token")
        this.setState({userinput:''})
    }

    getItemDetails = async (ind) => {
        const {yturl,recordCount} = this.state
        if(yturl!==""){
        const retrievingUrl = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_API_KEY1}&id=${ind}&part=snippet,contentDetails,statistics`;
        const response = await fetch(retrievingUrl);
        if(response.ok){
        const data = await response.json();
        const {items} = data
        // console.log(items)
        if(items.length===0)
        {
            const {itemsList} = this.state
            const newLi = [...itemsList,{id:uuidv4(),yturl:ind}]
            localStorage.setItem("items",JSON.stringify(newLi))
            this.setState({itemsList:newLi})
            return []
        }
        const {title,description} = items[0].snippet.localized;
        const {viewCount,likeCount,commentCount} = items[0].statistics;
        const {channelTitle} = items[0].snippet;
        const {url} = items[0].snippet.thumbnails.high;
        const {publishedAt} = items[0].snippet
        const currDate = new Date()
        const pubDate = new Date(publishedAt)
        const VideoDays = Math.floor((currDate.getTime()-pubDate.getTime())/(1000*60*60*24))
        const {channelId} = items[0].snippet;
        const response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?key=${process.env.REACT_APP_API_KEY1}&id=${channelId}&part=statistics`);
        let subscriberCount,videoCount,channelViewCount;
        if(response2.ok){
            const data2 = await response2.json()
            const {items} = data2
            // console.log(items[0].statistics)
            subscriberCount = items[0].statistics.subscriberCount
            videoCount = items[0].statistics.videoCount
            channelViewCount = items[0].statistics.viewCount
            // console.log(subscriberCount)
            // console.log(videoCount)
        }
        const finalData = {
            id : uuidv4(),
            videolink:retrievingUrl,
            linkUrl:ind,
            url,
            title,
            channelViewCount,
            description,
            viewCount,
            likeCount,
            channelTitle,
            commentCount,
            recordCount,
            publishedAt,
            VideoDays,
            subscriberCount,
            videoCount,
            rewardPoints:Math.ceil(viewCount/100)
        }
        // console.log(finalData)
        const {itemsList} = this.state
        const newList = [...itemsList,finalData];
        localStorage.setItem("items",JSON.stringify(newList))
        localStorage.setItem("recordNo",JSON.stringify(recordCount))
        // localStorage.setItem(finalData.id,JSON.stringify(finalData))
        // localStorage.setItem("items",JSON.stringify(newList));
        // localStorage.setItem("recordNo",JSON.stringify(recordCount));
        this.setState({itemsList:newList})
        }
    }
}

getUpdatedVideoDetails = async (videoLinkUrl) => {
    const {recordCount,retrievingUrl,linkUrl} = videoLinkUrl
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_API_KEY1}&id=${linkUrl}&part=snippet,contentDetails,statistics`)
    
    if(response.ok){
        const data = await response.json()
    const {items} = data
    // console.log(items)
    const {title,description} = items[0].snippet.localized;
    const {viewCount,likeCount,commentCount} = items[0].statistics;
    const {channelTitle} = items[0].snippet;
    const {url} = items[0].snippet.thumbnails.high;
    const {publishedAt} = items[0].snippet
    const currDate = new Date()
    const pubDate = new Date(publishedAt)
    const VideoDays = Math.floor((currDate.getTime()-pubDate.getTime())/(1000*60*60*24))
    const {channelId} = items[0].snippet;
    const response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?key=${process.env.REACT_APP_API_KEY1}&id=${channelId}&part=statistics`);
    let subscriberCount,videoCount,channelViewCount;
    if(response2.ok){
        const data2 = await response2.json()
        const {items} = data2

        subscriberCount = items[0].statistics.subscriberCount
        videoCount = items[0].statistics.videoCount
        channelViewCount = items[0].statistics.viewCount
    const finalData = {
        id : uuidv4(),
        videolink:retrievingUrl,
        linkUrl,
        url,
        title,
        description,
        viewCount,
        likeCount,
        channelViewCount,
        channelTitle,
        commentCount,
        recordCount,
        publishedAt,
        VideoDays,
        subscriberCount,
        videoCount,
        rewardPoints:Math.ceil(viewCount/100)
    }
    return finalData
}
}
}

onClickRefresh = async () => {
    const {itemsList} = this.state
    let arr = [];
    for(let values of itemsList)
    {
        arr.push(await this.getUpdatedVideoDetails(values))
    }
    this.setState({itemsList:arr})
}



    onClickDelete = (value) => {
       const {itemsList} = this.state   
        const filteredDetails = itemsList.filter((ele) => ele.id!==value)
        localStorage.setItem("items",JSON.stringify(filteredDetails));
        // localStorage.removeItem("recordNo");
        if(filteredDetails.length===0){
            console.log("Hey Length is 0")
            // localStorage.setItem("recordNo",JSON.stringify(0));
        this.setState({recordCount:0})
        }
        this.setState({itemsList:filteredDetails})
    }

    getYouTubeVideoID = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else if (match && match[1].includes('shorts/')) {
            // Extract video ID from shorts URL format
            const videoIdIndex = url.indexOf('shorts/') + 'shorts/'.length;
            const videoId = url.substring(videoIdIndex, videoIdIndex + 11);
            return videoId;
        } else {
            console.error('The supplied URL is not a valid YouTube URL');
            return url;
        }
    }
    
    

    //   onSubmitUrl = async (event) => {
    //     event.preventDefault();
    //     const { userinput } = this.state;
    //     let li = userinput.split(",");
    //     console.log(li)
    //     let videoIds = li.map(this.getYouTubeVideoID);
    
    //     for (const videoId of videoIds) {
    //         await new Promise(resolve => this.setState({
    //             yturl: videoId,
    //             userinput: '',
    //             recordCount: this.state.recordCount + 1
    //         }, () => {
    //             this.getItemDetails(videoId);
    //             resolve();
    //         }));
    //     }
    // }

    onSubmitUrl = async (event) => {
        event.preventDefault();
        const { userinput } = this.state;
        const lines = userinput.trim().split('\n');
          let li = lines.flatMap(line => line.split(',').map(url => url.trim()));
          li = li.filter((ele) => ele!=="")
        //   console.log("I am LI")
          console.log(li)
        //   console.log(li)
        // let li = userinput.split("");
        let videoIds = li.map(this.getYouTubeVideoID);
        // console.log("7biigBn5Md4"+this.getYouTubeVideoID("https://youtube.com/shorts/7biigBn5Md4?feature=share"))
        console.log(videoIds)
        // Reset the state before processing the video IDs
        this.setState({
          yturl: '',
          recordCount: 0
        });
      
        for (let i = 0; i < videoIds.length; i++) {
          const videoId = videoIds[i];
          await this.setState({
            yturl: videoId,
            userinput: '',
            recordCount: this.state.recordCount + 1
          });
            await this.getItemDetails(videoId);
        }
      }
      
    //   onSubmitUrl = async (event) => {
    //     event.preventDefault();
    //     const { userinput } = this.state;
    //     let li = userinput.split(",");
    //     console.log(li)
    //     let videoIds = li.map(this.getYouTubeVideoID);
      
    //     // Create an array of promises for getItemDetails calls
    //     const promises = videoIds.map(async (videoId) => {
    //       await this.setState({
    //         yturl: videoId,
    //         userinput: '',
    //         recordCount: this.state.recordCount + 1
    //       });
    //       await this.getItemDetails(videoId);
    //     });
      
    //     // Wait for all promises to resolve
    //     await Promise.all(promises);
    //   }
    
    
    
    

    // onSubmitUrl = (event) => {
    //     event.preventDefault();
    //     const {userinput,recordCount} = this.state;
    //     let li=userinput.split(",");
    //     let arr = [];
    //     for(let values of li)
    //     {
    //     arr.push(this.getYouTubeVideoID(values));
    // }
    //     // for(let values of arr){
    //     // this.setState({yturl:values,userinput:'',recordCount:recordCount+1},this.getItemDetails)
    //     // }
    //     for(let values of arr)
    //     {
    //         this.setState({yturl:values,userinput:'',recordCount:recordCount+1},this.getItemDetails)
    //     }
    // }

    getLast24HrsDetails = async (yturl) => {
        const endDate = new Date(); // Current date and time
        const startDate = new Date(endDate.getTime() - (24 * 60 * 60 * 1000)); // Subtract 24 hours

        // Convert dates to ISO 8601 format
        const isoStartDate = startDate.toISOString();
        const isoEndDate = endDate.toISOString();

        const retrievingUrl = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_API_KEY1}&id=${yturl}&part=snippet,contentDetails,statistics&publishedAfter=${isoStartDate}&publishedBefore=${isoEndDate}`;
        const response = await fetch(retrievingUrl);
        if(response.ok){
        const data = await response.json();
        const {items} = data
        console.log(items)
        // const {title,description} = items[0].snippet.localized;
        const {viewCount,likeCount,commentCount} = items[0].statistics;
        // const {channelTitle} = items[0].snippet;
        // const {url} = items[0].snippet.thumbnails.high;
        const {publishedAt} = items[0].snippet
        const currDate = new Date()
        const pubDate = new Date(publishedAt)
        const VideoDays = Math.floor((currDate.getTime()-pubDate.getTime())/(1000*60*60*24))
        const {channelId} = items[0].snippet;
        const response2 = await fetch(`https://www.googleapis.com/youtube/v3/channels?key=${process.env.REACT_APP_API_KEY1}&id=${channelId}&part=statistics&publishedAfter=${isoStartDate}&publishedBefore=${isoEndDate}`);
        let subscriberCount,videoCount,channelViewCount;
        if(response2.ok){
            const data2 = await response2.json()
            const {items} = data2
            subscriberCount = items[0].statistics.subscriberCount
            videoCount = items[0].statistics.videoCount
            channelViewCount = items[0].statistics.viewCount
        }
        const {itemsList} = this.state
        itemsList.map((ele) => {
            if(ele.linkUrl===yturl)
            {
                return {...ele,viewCount,likeCount,channelViewCount,commentCount,VideoDays,subscriberCount,videoCount}
            }
            return ele
        })
        this.setState({itemsList})
    }
}


    onClickLast24Btn = async () => {
        const {itemsList} = this.state
        for(let values of itemsList)
        this.getLast24HrsDetails(values.linkUrl)
    }

    onClickCont = () => {
    let menu = document.getElementById("menuList");
    // let Btn = document.getElementById("icon");
    let leftCont = document.getElementById("leftCont")
        menu.classList.toggle("menu-collapsed");
        menu.classList.toggle("menu-expanded");
        leftCont.classList.toggle("di");
    }

    onClickDeleteAll = () => {
        localStorage.setItem("items",JSON.stringify([]))
        this.setState({itemsList:[]})
    }
    
    onClickDropBtn = () => {
        let el = document.getElementsByClassName("drop-item");
        for(let values of el)
        values.classList.toggle("dis");
    }

    handleTabClick = (tabName) => {
      this.setState({ activeTab: tabName });
    };

    render(){
        // console.log(process.env.API_KEY)
        const {itemsList,userinput} = this.state
        let views = 0;
        let likes = 0;
        let comments = 0;
        let rewards = 0;
        itemsList.forEach((ele) => {
            if(ele.viewCount)
            views += parseInt(ele.viewCount)
            if(ele.likeCount)
            likes += parseInt(ele.likeCount)
            if(ele.commentCount)
            comments += parseInt(ele.commentCount)
            if(ele.rewardPoints)
            rewards += parseInt(ele.rewardPoints)
        })
        const token = Cookies.get("jwt_token")
        if(token===undefined)
        return <Navigate to="/login"/>
        else
            return (
                <>
        <Navbar />
        <div className='app-main-container'>
          <div className='app-sidebar-container'>
            <Dashboard />
          </div>
          <div className='app-content-container'>
            <div className="ytpa-main-container">
              <div className="top-cards">
          <div className='top-stats-design'>
            <h1 className='main-stats-heading'>Youtuber</h1>
            <div>
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
          <div className="header">
            <div className="tabs">
              <div
                className={`tab ${
                  this.state.activeTab === 'Table' ? 'active' : ''
                }`}
                onClick={() => this.handleTabClick('Table')}
              >
                Table
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Charts' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Charts')}
              >
                Charts
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Graph' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Graph')}
              >
                Graph
              </div>
              <div
                className={`tab ${
                  this.state.activeTab === 'Maps' ? 'active' : ''
                }`}
                // onClick={() => this.handleTabClick('Maps')}
              >
                Maps
              </div>
            </div>
            <div className="action-buttons">
            <Popup
                      trigger={<button className="ytpa-addBtn" type="button"> Add <FaPlus/></button>}
                      modal
                      nested
                    >
                      {close => (
                        <div className="ytpa-modal ytpa-custom-popup">
                          <h1 style={{ margin: 'auto' }}>Add Link</h1>
                          <p>You can use comma to enter multiple video urls.</p>
                          <div className="ytpa-content ytpa-popup-cont">
                            <form onSubmit={this.onSubmitUrl}>
                              <textarea cols="50" id="urlInput" value={userinput} onChange={this.onChangeSearchInput} rows="10" placeholder="Enter URLs, one per line"></textarea>
                              <button className="ytpa-fetchBtn" type="submit">fetch Details</button>
                            </form>
                          </div>
                          <div className="ytpa-actions">
                            <button
                              className="ytpa-button ytpa-closeBtn"
                              onClick={() => {
                                close();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup>
              <button className="action-button">
                <FaCalendarAlt />
              </button>
              <button className="action-button">
                <FaMapMarkerAlt />
              </button>
              <button className="action-button">
                <FaSearch />
              </button>
            </div>
          </div>
        </div>
              {/* <div className="ytpa-top-container">
                <div className="ytpa-inner-top-container">
                  <h1>Youtuber Progress Analytics</h1>
                  <div>
                    <Popup
                      trigger={<button className="ytpa-addBtn" type="button"> Add </button>}
                      modal
                      nested
                    >
                      {close => (
                        <div className="ytpa-modal ytpa-custom-popup">
                          <h1 style={{ margin: 'auto' }}>Add Link</h1>
                          <p>You can use comma to enter multiple video urls.</p>
                          <div className="ytpa-content ytpa-popup-cont">
                            <form onSubmit={this.onSubmitUrl}>
                              <textarea cols="50" id="urlInput" value={userinput} onChange={this.onChangeSearchInput} rows="10" placeholder="Enter URLs, one per line"></textarea>
                              <button className="ytpa-fetchBtn" type="submit">fetch Details</button>
                            </form>
                          </div>
                          <div className="ytpa-actions">
                            <button
                              className="ytpa-button ytpa-closeBtn"
                              onClick={() => {
                                close();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup>
                    <button className="ytpa-last24Btn" onClick={this.onClickRefresh}>Refresh</button>
                    <button className="ytpa-last24Btn" onClick={this.onClickDeleteAll}>Delete All</button>
                  </div>
                  <div className="ytpa-date-cont">
                    <p>{(new Date()).toDateString()}</p>
                    <p>Beta Testing</p>
                  </div>
                </div>
              </div> */}
              <div className="ytpa-main-inner-container">
                <div className="ytpa-table-container">
                  <table className="ytpa-content-table">
                    <thead className="ytpa-thead">
                      <tr>
                        <th>sno</th>
                        <th>Title</th>
                        <th>Channel Name</th>
                        <th>Views</th>
                        <th>Likes</th>
                        <th>Comments</th>
                        <th>Reward Points</th>
                        <th>Upload Date</th>
                        <th>Video Days</th>
                        <th>TCV (Total Channel Videos)</th>
                        <th>Total Channel Views</th>
                        <th>Subscribers Count</th>
                        <th>Video Link</th>
                        <th>Video URL</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody className="ytpa-tbody">
                    {itemsList.length !== 0 && (
                      itemsList.map((ele, index) => <YoutubeItem sno={index + 1} key={ele.id} itemDetails={ele} onClickDelete={this.onClickDelete} />)
                    )}
                    </tbody>
                    <tfoot className="ytpa-tfoot">
                      <tr>
                        <th colSpan="3">Total</th>
                        <th>{views}</th>
                        <th>{likes}</th>
                        <th>{comments}</th>
                        <th>{rewards}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
            )
        }        
}

export default YoutubeAssign