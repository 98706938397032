import "./index.css"
import Popup from 'reactjs-popup'
import {Link} from 'react-router-dom'

const CampAttendanceItem = (props) => {
    const {itemDetails,Sno} = props
    const { 
      present,
      absent,
      time,
      campCluster,
    } = itemDetails

    return (
        <tr>
            <td>{Sno}</td>
            <td>{campCluster}</td>
            <td>
              {time.split('&')[0]}
            </td>
            <td>{present}</td>
            <td>{absent}</td>
        </tr>
    )
}
export default CampAttendanceItem