import {useState,useContext,useEffect} from 'react';

import './index.css'
import { dataContext } from '../Context/data';
import Dashboard from '../Dashboard';
import Navbar from '../Navbar';

const Message = () => {
    const {data,setMessage,message,details,setDetails,setAttachFile,attachFile}=useContext(dataContext)
    
    const [checkAll,setCheckAll]=useState(false);

    useEffect(()=>{
        setDetails(data);
        console.log('times')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])

    const sendData = async () => {
        // console.log(data,"data from hero in navbar");
        const requiredData=details.filter(detail=>detail.check===true);
        console.log(requiredData,"neededd daat");
        const requiredDataFormat={"message":message,"details":requiredData}
        console.log(requiredDataFormat,"our format");

        if(attachFile ===null){ // no file is attached
            const options={
                method:"POST",
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify(requiredDataFormat)
            }
            console.log(options)
            const response = await fetch('https://whatsapp-backend-murex.vercel.app/'+'sendmsg',options);
    
            // if(response){
                const result= await response.json();
                console.log(result);
                const requireResult=result.isSent;
                console.log(requireResult);
                const updatedDetails=[...details];
                console.log(updatedDetails)
    
                requireResult.forEach(resultEntry => {
    
                    const matchingDetailIndex = updatedDetails.findIndex(detail => detail.number === resultEntry.phone);
    
                    if (matchingDetailIndex !== -1) {
                        updatedDetails[matchingDetailIndex].status = resultEntry.status.toUpperCase();
                    }
                });
    
                console.log(updatedDetails,"after data sent sattusse updated")
                setDetails(updatedDetails);
            // }
            // else{
            //     console.log("not requested data for messaging some issues")
            // }
        }
        else{ // a file is attached
            const formData=new FormData();
            console.log(attachFile)
            formData.append('message',message)
            formData.append('file',attachFile)
            formData.append('details',requiredData)

            const options={
                method:"POST",
                headers:{
                    'Content-Type':'multipart/form-data',
                },
                body:formData
            }
            console.log(options)
            try{
                const response = await fetch('https://whatsapp-backend-murex.vercel.app/sendfilemsg',options);
        
                const result= await response.json();
                console.log(result);
                const requireResult=result.isSent;
                console.log(requireResult);
                const updatedDetails=[...details];
                console.log(updatedDetails)
    
                requireResult.forEach(resultEntry => {
    
                    const matchingDetailIndex = updatedDetails.findIndex(detail => detail.number === resultEntry.phone);
    
                    if (matchingDetailIndex !== -1) {
                        updatedDetails[matchingDetailIndex].status = resultEntry.status.toUpperCase();
                    }
                });
    
                console.log(updatedDetails,"after data sent with file sattusse updated")
                setDetails(updatedDetails);  
            }
            catch (e){
                console.log(e,e.message,"hera llsdvr")
            }
              
        }

    }

    const handleCheckboxChange = (index) => (event) => {
        const updatedDetails = [...details];
        updatedDetails[index] = {
            ...updatedDetails[index],
            check: event.target.checked
        };
        setDetails(updatedDetails);
      };

    const handleAllCheckBoxes=()=>{
       setCheckAll(l=>!l);
       const updatedDetails = [...details];
       updatedDetails.forEach((item)=>{item.check=!checkAll});
       console.log(updatedDetails,"checkAll");
       setDetails(updatedDetails);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file)
        setAttachFile(file)
      };
      

    return(
        <>
        <Navbar/>
        <div className='app-main-container'>
            <div className='app-sidebar-container'>
                <Dashboard/>
            </div>
        <div className='app-content-container'>
        <div className="hero-container">
            <button className="message-btn">MESSAGE</button>
            <button className='send-btn' onClick={sendData}>SEND</button>
            <div className="choose-data">
                <input type="" className="message-box" onChange={(e)=>{setMessage(e.target.value)}} value={message} placeholder="Write your message here"/>
                <div className="collect-data">
                    <button className="attach-btn">Attachment</button>
                    <div className="file-input">
                        <label className="custom-file-upload">
                            <input type="file" onChange={handleFileChange} />
                            Browse
                        </label>
                    </div>
                </div>
            </div>
            <div className="table-header">
                <span className='table-cell'>NAME</span>
                <span className='table-cell'>NUMBER</span>
                <label className='table-cell' htmlFor='checkbox-header'>ALL<input type="checkbox" onChange={handleAllCheckBoxes} id="checkbox-header" /></label>
                <span className='table-cell'>STATUS</span>
            </div>
            {details && <div className='table-footer'>
                {details.map((detail,index)=>{
                    const {name,number,check,status }= detail;
                    return(<div key={index} className='table-values'>
                        <span className='table-cell-values'>{name}</span>
                    <span className='table-cell-values'>{number}</span>
                    <label className='table-cell-values' htmlFor={`${number}-${index}`}><input id={`${number}-${index}`} type="checkbox" checked={check} onChange={handleCheckboxChange(index)} /> </label>
                    <span className={`table-cell-values ${status==="SUCCESS" && 'success-message'} ${status==="FAILURE"&& 'failure-message'}`} >{status}</span></div>
                    )
                })}
            </div>}
        </div>
        </div>
        </div>
        </>
    )
}

export default Message;