import React, { useState, useEffect } from 'react';
import { Navigate , useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import CampSubAdminItem from '../CampSubAdminItem';

function CampSubAdmins() {
  const [activeTab, setActiveTab] = useState('Table');
  const [regusersList, setRegUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams()
  const {campCluster} = params;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://js-member-backend.vercel.app/campusers');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      const filteredList = data.filter((ele) => (ele.campCluster===campCluster && ele.person==="subadmin"))
      setRegUsersList(filteredList);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const onClickLogout = () => {
    Cookies.remove('jwt_token');
    // Perform any additional logout logic here
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Additional logic based on tab click if needed
  };

  const renderTable = () => {
    return (
      <div className="ytpa-table-container">
        <table className="ytpa-content-table">
          <thead>
            <tr>
              <th>Sno</th>
              <th>Sub Admin Name</th>
              <th>Sub Admin Email</th>
              <th>Sub Admin MobileNo</th>
              <th>Access</th>
              <th>Joining Time</th>
            </tr>
          </thead>
          <tbody>
            {regusersList.map((ele, index) => (
              <CampSubAdminItem
                key={ele.id}
                sno={index + 1}
                itemDetails={ele}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const token = Cookies.get('jwt_token');
  if (token === undefined) return <Navigate to="/login" />;
  
  return (
    <>
      <Navbar />
      <div className="app-main-container">
        <div className="app-sidebar-container">
          <Dashboard />
        </div>
        <div className="app-content-container">
          <div className="ytpa-main-container">
            <div className="top-cards">
              <div className="top-stats-design">
                <h1 className="main-stats-heading">Sub Admins {`> ${campCluster} `}</h1>
                <div>
                  <p>{new Date().toDateString()}</p>
                </div>
              </div>
              <div className="header">
                <div className="tabs">
                  <div
                    className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Table')}
                  >
                    Table
                  </div>
                  {/* Additional tabs if needed */}
                </div>
                <div className="action-buttons">
                  <button className="action-button">
                    <FaCalendarAlt />
                  </button>
                  <button className="action-button">
                    <FaMapMarkerAlt />
                  </button>
                  <button className="action-button">
                    <FaSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className="ytpa-main-inner-container">
              {isLoading && (
                <div className="ytcm-main-container">
                  <ThreeDots color="gray" height={50} width={50} />
                </div>
              )}
              {!isLoading && renderTable()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampSubAdmins;
