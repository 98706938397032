import "./videoid.css"

const VideoIdItem = (props) => {
    const {videoDetails,sno,onClickFetch,videoCount,subsCount,onClickDelete} = props
    const {id,videoId,viewCount,likeCount,commentCount,VideoDays,title,uploadDate} = videoDetails
    const onFetch = () => {
        onClickFetch(videoDetails)
    }

    return (
            <tr>
                <td className="tableD">
                    {sno}
                </td>
                <td className="tableD">
                    {videoId}
                </td>
                <td>
                    {title}
                </td>
                <td className="tableD">
                    {viewCount}
                </td>
                <td>
                    {likeCount}
                </td>
                <td>
                    {commentCount}
                </td>
                <td>
                    {videoCount}
                </td>
                <td>
                    {subsCount}
                </td>
                <td>
                    {uploadDate}
                </td>
                <td>
                    {VideoDays}
                </td>
                <td className="tableD">
                    <button onClick={onFetch} className="fetchBtn">Fetch</button>
                </td>
            </tr>
    )
}

export default VideoIdItem