// About.js

import React from 'react';
import './index.css';
import Navbar from '../Navbar';
import Dashboard from '../Dashboard';

const PrivacyAndPolicy = () => {
  return (
    <>
      <Navbar/>
      <div className="app-main-container">
        <div className='app-sidebar-container'>
          <Dashboard/>
        </div>
        <div className='app-content-container'>
          <div style={{minHeight:'100vh',backgroundColor:'rgb(52,58,64)',width:'80vw',padding:'20px'}} >
            <h1 className="heading">Privacy And Policy</h1>
            <p className="paragraph"><strong>Effective Date:</strong> {(new Date()).toLocaleDateString('en-GB')}</p>

            <h2 className='pp-sub-heading'>1. Introduction</h2>
            <p className="paragraph">
              Welcome to Vahini, a utility app designed for the task management of volunteers of Jan Suraaj. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application ("App").
            </p>

            <h2 className='pp-sub-heading'>2. Information We Collect</h2>
            <p className="paragraph">
              We may collect and process the following types of information:
            </p>
            <ul className="paragraph">
              <li><strong>Personal Information:</strong> Name, email address, phone number, and other contact details.</li>
              <li><strong>Task Information:</strong> Details of the tasks assigned to you, your progress, and completion status.</li>
              <li><strong>Usage Data:</strong> Information on how you use our App, including interactions and preferences.</li>
              <li><strong>Device Information:</strong> Information about the device you use to access the App, such as IP address, operating system, and browser type.</li>
              <li><strong>Location Data:</strong> Approximate location data based on your device settings to help assign tasks relevant to your area.</li>
            </ul>

            <h2 className='pp-sub-heading'>3. How We Use Your Information</h2>
            <p className="paragraph">
              We use the collected information to:
            </p>
            <ul className="paragraph">
              <li>Provide, maintain, and improve our App.</li>
              <li>Assign and manage tasks effectively.</li>
              <li>Communicate with you, including sending updates and task notifications.</li>
              <li>Personalize your experience within the App.</li>
              <li>Analyze usage trends to improve our services.</li>
            </ul>

            <h2 className='pp-sub-heading'>4. Sharing Your Information</h2>
            <p className="paragraph">
              We may share your information with:
            </p>
            <ul className="paragraph">
              <li><strong>Service Providers:</strong> Third-party companies that help us operate and improve our App.</li>
              <li><strong>Jan Suraaj Management:</strong> To effectively manage and coordinate volunteer tasks.</li>
              <li><strong>Legal Authorities:</strong> If required by law or to protect our rights.</li>
            </ul>

            <h2 className='pp-sub-heading'>5. Security</h2>
            <p className="paragraph">
              We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.
            </p>

            <h2 className='pp-sub-heading'>6. Your Rights</h2>
            <p className="paragraph">
              You have the right to:
            </p>
            <ul className="paragraph">
              <li>Access and update your personal information.</li>
              <li>Request deletion of your personal information.</li>
              <li>Opt-out of receiving non-essential communications.</li>
            </ul>

            <h2 className='pp-sub-heading'>7. Children's Privacy</h2>
            <p className="paragraph">
              Our App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
            </p>

            <h2 className='pp-sub-heading'>8. Changes to This Privacy Policy</h2>
            <p className="paragraph">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy in the App. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2 className='pp-sub-heading'>9. Contact Us</h2>
            <p className="paragraph">
              If you have any questions about this Privacy Policy, please contact us at: info.jansuraaj@gmail.com
            </p>
          </div>
        </div> 
      </div>
    </>
  );
}

export default PrivacyAndPolicy;
