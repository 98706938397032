import "./index.css"
import Popup from "reactjs-popup"

const CampRegistrationItem = (props) => {
    const {itemDetails,sno,onDeleteUser} = props
    const {
        id,
        date,
        campCluster,
        // campName,
        campDistricts,
        campInchargeName,
        campInchargeNumber,
        campInchargeGmail,
        campLogin,
        campPassword,
    } = itemDetails
    const onRemoveUser = () => {
        onDeleteUser(id,campCluster)
    }
    return (
        <tr>
            <td>{sno}</td>
            <td>{date}</td>
            <td>{campCluster}</td>
            {/* <td>{campName}</td> */}
            <td>{campDistricts.join("\n")}</td>
            <td>{campInchargeName}</td>
            <td>{campInchargeNumber}</td>
            <td>{campInchargeGmail}</td>
            {/* <td>{campLogin}</td>
            <td>{campPassword}</td> */}
            <td>
  <Popup
    trigger={<button className="delete-Btn" type="button"> Delete </button>}
    modal
    nested
  >
    {close => (
      <div className="modal rcyt-custom-popup">
        {/* <button className="close " onClick={close}>
          &times;
        </button> */}
        <div style={{textAlign:'center'}} className="content rcyt-popup-cont">
            <h1>Confirm Delete</h1>
            <button className="delete-Btn" type="button" onClick={onRemoveUser}>Delete</button>
        </div>
        <div className="actions">
          <button
            className="button closeBtn"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </Popup>
                </td>
        </tr>
    )
}

export default CampRegistrationItem