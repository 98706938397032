import React, { useState, useEffect } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThreeDots } from 'react-loader-spinner';
import Navbar from '../../Navbar';
import Dashboard from '../../Dashboard';
import { FaCalendarAlt, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const Expenses = () => {
  const [activeTab, setActiveTab] = useState('Table');
  const [d2dList, setd2dList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const {campCluster} = params;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://js-member-backend.vercel.app/getexpensesreportdata/${campCluster}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      

      const data = await response.json();
      let l = data.result;
      l.sort((a, b) => new Date(b.expensesDate) - new Date(a.expensesDate));
      const newList = (l).map((values) => ({
        
id : values.id,
expensesDate : values.expensesDate,
purpose : values.purpose,
item : values.item,
amount : values.amount,
verifiedBy : values.verifiedBy,
copyOfTheBill : values.copyOfTheBill,
date : values.date,
time : values.time,
campCluster : values.campCluster,
addedByemail : values.addedByemail
      }));
      setd2dList(newList);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const onClickLogout = () => {
    Cookies.remove('jwt_token');
    // Perform any additional logout logic here
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Additional logic based on tab click if needed
  };

  const renderTable = () => {
    return (
      <div className="ytpa-table-container">
        <table className="ytpa-content-table">
          <thead>
          <tr>
<th>S.NO.</th>
<th>Camp Cluster</th>
<th>Date</th>
<th>Purpose</th>
<th>Item</th>
<th>Amount</th>
<th>Verified By</th>
<th>Copy of The Bill</th>
<th>Actions</th>
        </tr>
  </thead>
          <tbody>
            {d2dList.map((ele, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{ele.campCluster}</td>
                <td>{ele.expensesDate}</td>
                <td>{ele.purpose}</td>
                <td>{ele.item}</td>
                <td>{ele.amount}</td>
                <td>{ele.verifiedBy}</td>
                <td>{ele.copyOfTheBill}</td>
                <td style={{textAlign:'center',color:'red'}} >
                    <MdDelete size={20}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <div className="app-main-container">
        <div className="app-sidebar-container">
          <Dashboard />
        </div>
        <div className="app-content-container">
          <div className="ytpa-main-container">
            <div className="top-cards">
              <div className="top-stats-design">
                <h1 className="main-stats-heading">Expenses {`> ${campCluster}`} </h1>
                <div>
                  <p>{new Date().toDateString()}</p>
                </div>
              </div>
              <div className="header">
                <div className="tabs">
                  <div
                    className={`tab ${activeTab === 'Table' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Table')}
                  >
                    Table
                  </div>
                  {/* Additional tabs if needed */}
                </div>
                <div className="action-buttons">
                  <button className="action-button">
                    <FaCalendarAlt />
                  </button>
                  <button className="action-button">
                    <FaMapMarkerAlt />
                  </button>
                  <button className="action-button">
                    <FaSearch />
                  </button>
                </div>
              </div>
            </div>
            <div className="ytpa-main-inner-container">
              {isLoading && <ThreeDots color="gray" height={50} width={50} />}
              {!isLoading && renderTable()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Expenses;
